import { updateWidget } from '../api/index';
import { client } from '@/ReactQueryProvider';
import { useMutation } from '@tanstack/react-query';
import { V4WidgetUpdatePayload } from '../types/index';

type UpdateWidgetMutationType = {
  slug: string;
  payload: V4WidgetUpdatePayload;
};

export const useWidgetUpdate = () => {
  const { mutate, isLoading } = useMutation({
    mutationKey: ['update-widget'],
    mutationFn: ({ slug, payload }: UpdateWidgetMutationType) => updateWidget(slug, payload),
    onSuccess: () => client.invalidateQueries({ queryKey: ['get-widgets'] }),
  });

  return {
    updateWidget: mutate,
    isLoading,
  };
};
