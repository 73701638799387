import AppContext from '@/pages/app/context';
import { IShiftsByUser, PickerType } from '@/pages/app/hours/manage/redux/store';
import Colors from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import { FEATURES } from '@/types/features.model';
import { IScheduleDay } from '@/types/schedule-day.model';
import { IScheduleHour } from '@/types/schedule-hour.model';
import { IShift } from '@/types/shift.model';
import { getWindowSize, isFeatureEnabled, isNullOrUndefined } from '@/utils';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import React, { Dispatch, useContext, useEffect, useRef, useState } from 'react';
import {
  Provider,
  ReactReduxContextValue,
  batch,
  createDispatchHook,
  createSelectorHook,
  createStoreHook,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import NoSectionSelected from '../components/NoSectionSelected';
import OpenShiftsRow from '../components/OpenShiftRow';
import UserShiftLoadingRow from '../components/UserShiftLoadingRow';
import UserShiftRow from '../components/UserShiftRow';
import { ActionType } from '../redux/actions';
import { InitialStateType } from '../redux/store';
import Insights from './components/Insights';
import NowRow from './components/NowRow';
import Shifts from './components/Shifts';
import TaskScheduler from './components/TaskScheduler';
import TotalEmployees from './components/TotalEmployees';
import { ActionType as HourlyActionType } from './redux/actions';
import { InitialStateType as HourlyInitialStateType, store } from './redux/store';

const TASKS_HEIGHT = 22;

const HourlyReduxContext = React.createContext({} as ReactReduxContextValue<any, HourlyActionType>);
export const useHourlyStore = createStoreHook(HourlyReduxContext);
export const useHourlyDispatch = createDispatchHook(HourlyReduxContext);
export const useHourlySelector = createSelectorHook(HourlyReduxContext);

interface Props {
  className?: string;
  refTotalHours: React.MutableRefObject<null> | null;
  refPageContainer: React.MutableRefObject<null> | null;
  activeSection: string | undefined;
  department: IDepartment | null;
  quotas: any;
  turnover: any;
  balance: any;
}

const AppHoursManageHourlyContainer: React.FC<Props> = ({
  refTotalHours,
  refPageContainer,
  activeSection,
  department,
  quotas,
  turnover,
  balance,
}) => {
  return (
    <Provider store={store} context={HourlyReduxContext}>
      <AppHoursManageHourlyStyled
        refTotalHours={refTotalHours}
        refPageContainer={refPageContainer}
        activeSection={activeSection}
        department={department}
        quotas={quotas}
        turnover={turnover}
        balance={balance}
      />
    </Provider>
  );
};

const AppHoursManageHourly: React.FC<Props> = ({
  className,
  refTotalHours,
  refPageContainer,
  activeSection,
  department,
  quotas,
  turnover,
  balance,
}) => {
  const {
    startDate,
    endDate,
    hours,
    totalBudget,
    filteredShiftsByUsers,
    showOpenShifts,
    showSkills,
    showTasks,
    showAttributes,
    showSections,
    showDetails,
    showOtherDepartments,
    picker,
    selectedDate,
    isLoading,
    taskScheduler,
    shiftsMap,
    usersMap,
  } = useSelector(
    ({
      startDate,
      endDate,
      hours,
      totalBudget,
      filteredShiftsByUsers,
      showOpenShifts,
      showSkills,
      showTasks,
      showAttributes,
      showSections,
      showDetails,
      showOtherDepartments,
      picker,
      selectedDate,
      isLoading,
      taskScheduler,
      shiftsMap,
      usersMap,
    }: InitialStateType) => ({
      startDate,
      endDate,
      hours,
      totalBudget,
      filteredShiftsByUsers,
      showOpenShifts,
      showSkills,
      showTasks,
      showAttributes,
      showSections,
      showDetails,
      showOtherDepartments,
      picker,
      selectedDate,
      isLoading,
      taskScheduler,
      shiftsMap,
      usersMap,
    }),
    shallowEqual,
  );

  const hoursDispatch: Dispatch<ActionType> = useDispatch();
  const hourlyDispatch: Dispatch<HourlyActionType> = useHourlyDispatch();
  const routeMatch = useRouteMatch();
  const paths = routeMatch.path.split('/');
  const currentPicker = paths[paths.length - 2] as PickerType;
  const { formatedDate } = routeMatch.params as any;
  const [columnWidth, setColumnWidth] = useState<number>(0);
  const [shifts, setShifts] = useState<IShiftsByUser[]>([]);
  const [openShifts, setOpenShifts] = useState<IShiftsByUser | null>(null);
  const refDraggedShift = useRef<any>(null);
  const refDraggedShiftData = useRef<any>(null);
  const {
    state: { activeDepartment, skills, userCategories, features, tasks },
  } = useContext(AppContext);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { totalEmployees, totalOpenShifts, totalHeadCounts, totalBudgets } = useHourlySelector(
    ({ totalEmployees, totalOpenShifts, totalHeadCounts, totalBudgets }: HourlyInitialStateType) => ({
      totalEmployees,
      totalOpenShifts,
      totalHeadCounts,
      totalBudgets,
    }),
    shallowEqual,
  );

  const startHour = department?.scheduleParams?.startHour || 0;
  const endHour = department?.scheduleParams?.endHour || 24;
  const hoursLength = hours.length || 24;

  const resizeHandler = () => {
    if (window.innerWidth > 1024) {
      const width = (window.innerWidth - 240) / hoursLength;
      setColumnWidth(width);
    } else {
      setColumnWidth((1024 - 240) / hoursLength);
    }
  };

  useEffect(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [hoursLength]);

  useEffect(() => {
    // CHECK IF THE DATE AND INTERVAL ARE CORRECTS
    if (department && (picker !== currentPicker || selectedDate.format('YYYY-MM-DD') !== formatedDate)) {
      const startDate = moment(formatedDate).startOf('day').add(startHour, 'hour');
      let endDate = moment(formatedDate).startOf('day').add(endHour, 'hour');
      if (endDate.isSameOrBefore(startDate)) {
        endDate = endDate.add(1, 'day');
      }
      hoursDispatch({
        type: 'SET_PICKER_AND_SELECTED_DATE',
        payload: {
          picker: currentPicker,
          selectedDate: moment(formatedDate).valueOf(),
          startDate: startDate.valueOf(),
          endDate: endDate.valueOf(),
        },
      });
    }
    // eslint-disable-next-line
  }, [picker, formatedDate, activeSection, department]);

  useEffect(() => {
    // CHECK IF THE DATE AND INTERVAL ARE CORRECTS
    if (picker !== currentPicker || selectedDate.format('YYYY-MM-DD') !== formatedDate) {
      return;
    }

    const totalEmployees = [];
    const totalHeadCounts = [];
    const totalBudgets = [];
    const totalOpenShifts = [];
    const count_free_shift_in_head_count = department?.scheduleParams?.count_free_shift_in_head_count;

    let totalFraction = 0;
    for (let i = 0; i < hours.length; i++) {
      const _currentHour = hours[i];
      const currentHour = moment.unix(_currentHour.date);
      totalHeadCounts.push(_currentHour.headCount || null);
      totalBudgets.push(_currentHour.budget || null);
      let totalHourEmployees = 0;
      let totalHourOpenShifts = 0;
      let fraction = 0;
      let subtract = false;
      totalFraction = 0;
      // eslint-disable-next-line
      filteredShiftsByUsers.forEach((shiftsByUser) => {
        totalFraction = 0;
        fraction = 0;
        shiftsByUser.shifts.forEach((shift) => {
          fraction = 0;
          const startShiftMoment = moment.unix(shift.start!).startOf('hour');
          const endShiftMoment = moment.unix(shift.end!);
          const hasTaskInHour = shift.tasks
            ? shift.tasks.find((t) => {
                const task = tasks.find((el) => t.taskTypeId == el.id);
                subtract = task ? !!task.day_subtract : false;
                const startOfHour = currentHour;
                const endOfHour = currentHour.clone().add(1, 'hour');
                const wholeHour = startOfHour.unix() >= t.start! && endOfHour.unix() <= t.end!;
                const diffStart = moment.unix(t.start!).diff(currentHour, 'minutes') / 15;
                const diffEnd = currentHour.clone().add(1, 'hour').diff(moment.unix(t.end!), 'minutes') / 15;
                const startInCurrentHour = moment
                  .unix(t.start!)
                  .isBetween(currentHour, currentHour.clone().add(1, 'hour'), null, '[)');
                const endInCurrentHour = moment
                  .unix(t.end!)
                  .isBetween(currentHour, currentHour.clone().add(1, 'hour'), null, '(]');
                if (!subtract) {
                  if (diffStart > 0 && diffStart < 4) {
                    fraction = 0.25 * diffStart;
                  } else if (diffEnd > 0 && diffEnd < 4) {
                    fraction = 0.25 * (4 - diffEnd);
                  } else if (wholeHour) {
                    fraction = 0;
                  } else {
                    fraction = 0;
                  }
                  totalFraction += fraction;
                }

                return subtract && (startInCurrentHour || endInCurrentHour || wholeHour);
              })
            : false;

          if (currentHour.isBetween(startShiftMoment, endShiftMoment, undefined, '[)')) {
            if (shift.shyftType === 3) {
              return;
            }
            if (!shiftsByUser.user.recordId) {
              totalHourOpenShifts += 1;
              if (count_free_shift_in_head_count && !shift.dayoff) {
                totalHourEmployees += 1;
              }
            } else {
              if (!shift.dayoff) {
                const shiftSectionId = shift?.section?.id;
                if (!activeSection || (activeSection && shiftSectionId === activeSection)) {
                  totalHourEmployees += hasTaskInHour ? Number(Number(totalFraction).toFixed(2)) : 1;
                }
              }
            }
          }
        });
      });
      totalEmployees.push(totalHourEmployees);
      totalOpenShifts.push(totalHourOpenShifts);
    }

    const shifts = cloneDeep(filteredShiftsByUsers.slice(1));
    for (let shiftsByuserRecordIdx = 0; shiftsByuserRecordIdx < shifts.length; shiftsByuserRecordIdx++) {
      const userShiftGroup = shifts[shiftsByuserRecordIdx];
      userShiftGroup.totalMinutes = 0;
      userShiftGroup.totalPrice = 0;

      const { user, shifts: userShifts } = userShiftGroup;
      for (let shiftIdx = 0; shiftIdx < userShifts.length; shiftIdx++) {
        const shift = userShifts[shiftIdx];
        const duration = moment.duration(shift.end! - shift.start!, 'seconds');
        duration.subtract(shift.pause!.unpaid!, 'seconds');
        const durationAsMinutes = duration.asMinutes();
        let hourlyRate = user?.hourlyRate;
        if (isNullOrUndefined(hourlyRate) && shift.skills && shift.skills.length > 0) {
          const maxSkillPrice = Math.max(...shift.skills.map((skill) => skill.price || 0));
          hourlyRate = maxSkillPrice;
        }
        //const price = (durationAsMinutes / 60) * (hourlyRate || 0);
        //shift.price = price;
        const price = shift.price!;
        if (shift.start! >= startDate.unix()) {
          if (!shift.dayoff || shift.dayoff.paid) {
            userShiftGroup.totalPrice += price;
          }
          userShiftGroup.totalMinutes += durationAsMinutes;
        }
      }
    }
    setShifts(shifts);
    setOpenShifts(filteredShiftsByUsers[0]);
    hourlyDispatch({
      type: 'SET_TOTAL_EMPLOYEES',
      payload: totalEmployees,
    });
    hourlyDispatch({
      type: 'SET_TOTAL_HEAD_COUNTS',
      payload: totalHeadCounts,
    });
    hourlyDispatch({
      type: 'SET_TOTAL_BUDGETS',
      payload: totalBudgets,
    });
    hourlyDispatch({
      type: 'SET_TOTAL_OPEN_SHIFTS',
      payload: totalOpenShifts,
    });
    // eslint-disable-next-line
  }, [filteredShiftsByUsers, tasks]);

  const onTaskDropHandler = (e: React.DragEvent) => {
    const taskId = e.dataTransfer.getData('taskId');
    const shiftId = e.dataTransfer.getData('shiftId');
    if (!shiftId || shiftId == '') return;
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v3/shift-tasks/${taskId}`, {
        params: {
          picker,
          startDate: startDate.unix(),
          endDate: endDate.unix(),
          sectionId: activeSection,
          departmentId: department?.id,
        },
      })
      .then(({ data }) => {
        const days = (data.days as IScheduleDay[]) || [];
        const hours = (data.hours as IScheduleHour[]) || [];
        const {
          shifts,
          totalBudget,
          comparisonTemplate,
          cycleNumber = null,
          scheduleModel = false,
          productivityIndex = null,
          productivityIndexReal = null,
        } = data;
        const newShifts = data.shifts as IShift[];
        const newShiftsMap = new Map(shiftsMap);
        for (let i = 0; i < newShifts.length; i++) {
          const newShift = newShifts[i];
          newShiftsMap.set(newShift.id!, newShift);
        }
        const filteredShifts = [...shifts].filter(
          (shift: IShift) => moment.unix(shift.start!).day() == startDate.day() && shift.shyftType == 1,
        );
        const earliestShift = filteredShifts.reduce((earliestShift, currentShift) => {
          const shiftStartDate = moment.unix(currentShift.start);
          const shiftEndDate = moment.unix(currentShift.end);

          // Check if the start date is earlier than the current earliest shift
          if (shiftStartDate.isBefore(moment.unix(earliestShift.start))) {
            return currentShift;
          }

          return earliestShift;
        }, filteredShifts[0]);
        const latestShift = filteredShifts.reduce((latestShift, currentShift) => {
          const shiftStartDate = moment.unix(currentShift.start);
          const shiftEndDate = moment.unix(currentShift.end);

          // Check if the start date is today and the end date is greater than the current latest shift
          if (shiftEndDate.isAfter(moment.unix(latestShift.end))) {
            return currentShift;
          }

          return latestShift;
        }, filteredShifts[0]);
        batch(() => {
          const startHour = activeDepartment?.scheduleParams?.startHour || 0;
          const endHour = activeDepartment?.scheduleParams?.endHour || 24;
          const dynamicHours = activeDepartment?.scheduleParams?.dynamicHour;

          let nextDay = false;
          if (earliestShift && latestShift && earliestShift.start && latestShift.end) {
            nextDay = moment.unix(latestShift.end).day() != moment.unix(earliestShift.start).day();
          }

          const start = dynamicHours
            ? earliestShift && earliestShift.start
              ? moment.unix(earliestShift.start).subtract(1, 'hour').hours()
              : 9
            : startHour;
          const end = dynamicHours
            ? latestShift && latestShift.end
              ? moment.unix(latestShift.end).add(1, 'hour').hours()
              : 24
            : endHour;
          const tz = activeDepartment?.timezone || 'Europe/Brussels';
          const isMidnight =
            earliestShift && earliestShift.startDate ? earliestShift.startDate.split(' ')[1].startsWith('00:') : false;

          hoursDispatch({
            type: 'SET_SHIFTS',
            payload: {
              skills,
              userStatus: userCategories,
              picker,
              activeDepartment,
              shifts: [...newShiftsMap.values()],
              users: [...usersMap.values()],
              days,
              hours:
                picker == 'day'
                  ? hours.filter(
                      (hour: IScheduleHour) =>
                        hour.date >= moment(startDate.clone().set({ hours: isMidnight ? -1 : start })).unix() &&
                        hour.date <=
                          moment(
                            startDate
                              .clone()
                              .add(1, 'day')
                              .toDate()
                              .setHours(end + 1),
                          ).unix(),
                    )
                  : hours,
              totalBudget,
              comparisonTemplate,
              cycleNumber,
              productivityIndex,
              productivityIndexReal,
              scheduleModel,
              activeSection,
            },
          });

          hoursDispatch({
            type: 'UPDATE_FILTERED_SHIFTS',
            payload: { department: activeDepartment },
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (department?.scheduleParams?.force_section && !activeSection) {
    return <NoSectionSelected />;
  }

  return (
    <Provider store={store} context={HourlyReduxContext}>
      {selectedDate.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD') && (
        <NowRow refPageContainer={refPageContainer} />
      )}
      {isFeatureEnabled(features, FEATURES.FREESHIFTS) && openShifts && (
        <OpenShiftsRow baseClassName={`${className} ${showOpenShifts ? '' : 'hide-shifts'}`}>
          <Shifts
            refPageContainer={refPageContainer}
            refDraggedShift={refDraggedShift}
            refDraggedShiftData={refDraggedShiftData}
            rowIndex={0}
            columnWidth={columnWidth}
            userRecordId={null}
            shifts={openShifts.shifts}
            hideShifts={!showOpenShifts}
            totalShifts={totalOpenShifts}
            taskHeight={TASKS_HEIGHT}
            height={
              20 +
              28 * +showOtherDepartments +
              20 * +showDetails +
              30 * +(showSkills || showSections || showAttributes) +
              TASKS_HEIGHT * +(!!isFeatureEnabled(features, FEATURES.TASKS) && showTasks)
            }
          />
        </OpenShiftsRow>
      )}
      {windowSize.innerWidth > 900 && department?.scheduleParams?.enableProvisional && (
        <Insights
          offsetTop={45}
          department={department}
          startDate={startDate}
          totalBudget={totalBudget}
          totalBudgets={totalBudgets}
          columnWidth={columnWidth}
          refPageContainer={refPageContainer}
          quotas={quotas}
          turnover={turnover}
        />
      )}
      {windowSize.innerWidth > 900 && (
        <TotalEmployees
          offsetTop={department?.scheduleParams?.enableProvisional ? 86 : 45}
          totalEmployees={totalEmployees}
          totalHeadCounts={totalHeadCounts}
          columnWidth={columnWidth}
          refTotalHours={refTotalHours}
          refPageContainer={refPageContainer}
          hours={hours}
          selectedDate={selectedDate}
          balance={balance}
          quotas={quotas}
          turnover={turnover}
        />
      )}
      {shifts
        // .map((userShifts) => {
        //   return {
        //     ...userShifts,
        //     shifts: userShifts.shifts.filter((shift) => {
        //       return moment(shift.startDate).isSameOrAfter(moment.unix(hours[0].date));
        //     }),
        //   };
        // })
        .sort((a, b) => {
          const lengthA = a.shifts.length;
          const lengthB = b.shifts.length;
          if (lengthA === 0 && lengthB > 0) {
            return 1;
          } else if (lengthA > 0 && lengthB === 0) {
            return -1;
          }
          return 0;
        })
        .map((shiftsByUser, userIndex) => {
          const { user, totalPrice, totalMinutes } = shiftsByUser;
          const { recordId: userRecordId, hourlyRate } = user;
          return (
            <UserShiftRow
              key={`userShift_${userRecordId}`}
              baseClassName={className!}
              user={user}
              totalMinutes={totalMinutes!}
              totalPrice={totalPrice!}
              activeSection={activeSection}
              activeDepartment={department || undefined}
              showSkillInsteadOfStatus={department?.scheduleParams?.orderByDay === 'SKILL'}
            >
              <Shifts
                refPageContainer={refPageContainer}
                refDraggedShift={refDraggedShift}
                refDraggedShiftData={refDraggedShiftData}
                columnWidth={columnWidth}
                rowIndex={userIndex + 1}
                userRecordId={userRecordId!}
                shifts={shiftsByUser.shifts}
                hourlyRate={hourlyRate}
                height={
                  20 +
                  28 * +showOtherDepartments +
                  20 * +showDetails +
                  30 * +(showSkills || showSections || showAttributes) +
                  TASKS_HEIGHT * +(!!isFeatureEnabled(features, FEATURES.TASKS) && showTasks)
                }
                taskHeight={TASKS_HEIGHT}
              />
            </UserShiftRow>
          );
        })}
      {isLoading === true && (
        <React.Fragment>
          {[...new Array(3)].map((loadingRow, loadingRowIndex) => (
            <UserShiftLoadingRow
              key={`userLoading_${loadingRowIndex}`}
              baseClassName={`${className} ${picker}`}
            ></UserShiftLoadingRow>
          ))}
        </React.Fragment>
      )}
      {taskScheduler && <TaskScheduler columnWidth={columnWidth} onTaskDropHandler={onTaskDropHandler} />}
    </Provider>
  );
};

const AppHoursManageHourlyStyled = styled(AppHoursManageHourly)`
  display: flex;
  position: relative;
  width: 100%;

  .task-scheduler-enter {
    opacity: 0;
  }

  .task-scheduler-enter.task-scheduler-enter-active {
    opacity: 1;
    transition: opacity 3000ms ease-in;
  }

  &.hide-shifts {
    height: 39px;
  }

  > .left {
    position: sticky;
    left: 0;
    padding: 10px;
    border-right: 1px solid ${Colors.blueLight};
    border-bottom: 1px solid ${Colors.blueLight};
    width: 210px;
    background: white;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    z-index: 2;
    background: #f9f9f9;
  }

  > .right {
    flex: 1;
    width: auto;
  }

  @media screen and (max-width: 900px) {
    > .left {
      width: 150px;
    }
  }
`;

export default AppHoursManageHourlyContainer;
