import React, { FC, useMemo } from 'react';
import styled from 'styled-components';

type UnderLayerType = {
  height: number;
  editing: boolean;
  size: 'lg' | 'md' | 's';
  className?: string;
};

const UnderLayer: FC<UnderLayerType> = ({ size, className, height, editing }) => {
  const columns = { lg: 6, md: 3, s: 1 }[size];
  const shadowBoxes = useMemo(() => {
    return [...Array(Math.round(height / 160) * columns + columns).keys()].map((i) => (
      <span key={i} className={'shadow-box'} />
    ));
  }, [height, columns]);

  if(!editing) {
    return null;
  }

  return (
    <div style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }} className={className}>
      {shadowBoxes}
    </div>
  );
};

const UnderLayerStyled = styled(UnderLayer)`
  position: absolute;
  display: grid;
  grid-gap: 10px;
  margin: 10px 0 0 10px;
  width: calc(100% - 20px);

  .shadow-box {
    height: 150px;
    border-radius: 5px;
    background-color: rgba(216, 213, 213, 0.31);
  }
`;

export default UnderLayerStyled;
