import { setToken } from '@/actions';
import { usePusher } from '@/components/PusherProvider';
import AuthContext from '@/context';
import AppContext from '@/pages/app/context';
import { default as Colors, default as colors } from '@/styles/colors';
import { FEATURES } from '@/types/features.model';
import { IHeaderBadges } from '@/types/header-badges.model';
import {
  isAdminOrHrOrPlanning,
  isClockingAccount,
  isEmptyAccount,
  isFeatureEnabled,
  isFree,
  isTrial,
  now,
} from '@/utils';
import { DownOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Dropdown, Select, Tooltip, message, notification } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import i18n from '../../../i18n';
import { store as dashboardStore } from '../dashboard/redux/store';
import { store as manageStore } from '../hours/manage/redux/store';
import MenuClocking from '../menus/MenuClocking';
import MenuDocuments from '../menus/MenuDocuments';
import MenuHelp from '../menus/MenuHelp';
import MenuHours from '../menus/MenuHours';
import MenuNotifications from '../menus/MenuNotifications';
import MenuReports from '../menus/MenuReports';
import MenuSuperAdmin from '../menus/MenuSuperAdmin';
import MenuTeam from '../menus/MenuTeam';
import MenuUser from '../menus/MenuUser';
import CommandsModal from './CommandsModal';
import ModalPayingOption from './ModalPayingOption';
import ModalPayingOptionConfirm from './ModalPayingOptionConfirm';

const { Option } = Select;

interface Props {
  className?: string;
}

const desactivateLink = (e: any) => {
  e.preventDefault();
};

const Header: React.FC<Props> = ({ className }) => {
  const { state: appContextState, dispatch: appContextDispatch } = useContext(AppContext);
  const {
    dispatch: authContextDispatch,
    state: { userDetails, impersonate },
  } = useContext(AuthContext);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const { pathname } = useLocation();
  const {
    activeSection,
    activeDepartment,
    activeDepartmentId,
    sections,
    departments,
    resources,
    badges,
    features,
  } = appContextState;
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [loadingNotifications, setLoadingNotifications] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [modalPayingOptionVisible, setModalPayingOptionVisible] = useState<boolean>(false);
  const [modalPayingOptionConfirmVisible, setModalPayingOptionConfirmVisible] = useState<boolean>(false);
  const history = useHistory();
  const pusher = usePusher();

  useEffect(() => {
    if (showNotification) {
      let mounted = true;
      const cancelTokenSource = axios.CancelToken.source();
      setLoadingNotifications(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/notifications`, {
          params: {
            departmentId: activeDepartment?.id,
          },
          cancelToken: cancelTokenSource.token,
        })
        .then((response) => {
          if (mounted) {
            setLoadingNotifications(false);
            setNotifications(response.data);
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            console.error(error);
          }
          if (mounted) {
            setLoadingNotifications(false);
          }
        });

      return () => {
        mounted = false;
        cancelTokenSource.cancel();
      };
    }
  }, [showNotification, activeDepartment]);

  useEffect(() => {
    if (pusher && activeDepartment) {
      // PUSHER
      const channel = pusher?.subscribe(`private-department-${activeDepartment.id}`);
      channel?.bind('badges', (message: any) => {
        appContextDispatch({
          type: 'SET_BADGES',
          payload: message.badges,
        });
      });

      const authChannel2 = pusher?.subscribe(`private-App.User.${userDetails?.id}.Department.${activeDepartment.id}`);
      authChannel2?.bind(`Illuminate\\Notifications\\Events\\BroadcastNotificationCreated`, (message: any) => {
        if (activeDepartmentId) {
          openNotification(message, activeDepartmentId);
        }
        appContextDispatch({
          type: 'SET_BADGES',
          payload: {
            ...badges,
            notifications: {
              unread: (badges?.notifications?.unread || 0) + 1,
            },
          } as IHeaderBadges,
        });
      });
    }

    return () => {
      if (pusher && activeDepartment) {
        pusher.unsubscribe(`private-department-${activeDepartment.id}`);
        pusher.unsubscribe(`private-App.User.${userDetails?.id}.Department.${activeDepartment.id}`);
      }
    };
  }, [pusher, activeDepartment]);

  const handleSectionChange = (value: string | undefined) => {
    appContextDispatch({
      type: 'SET_ACTIVE_SECTION',
      payload: value || undefined,
    });
  };

  const handleDepartmentChange = (value: string | null) => {
    if (value && activeDepartment?.id !== value) {
      const department = departments?.find((x) => x.id === value);
      if (department) {
        appContextDispatch({
          type: 'SET_ACTIVE_DEPARTMENT',
          payload: department?.id,
        });
        authContextDispatch({
          type: 'SET_TRIAL_END_DATE',
          payload: department?.trialEndDate || null,
        });
        authContextDispatch({
          type: 'SET_BLOCKED',
          payload: department?.accountType === 'BLOCKED' ? true : false,
        });
        if (isTrial(department)) {
          history.push(`/app/clockings/${moment().format('YYYY-MM-DD')}`);
        } else {
          history.push('/app/dashboard');
        }
      }
    }
  };

  const handleLogout = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (impersonate && accessToken) {
      sessionStorage.removeItem('impersonateToken');
      history.push('/app');
      setToken(authContextDispatch, accessToken);
    } else {
      history.push('/app');
      localStorage.clear();
      manageStore.dispatch({
        type: 'RESET',
      });
      dashboardStore.dispatch({
        type: 'RESET',
      });
      authContextDispatch({
        type: 'RESET',
      });
      appContextDispatch({
        type: 'RESET',
      });
    }
  };

  const renderReportsLink = () => (
    <NavLink
      className={pathname.startsWith('/app/reports') ? 'is-active' : ''}
      to="/app/reports/timesheets"
      style={{ textTransform: 'uppercase', fontSize: 13 }}
    >
      <i className="icon-chart-bar"></i>
      <span className="text">{t('MENU.REPORTS.TITLE')}</span>
    </NavLink>
  );

  const openSupport = () => {
    switch (activeDepartment?.userLanguageCode) {
      case 'fr':
        window.open('https://help.shyfter.co/fr/', '_blank');
        break;
      case 'nl':
        window.open('https://help.shyfter.co/nl/', '_blank');
        break;
      case 'es':
        window.open('https://help.shyfter.co/es/', '_blank');
        break;
      default:
        window.open('https://help.shyfter.co', '_blank');
        break;
    }
  };

  const local = ['shyfter.test'].some((text) => process.env.REACT_APP_API_URL?.includes(text));
  const staging = ['staging', 'params'].some((text) => process.env.REACT_APP_API_URL?.includes(text));

  const renderTeam = () => {
    return (
      <NavLink
        id="team_menu"
        className={pathname.startsWith('/app/team') ? 'is-active' : ''}
        to="/app/team/collaborators/all"
        style={{ textTransform: 'uppercase', fontSize: 13 }}
      >
        <i className="icon-user"></i>
        <span className="text">{t('MENU.TEAM.TITLE')}</span>
        {activeDepartment?.userSubscription && badges.users && <Badge count={badges.users?.total} />}
      </NavLink>
    );
  };

  const renderReports = () => {
    return (
      <NavLink
        className={pathname.startsWith('/app/reports') ? 'is-active' : ''}
        to="/app/reports/timesheets"
        style={{ textTransform: 'uppercase', fontSize: 13 }}
      >
        <i className="icon-chart-bar"></i>
        <span className="text">{t('MENU.REPORTS.TITLE')}</span>
      </NavLink>
    );
  };

  const onCopyDepartmentDetails = () => {
    const text = `**Account ID**: ${activeDepartment?.accountId}\n**Department ID**: ${activeDepartmentId}\n**Department Name**: ${activeDepartment?.company}\n**Url Super-admin**: https://v3-app.shyfter.co/app/super-admin/accounts/${activeDepartment?.accountId}/${activeDepartmentId} ###\n\n**Atlas account url**: https://atlas.shyfter.co/resources/accounts/${activeDepartment?.accountId} ###\n\n**User id**: ${userDetails?.id}\n**User name**: ${userDetails?.userName}`;
    navigator.clipboard.writeText(text.replaceAll('###', ''));
    message.success('Department details copied to clipboard');
  };

  return (
    <header
      className={className}
      style={{
        backgroundColor: staging ? colors.orange : local ? colors.blueLightPastel : '#fff',
      }}
    >
      <div className="left">
        <NavLink
          to={
            activeDepartment?.accountType == 'RGPD'
              ? '/app/reports/timesheets'
              : isClockingAccount(features)
              ? `/app/clockings/${now().format('YYYY-MM-DD')}`
              : isEmptyAccount(features)
              ? '/app/team/collaborators/all'
              : '/app/dashboard'
          }
          className="shyfter-logo menu_dashboard"
        >
          {activeDepartment?.partner?.logo ? (
            <img src={activeDepartment?.partner?.logo} alt={activeDepartment?.partner?.name} />
          ) : (
            <img src={process.env.PUBLIC_URL + '/shyfter-logo.svg'} alt="Shyfter" />
          )}
          {activeDepartment?.partner?.icon ? (
            <img className="logo-icon" src={activeDepartment?.partner?.icon} alt={activeDepartment?.partner?.name} />
          ) : (
            <img className="logo-icon" src={process.env.PUBLIC_URL + '/shyfter-logo-min.svg'} alt="Shyfter" />
          )}
        </NavLink>
        {activeDepartment?.accountType !== 'RGPD' &&
          isFeatureEnabled(features, FEATURES.SECTIONS) &&
          !!sections?.length && (
            <Select
              placeholder={
                activeDepartment?.scheduleParams?.force_section
                  ? t('FORMS.SECTION_PLACEHOLDER')
                  : t('FORMS.ALL_SECTIONS')
              }
              value={activeSection || undefined}
              allowClear
              className="select-section"
              onChange={handleSectionChange}
              style={{ marginRight: 10 }}
              getPopupContainer={(trigger) => trigger.parentNode}
              dropdownMatchSelectWidth={false}
            >
              {!activeDepartment?.scheduleParams?.force_section && sections?.length > 1 && (
                <Option value="">{t('FORMS.ALL_SECTIONS')}</Option>
              )}
              {sections.map((section) => (
                <Option key={`section_${section.id!}`} value={section.id!}>
                  {section.name!}
                </Option>
              ))}
            </Select>
          )}
        <nav>
          {activeDepartment?.accountType !== 'RGPD' &&
            isFeatureEnabled(features, FEATURES.SCHEDULE) &&
            activeDepartment?.access?.shifts && (
              <Dropdown
                overlay={
                  <MenuHours
                    access={activeDepartment?.access}
                    department={activeDepartment}
                    scheduleBadges={badges.schedule}
                  />
                }
                placement="bottomLeft"
                trigger={['click']}
                getPopupContainer={(trigger) => trigger}
                className="menu_hours"
              >
                <NavLink activeClassName="is-active" to="/app/hours" onClick={desactivateLink}>
                  <i className="icon-calendar-alt"></i>
                  <span className="text">{t('MENU.SCHEDULE.TITLE')}</span>
                  <Badge
                    count={
                      badges.schedule
                        ? Object.keys(badges.schedule)
                            .filter((key) => key !== 'total' && key !== 'clockin' && key !== 'clockinVerification')
                            .reduce((sum, key) => sum + badges.schedule[key], 0)
                        : 0
                    }
                  />
                </NavLink>
              </Dropdown>
            )}
          {activeDepartment?.accountType !== 'RGPD' &&
            isFeatureEnabled(features, FEATURES.CLOCKING) &&
            activeDepartment?.access?.clockings && (
              <>
                {!activeDepartment?.clockinParams?.approveFreeclockin && !isTrial(activeDepartment) ? (
                  <Dropdown
                    overlay={
                      <MenuClocking
                        access={activeDepartment?.access}
                        department={activeDepartment}
                        scheduleBadges={badges.schedule}
                      />
                    }
                    placement="bottomLeft"
                    trigger={['click']}
                    getPopupContainer={(trigger) => trigger}
                    className="menu_clockings"
                  >
                    <NavLink
                      activeClassName="is-active"
                      to={`/app/clockings/${moment().startOf('week').format('YYYY-MM-DD')}`}
                      onClick={desactivateLink}
                    >
                      <i className="icon-stopwatch"></i>
                      <span className="text">{t('MENU.CLOCKINGS.TITLE')}</span>
                      <Badge count={badges.schedule?.clockinVerification} />
                    </NavLink>
                  </Dropdown>
                ) : (
                  <NavLink
                    id="menu-clockings"
                    className={`link-only ${pathname.startsWith('/app/clockings') ? 'is-active' : ''}`}
                    activeClassName="is-active"
                    to={`/app/clockings/${moment().startOf('week').format('YYYY-MM-DD')}`}
                  >
                    <i className="icon-stopwatch"></i>
                    <span className="text">{t('MENU.CLOCKINGS.TITLE')}</span>
                  </NavLink>
                )}
              </>
            )}
          {activeDepartment?.accountType !== 'RGPD' &&
            activeDepartment?.role &&
            isAdminOrHrOrPlanning(activeDepartment.role) && (
              <React.Fragment>
                {!isFree(activeDepartment!) &&
                !(isTrial(activeDepartment) && !isFeatureEnabled(features, FEATURES.SCHEDULE)) ? (
                  <Dropdown
                    overlay={
                      <MenuTeam
                        resources={resources}
                        department={activeDepartment}
                        teamBadges={badges.team}
                        usersBadges={badges.users}
                      />
                    }
                    placement="bottomLeft"
                    trigger={['click']}
                    getPopupContainer={(trigger) => trigger}
                    className="menu_team"
                  >
                    {renderTeam()}
                  </Dropdown>
                ) : (
                  <React.Fragment>{renderTeam()}</React.Fragment>
                )}
              </React.Fragment>
            )}
          {(isFeatureEnabled(features, FEATURES.SCHEDULE) ||
            isFeatureEnabled(features, FEATURES.CLOCKING) ||
            activeDepartment?.accountType !== 'RGPD') &&
            activeDepartment?.access?.reports && (
              <React.Fragment>
                {isFree(activeDepartment) ? (
                  <>{renderReportsLink()}</>
                ) : (
                  <Dropdown
                    overlay={<MenuReports department={activeDepartment} />}
                    placement="bottomLeft"
                    trigger={['click']}
                    getPopupContainer={(trigger) => trigger}
                    className="menu_reports"
                  >
                    {renderReportsLink()}
                  </Dropdown>
                )}
              </React.Fragment>
            )}
          {((isFeatureEnabled(features, FEATURES.CONTRACTS) && activeDepartment?.access?.documents) ||
            (isFeatureEnabled(features, FEATURES.DIMONA) &&
              activeDepartment?.params?.dimona &&
              activeDepartment?.access?.dimona) ||
            (isFeatureEnabled(features, FEATURES.SCHEDULE) &&
              activeDepartment?.access?.shifts &&
              activeDepartment.weeklyReports &&
              activeDepartment.weeklyReports.length > 0)) && (
            <Dropdown
              overlay={<MenuDocuments department={activeDepartment} />}
              placement="bottomLeft"
              trigger={['click']}
              getPopupContainer={(trigger) => trigger}
              className="menu_documents"
            >
              <a className={pathname.startsWith('/app/documents') ? 'is-active' : ''}>
                <i className="icon-doc-text-inv"></i>
                <span className="text">{t('MENU.DOCUMENTS.TITLE')}</span>
              </a>
            </Dropdown>
          )}
        </nav>
      </div>
      <div className="right">
        <nav style={{ display: 'flex', alignItems: 'center' }}>
          {impersonate && (
            <div style={{ marginRight: 10 }}>
              <Tooltip overlay="Click to copy">
                <Button type="ghost" onClick={onCopyDepartmentDetails}>
                  {activeDepartment?.accountId}/{activeDepartmentId}
                </Button>
              </Tooltip>
            </div>
          )}
          {!isTrial(activeDepartment!) && activeDepartment?.accountType !== 'RGPD' && (
            <div style={{ marginRight: 10 }}>
              <CommandsModal />
            </div>
          )}
          {activeDepartment?.accountType !== 'RGPD' && (
            <Dropdown
              overlay={
                <MenuNotifications
                  notifications={notifications}
                  setNotifications={setNotifications}
                  setShowNotification={setShowNotification}
                  loadingNotifications={loadingNotifications}
                />
              }
              visible={showNotification}
              placement="bottomLeft"
              align={{
                offset: [-75, 0],
              }}
              trigger={['click']}
              getPopupContainer={(trigger) => trigger}
              overlayStyle={{
                width: 360,
              }}
              onVisibleChange={(visible) => {
                setShowNotification(visible);
              }}
              className="menu_notifications"
            >
              <Badge dot count={badges?.notifications?.unread} style={{ backgroundColor: colors.green }}>
                <i
                  className="icon-bell-alt"
                  style={{ cursor: 'pointer', fontSize: 14 }}
                  onClick={() => {
                    setShowNotification(!showNotification);
                  }}
                />
              </Badge>
            </Dropdown>
          )}
          {activeDepartment?.accountType !== 'RGPD' && (
            <Dropdown
              overlay={<MenuHelp department={activeDepartment} openSupport={openSupport} />}
              placement="bottomLeft"
              trigger={['click']}
              getPopupContainer={(trigger) => trigger}
              className="menu_help"
            >
              <a>
                <span className="text">{t('MENU.HELP.TITLE')}</span>
              </a>
            </Dropdown>
          )}
          <Dropdown
            className="user menu_user"
            overlay={
              <MenuUser
                department={activeDepartment}
                departments={departments}
                handleDepartmentChange={handleDepartmentChange}
                handleLogout={handleLogout}
                userDetails={userDetails}
              />
            }
            trigger={['click']}
            placement="bottomRight"
            getPopupContainer={(trigger) => trigger}
          >
            <span className="as-link">
              <span className="department">{activeDepartment?.company || t('GLOBAL.ALL')}</span>
              <Avatar size={20} src={userDetails?.picture}>
                {userDetails?.initials}
              </Avatar>
              <DownOutlined />
            </span>
          </Dropdown>
          {activeDepartment?.accountType !== 'RGPD' && ['SUPER-ADMIN', 'SSA'].includes(userDetails?.role || '') && (
            <Dropdown
              overlay={<MenuSuperAdmin role={userDetails?.role} level={userDetails?.customLevel} />}
              placement="bottomRight"
              trigger={['click']}
              getPopupContainer={(trigger) => trigger}
              className="menu_super_admin"
            >
              <div className="link-settings" onClick={desactivateLink} style={{ cursor: 'pointer' }}>
                <i className="icon-lock" />
              </div>
            </Dropdown>
          )}
          {activeDepartment?.accountType !== 'RGPD' && (
            <NavLink className="link-settings menu_settings" activeClassName="is-active" to="/app/settings">
              <i className="icon-settings" />
            </NavLink>
          )}
        </nav>
      </div>
      <ModalPayingOption
        visible={modalPayingOptionVisible}
        onClose={() => setModalPayingOptionVisible(false)}
        setModalPayingOptionConfirmVisible={setModalPayingOptionConfirmVisible}
      />
      <ModalPayingOptionConfirm
        visible={modalPayingOptionConfirmVisible}
        onClose={() => setModalPayingOptionConfirmVisible(false)}
      />
    </header>
  );
};

const HeaderStyled = styled(Header)`
  top: 0;
  left: 0;
  position: fixed;
  height: 50px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  nav {
    > a,
    > .as-link {
      font-weight: 500;
      height: 30px;
      display: inline-flex;
      align-items: center;
      padding: 0 11px;
      color: ${Colors.blue};

      .department {
        padding-right: 14px;
        /* max-width: 110px;
        overflow: hidden;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; */
      }

      &:hover {
        cursor: pointer;
      }

      &.is-active {
        background: ${Colors.green};
        color: white;
        border-radius: 2px;

        i {
          color: white;
        }

        .ant-badge-count {
          background-color: white;
          color: ${Colors.green};
        }
      }

      i {
        color: ${Colors.green};
        padding-right: 5px;
      }

      .ant-dropdown {
        top: 50px !important;

        .ant-dropdown-menu-item .a.is-active .ant-badge-count {
          background-color: ${colors.green};
          color: white;
        }
      }
    }

    .ant-dropdown-menu {
      .ant-dropdown-menu-item {
        a {
          display: flex;
          align-items: center;
          justify-content: space-between !important;
          .ant-badge-count {
            background-color: ${colors.green} !important;
            color: white;
          }
        }
      }
    }
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: center;

    a.shyfter-logo {
      img {
        height: 22px;
      }
      img.logo-icon {
        display: none;
      }

      @media screen and (max-width: 768px) {
        img {
          display: none;
        }
        img.logo-icon {
          display: inline;
        }
      }
      &:after {
        content: '|';
        color: ${colors.greyLight};
        margin-right: 10px;
        margin-left: 10px;
      }
    }

    .select-section {
      min-width: 150px;
      max-width: 300px;
    }

    > nav {
      > a.ant-dropdown-trigger {
        font-size: 13px;
        text-transform: uppercase;
        font-weight: normal;
        div {
          text-transform: none;
        }

        > span.text {
          @media screen and (max-width: 1100px) {
            display: none;
          }
        }

        .ant-dropdown-menu-item {
          padding: 2px 12px;
          justify-content: space-between;
          align-items: center;
          > a {
            align-items: center;
            justify-content: flex-start;
            display: flex;
            .ant-badge {
              margin-left: 20px;
            }
          }
          &:last-child {
            padding-bottom: 10px;
          }
        }

        .ant-dropdown-menu-item-group-title {
          color: ${colors.green};
          font-weight: bold;
          font-size: 12px;
        }
        .ant-dropdown-menu-item-group-list {
          padding-top: 10px;
          &:before {
            content: '';
            height: 1px;
            width: calc(100% - 20px);
            position: absolute;
            background-color: #c4c4c4;
            margin-top: -8px;
          }
        }
      }
    }
  }

  .right {
    .user {
      .ant-dropdown {
        top: 50px !important;
      }
    }
    .link-settings {
      background: none;
      padding: 0;
      font-size: 16px;
      > i {
        color: ${Colors.grey};
      }
      &.is-active {
        > i {
          color: ${Colors.green};
        }
      }
    }
  }

  .icon-bell-alt {
    color: ${colors.grey};
  }

  .ant-dropdown-open {
    .icon-bell-alt {
      color: ${colors.blue};
    }
  }

  .link-only {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: normal;
  }
`;

export default HeaderStyled;

function openNotification(payload: any, departmentId: string) {
  const { id, type, message = '', description = '', action = null } = payload;

  const link = payload.action_url || payload.link;

  const onClick = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/v3/notifications/read`, {
      departmentId,
      id,
    });
    notification.close(id);
  };

  if (type === 'REFRESH_DATA' || message === 'REFRESH_DATA') {
    return null;
  }

  switch (action) {
    case 'DOWNLOAD': {
      notification.open({
        onClick,
        key: id,
        message,
        description,
        type: 'success',
        btn: (
          <Button
            type="primary"
            size="small"
            onClick={() => {
              window.open(link, '_blank');
            }}
          >
            {i18n.t('GLOBAL.DOWNLOAD')}
          </Button>
        ),
      });
      return null;
    }
    case 'LINK': {
      notification.open({
        onClick,
        key: id,
        message,
        description,
        type: 'info',
        btn: (
          <a style={{ color: colors.green }} href={link}>
            {i18n.t('GLOBAL.GO_TO_PAGE')}
          </a>
        ),
      });
      return null;
    }
    default:
      notification.open({
        type: 'info',
        message,
        description,
        onClick,
      });
      return null;
  }
}
