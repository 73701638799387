import api from '../../../../api/index';
import { V4WidgetSPositionUpdatePayload, V4WidgetUpdatePayload, V4WidgetResponse, V4WidgetDataResponse } from '../types/index';

export const getWidgets = async () => {
  const data = await api<V4WidgetResponse[]>(`widgets`);
  return data.data;
};

export const getWidget = async (slug: string, startDate: string, endDate: string, sectionId?: string) => {
  const data = await api<V4WidgetDataResponse>(
    `widgets/${slug}?startDate=${startDate}&endDate=${endDate}${sectionId ? `&sectionId=${sectionId}` : ''}`,
  );
  return data.data;
};

export const updateWidget = async (slug: string, payload: V4WidgetUpdatePayload) => {
  const data = await api<V4WidgetDataResponse>(`widgets/${slug}/configurations`, {
    method: 'PATCH',
    body: JSON.stringify(payload),
  });

  return data.data;
};

export const updateWidgetsPosition = async (payload: V4WidgetSPositionUpdatePayload) => {
  const data = await api<V4WidgetDataResponse>(`widgets/order `, {
    method: 'POST',
    body: JSON.stringify(payload),
  });

  return data.data;
};
