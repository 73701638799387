type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

interface ApiOptions extends RequestInit {
  method?: HttpMethod;
  headers?: HeadersInit;
  body?: string;
}

interface ApiResponse<T> {
  data: T;
  status: number;
}

const api = async <T>(endpoint: string, options: ApiOptions = {}): Promise<ApiResponse<T>> => {
  const url = new URL(`${process.env.REACT_APP_API_URL_V4}/${endpoint}`);
  const departmentId = localStorage.getItem('activeDepartmentId');
  if (departmentId) {
    url.searchParams.append('departmentId', departmentId);
  }

  const defaultHeaders: HeadersInit = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...options.headers,
  };

  const config: RequestInit = {
    ...options,
    headers: defaultHeaders,
  };

  const token = localStorage.getItem('accessToken');
  const impersonateToken = sessionStorage.getItem('impersonateToken');
  if (token || impersonateToken) {
    config.headers = {
      ...defaultHeaders,
      Authorization: `Bearer ${impersonateToken || token}`,
    };
  }

  try {
    const response = await fetch(url.toString(), config);
    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(`HTTP error! status: ${response.status}`);
      (error as any).response = responseData;
      (error as any).status = response.status;
      throw error;
    }

    return {
      data: responseData.data,
      status: response.status,
    };
  } catch (error) {
    throw error;
  }
};

export default api;
