import ScrollToTop from '@/components/ScrollToTop';
import AppContext from '@/pages/app/context';
import { DIMONA_WORK_TYPES } from '@/types/dimona.model';
import { IDocumentTemplate } from '@/types/document.model';
import { FEATURES } from '@/types/features.model';
import { IUserStatus } from '@/types/user-status.model';
import { difference, getWindowSize, isFeatureEnabled } from '@/utils';
import { Button, Checkbox, Drawer, Form, Input, InputNumber, Row, Select, Spin, notification } from 'antd';
import axios from 'axios';
import getSymbolFromCurrency from 'currency-symbol-map';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CustomColorPicker from '../CustomColorPicker';

const { Option } = Select;

interface Props {
  className?: string;
  visible: boolean;
  userStatus?: IUserStatus | null;
  onClose: () => void;
  onSave: (result: IUserStatus) => void;
}

const DrawerUserStatus: React.FC<Props> = ({ className, visible, userStatus, onClose, onSave }) => {
  const { i18n, t } = useTranslation(undefined, { useSuspense: false });
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [templates, setTemplates] = useState<IDocumentTemplate[]>([]);
  const [loadingTemplates, setLoadingTemplates] = useState<boolean>(false);
  const [counters, setCounters] = useState<{ id: string; name: string }[]>([]);
  const [loadingCounters, setLoadingCounters] = useState<boolean>(false);
  const [selectedCounters, setSelectedCounters] = useState<any[]>([]);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const locale = i18n.language || 'en';

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const {
    state: { activeDepartmentId, activeDepartment, features, hrPartners, ayWorkerTypes, loadingAyWorkerTypes },
  } = useContext(AppContext);

  const initialValues: any = {
    background: '#03a9f4',
  };

  useEffect(() => {
    form.resetFields();
    let fieldsValue: any = {
      ...initialValues,
    };

    if (userStatus) {
      fieldsValue = {
        ...fieldsValue,
        ..._(userStatus)
          .pick([
            'name',
            'background',
            'hourlyRate',
            'hourlyRateBruto',
            'workerType',
            'default_contract_template_id',
            'export_ssa',
            'enable_extra_hours',
            'enable_dayoff_counter',
            'worktime_calculation_method',
            'counterIds',
            'ay_id',
            'id',
            'employerCostCoefficient',
          ])
          .value(),
      };

      if (!userStatus.background) {
        fieldsValue.background = '#FFFFFF';
      }
    }
    form.setFieldsValue({ ...fieldsValue, ay_worker_id: userStatus?.ay_id ? String(userStatus?.ay_id) : null });

    let mounted = true;
    const cancelTokenSource = axios.CancelToken.source();
    setLoadingTemplates(true);
    setTemplates([]);
    setLoadingCounters(true);
    setCounters([]);
    setSelectedCounters(fieldsValue?.counterIds || []);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/documents/templates`, {
        cancelToken: cancelTokenSource.token,
        params: {
          departmentId: activeDepartmentId,
          filter: 'CONTRACT',
        },
      })
      .then(({ data }) => {
        if (mounted) {
          setTemplates(data.templates);
          setLoadingTemplates(false);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.error(error);
        }
        if (mounted) {
          setLoadingTemplates(false);
        }
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/user-categories-counters`, {
        cancelToken: cancelTokenSource.token,
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        if (mounted) {
          setCounters(data);
          setLoadingCounters(false);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.error(error);
        }
        if (mounted) {
          setLoadingCounters(false);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [userStatus]);

  const onFinish = async (values: any) => {
    setIsSaving(true);
    let userStatusResponse;

    try {
      if (userStatus && !_.isEmpty(userStatus)) {
        const differences = difference(values, userStatus);

        if ('counterIds' in differences) {
          differences.counterIds = values.counterIds || null;
        }

        if ('workerType' in differences) {
          differences.workerType = differences.workerType || null;
        }

        if ('worktime_calculation_method' in differences) {
          differences.worktime_calculation_method = differences.worktime_calculation_method || null;
        }

        if ('default_contract_template_id' in differences) {
          differences.default_contract_template_id = differences.default_contract_template_id || null;
        }

        if ('ay_worker_id' in differences) {
          differences.ay_worker_id = differences.ay_worker_id || null;
        }

        if (!_.isEmpty(differences)) {
          userStatusResponse = await axios.patch(
            `${process.env.REACT_APP_API_URL}/v3/user-categories/${userStatus.id!}`,
            {
              ...differences,
            },
            {
              params: {
                departmentId: activeDepartmentId,
              },
            },
          );
        }
      } else {
        userStatusResponse = await axios.post(`${process.env.REACT_APP_API_URL}/v3/user-categories`, {
          departmentId: activeDepartmentId,
          ...values,
        });
      }

      if (userStatusResponse) {
        onSave({
          ...userStatusResponse?.data.userCategory,
        });
        onClose();
      } else {
        onClose();
      }
    } catch (error) {
      console.error(error);
      const description = userStatus?.id
        ? t('GLOBAL.UPDATE_FAILED')
        : t('USER_CATEGORIES.USER_CATEGORIE_CREATION_FAILED');
      notification.open({
        message: t('GLOBAL.AN_ERROR_OCCURRED'),
        description,
        type: 'error',
      });
    }
    setIsSaving(false);
  };

  return (
    <Drawer
      forceRender={true}
      maskClosable={false}
      title={
        userStatus?.id ? t('USER_CATEGORIES.USER_CATEGORY_MODIFICATION') : t('USER_CATEGORIES.ADDING_USER_CATEGORY')
      }
      placement="right"
      visible={visible}
      destroyOnClose={true}
      onClose={onClose}
      className={className}
      width={windowSize.innerWidth > 900 ? 400 : '75%'}
    >
      <ScrollToTop />
      <Form form={form} layout="vertical" size="large" onFinish={onFinish} scrollToFirstError={true}>
        <Form.Item name="name" label={t('GLOBAL.NAME')} rules={[{ required: true }]}>
          <Input placeholder={t('USER_CATEGORIES.USER_CATEGORIE_NAME_PLACEHOLDER')} id="user-category-name" />
        </Form.Item>
        <Form.Item label={t('GLOBAL.NET_COST')}>
          <div className="ant-input-wrapper ant-input-group">
            <Form.Item name="hourlyRate" style={{ margin: 0 }}>
              <InputNumber className="ant-input" />
            </Form.Item>
            <span className="ant-input-group-addon">{getSymbolFromCurrency(`${activeDepartment?.currency}`)}/h</span>
          </div>
        </Form.Item>
        <Form.Item label={t('GLOBAL.GROSS_COST')}>
          <div className="ant-input-wrapper ant-input-group">
            <Form.Item name="hourlyRateBruto" style={{ margin: 0 }}>
              <InputNumber className="ant-input" />
            </Form.Item>
            <span className="ant-input-group-addon">{getSymbolFromCurrency(`${activeDepartment?.currency}`)}/h</span>
          </div>
        </Form.Item>
        <Form.Item label={t('GLOBAL.EMPLOYER_COST_COEFFICIENT')}>
          <Form.Item name="employerCostCoefficient" style={{ margin: 0 }}>
            <InputNumber className="ant-input" style={{ width: '100%' }} step="0.01" type="number" min={0} />
          </Form.Item>
        </Form.Item>
        {isFeatureEnabled(features, FEATURES.DIMONA) && activeDepartment?.params?.dimona && (
          <Form.Item label={t('GLOBAL.WORKER_TYPE')} name="workerType">
            <Select
              getPopupContainer={(trigger) => trigger}
              placeholder={t('FORMS.WORKER_TYPE_PLACEHOLDER')}
              allowClear={true}
            >
              {DIMONA_WORK_TYPES.map((workType) => {
                const { id } = workType;
                return (
                  <Option key={`workType_${id}`} value={id!}>
                    {t(`DIMONA.WORKTYPES.${id}`)}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        )}
        {isFeatureEnabled(features, FEATURES.CONTRACTS) && !!templates?.length && (
          <Form.Item name="default_contract_template_id" label={t('USER_CATEGORIES.DEFAULT_CONTRACT_TEMPLATE')}>
            <Select
              getPopupContainer={(trigger) => trigger}
              disabled={loadingTemplates}
              loading={loadingTemplates}
              placeholder={t('USER_CATEGORIES.DEFAULT_CONTRACT_TEMPLATE')}
              allowClear={true}
            >
              {templates?.map((department) => {
                const { id, name } = department;
                return (
                  <Option key={`template_${id}`} value={`${id}`}>
                    {name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        )}
        {isFeatureEnabled(features, FEATURES.CLOCKING) && (
          <Form.Item
            name={'worktime_calculation_method'}
            label={t('GLOBAL.GENERAL_WORKTIME_CALCULATION_METHOD')}
            style={{ width: '100%' }}
          >
            <Select
              style={{ width: '100%' }}
              placeholder={t('GLOBAL.SELECT')}
              getPopupContainer={(trigger) => trigger}
              allowClear={true}
            >
              <Option value={'PLANNING'}>{t('GLOBAL.PLANNED_HOURS')}</Option>
              <Option value={'CLOCKING'}>{t('GLOBAL.CLOCKING_HOURS')}</Option>
            </Select>
          </Form.Item>
        )}
        {isFeatureEnabled(features, FEATURES.HR_PARTNER) && hrPartners.length > 0 && (
          <Form.Item name="export_ssa" valuePropName="checked" style={{ margin: 0, padding: 0 }}>
            <Checkbox>{t('USER_CATEGORIES.EXPORT_TO_SSA')}</Checkbox>
          </Form.Item>
        )}
        {activeDepartment?.params?.showExtraHours !== 0 && (
          <Form.Item name="enable_extra_hours" valuePropName="checked" style={{ margin: 0, padding: 0 }}>
            <Checkbox>{t('USER_CATEGORIES.ENABLE_EXTRA_HOURS')}</Checkbox>
          </Form.Item>
        )}
        <Form.Item name="enable_dayoff_counter" valuePropName="checked" style={{ margin: 0, padding: 0 }}>
          <Checkbox>{t('USER_CATEGORIES.ENABLE_DAYOFF_COUNTER')}</Checkbox>
        </Form.Item>
        {!!activeDepartment?.scheduleParams?.sidebarCounters && (
          <React.Fragment>
            {loadingCounters ? (
              <Spin spinning={true} />
            ) : (
              <Form.Item name="counterIds" label={t('USER_CATEGORIES.EXTRA_COUNTERS')} style={{ marginTop: 20 }}>
                <Checkbox.Group onChange={setSelectedCounters}>
                  {counters?.map(({ name, id }) => (
                    <Row key={`counter_${id}`}>
                      <Checkbox
                        value={id}
                        style={{ lineHeight: '32px' }}
                        disabled={selectedCounters?.length >= 3 && !selectedCounters.find((x) => x === id)}
                      >
                        {name}
                      </Checkbox>
                    </Row>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            )}
          </React.Fragment>
        )}
        <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.background !== curValues.background}>
          {() => {
            const background = form.getFieldValue('background');
            return (
              <React.Fragment>
                <Form.Item name="background" style={{ display: 'none' }}>
                  <Input hidden id="user-category-background" />
                </Form.Item>
                <Form.Item
                  label={t('GLOBAL.COLOR')}
                  shouldUpdate={(prevValues, curValues) => prevValues.background !== curValues.background}
                >
                  {() => {
                    const background = form.getFieldValue('background');
                    return (
                      <React.Fragment>
                        <Form.Item name="background" style={{ display: 'none' }}>
                          <Input hidden id="section-background" />
                        </Form.Item>
                        <CustomColorPicker form={form} color={background} property="background" />
                      </React.Fragment>
                    );
                  }}
                </Form.Item>
              </React.Fragment>
            );
          }}
        </Form.Item>
        {/* {activeDepartment?.ay_core && (
          <div style={{ marginTop: 25 }}>
            <h2 style={{ color: Colors.ayBrand, fontWeight: 'bold' }}>AbsoluteYOU</h2>
            <Form.Item label={t('GLOBAL.AY_WORKER_TYPE')} name="ay_worker_id">
              <Select
                placeholder={t('GLOBAL.AY_WORKER_TYPE')}
                loading={loadingAyWorkerTypes}
                disabled={loadingAyWorkerTypes}
                allowClear
                showSearch
                filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {ayWorkerTypes.map((workerType) => (
                  <Select.Option value={String(workerType.id)}>{workerType[`name_${locale}`]}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        )} */}
        <div className="actions-container">
          <div className="actions">
            <Button type="primary" danger onClick={onClose}>
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button type="primary" htmlType="submit" loading={isSaving}>
              {t('GLOBAL.SAVE')}
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default styled(DrawerUserStatus)`
  .ant-checkbox-group {
  }
`;
