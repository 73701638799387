import React, { FC } from 'react';
import styled from "styled-components";

type NumberWidgetProps = {
  value: number | string
  className?: string;
}

const PlainTextWidget: FC<NumberWidgetProps> = ({ value, className }) => {
  return <div className={className}>{value}</div>;
};

const PlainTextWidgetStyled = styled(PlainTextWidget)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 35px);
  color: #00A651;
  font-size: 1.8rem;
  font-weight: bolder;
`

export default PlainTextWidgetStyled;
