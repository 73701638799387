import Axios from 'axios';
import { Empty } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Chart as Chartjs } from 'chart.js';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import styled from 'styled-components';
import { minutesToHoursAndOrMinutes } from '@/utils';

interface Props {
  className?: string;
  userMainId?: string;
  departmentId?: string;
  setHideDelayRate: React.Dispatch<React.SetStateAction<boolean>>;
}

const DelayRate: React.FC<Props> = ({ className, departmentId, userMainId, setHideDelayRate }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState<Chartjs | null>(null);

  const { t, i18n } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
  }, [i18n.language]);

  useEffect(() => {
    const cancelTokenSource = Axios.CancelToken.source();
    if (!departmentId || !userMainId) {
      return;
    }

    setIsLoading(true);
    if (chartInstance) {
      chartInstance.destroy();
      setChartInstance(null);
    }
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/dashboard/delay-rate`, {
      params: {
        userMainId,
        departmentId,
      },
      cancelToken: cancelTokenSource.token,
    })
      .then((response) => {
        const { dates, delays } = response.data;
        if (!!dates?.length) {
          const newChartInstance = new Chartjs(chartContainer.current!, {
            type: 'line',
            data: {
              labels: dates.map((date: number) => moment.unix(date).format('L')),
              datasets: [
                {
                  label: 'Retards',
                  data: delays,
                  fill: 'origin',
                  backgroundColor: 'rgb(237 110 93)',
                },
              ],
            },
            options: {
              plugins: {
                tooltip: {
                  callbacks: {
                    label: (item) => `${item.formattedValue} ${t('GLOBAL.MINUTES')}`,
                  },
                },
                legend: {
                  display: false,
                },
              },
              maintainAspectRatio: false,
              spanGaps: false,
              elements: {
                line: {
                  tension: 0.000001,
                },
              },
              scales: {
                x: {
                  display: false,
                },
                y: {
                  ticks: {
                    callback: (scale: number) => minutesToHoursAndOrMinutes(scale)
                  },
                },
              },
            },
          });
          setChartInstance(newChartInstance);
        } else {
          setHideDelayRate(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });

    return () => {
      cancelTokenSource.cancel();
    };
  }, [departmentId, userMainId]);

  return (
    <div className={className}>
      <Spin spinning={isLoading}>
        <div>
          <h4>{t('USERS.USER.DASHBOARD.DELAY_RATES')}</h4>
          {!isLoading && !chartInstance && <Empty />}
          <div
            className="chart-container"
            style={chartInstance ? { position: 'relative', height: 230, width: '100%' } : { display: 'none' }}
          >
            <canvas ref={chartContainer} />
          </div>
        </div>
      </Spin>
    </div>
  );
};

const DelayRateStyled = styled(DelayRate)`
  width: 100%;
  .ant-spin-nested-loading {
    width: 100%;
  }
`;
export default DelayRateStyled;
