export interface IDimona {
  id: string;
  user: {
    recordId: string;
    displayName: string;
  };
  startingDate: number;
  startingHour: string;
  endingDate: number;
  endingHour: string;
  workerType: string;
  plannedDaysNbr: number;
  plannedHoursNbr: number;
  dimonaPeriodId?: string;
  dimonaInsertDate?: number;
  niss?: string;
}

export interface IV4Dimona {
  id: string;
  user: {
    mainId: string;
    recordId: string;
    firstName: string;
    lastName: string;
    gener: number;
    birthDate: string;
    userStatusId: string | null;
  };
  pending?: boolean;
  status?: {
    label: string;
    active: boolean;
    cancelled: boolean;
    late: boolean;
    locked: boolean;
    indefinite: boolean;
  };
  result: {
    source: string;
    label: string;
    scheduled: boolean;
    errors: {
      blocking: boolean;
      message: string;
    }[];
  };
  history: {
    type: string;
    periodStart: string;
    periodEnd: string;
    sentAt: string;
    processedAt: string;
    errors: null | { message: string; blocking: boolean }[];
  }[];
  fileReference: string;
  dimonaPeriodId?: string;
  startDate: string;
  startHour: string;
  endDate: string | null;
  endHour: string;
  plannedHours: number;
  niss: string;
  workerType: string;
  jointCommissionNumber: string;
  createdAt: string;
  lastUpdateAt: string;
  processing: boolean;
  comment: string | null;
}

export interface IDimonaStatementFilters {
  /** search string */
  search: string;
  /** array of record ids */
  users: string[];
  /** if the dimona is linked to a shift or a clocking */
  filter?: string;
  /** dimona type ids (OTH, STU, FLX,...) */
  types: string[];
  /** the status of the dimona (active, closed,...) */
  state: string;
  /** display dimona errors */
  errors: boolean;
  /** display dimona pending */
  pending: boolean;
}

export const DIMONA_WORK_TYPES = [
  { id: 'S17', name: 'Sport - S17' },
  { id: 'O17', name: 'Other sectors - O17' },
  { id: 'OTH', name: 'Autre - OTH' },
  { id: 'STU', name: 'Étudiant - STU' },
  { id: 'FLX', name: 'Flexi-Job - FLX' },
  { id: 'IVT', name: 'Formation professionnelle - IVT' },
  { id: 'PMP', name: 'Mandat parlementaire - PMP' },
  { id: 'DWD', name: 'Non soumis aux cotisations - DWD' },
  { id: 'TRI', name: 'Stage de transition - TRI' },
  { id: 'STG', name: 'Stagiaire (reg.acc.trav) - STG' },
  { id: 'EXT', name: 'Travailleur occasionnel - EXT' },
  { id: 'ALT', name: 'Alternance - ALT' },
];

export const AUTO_DIMONA_WORK_TYPES = [
  { id: 'STU', name: 'Étudiant - STU' },
  { id: 'FLX', name: 'Flexi-Job - FLX' },
  { id: 'S17', name: 'Sport - S17' },
  { id: 'O17', name: 'Other sectors - O17' },
];
