import React, { FC } from 'react';
import styled from 'styled-components';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

import type { ChartData, ChartOptions } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

type BarChartWidgetProps = {
  data: ChartData<'bar'>;
  options?: ChartOptions<'bar'>;
  className?: string;
  showLegend?: boolean
};

const BarChartWidget: FC<BarChartWidgetProps> = ({ data, options, className, showLegend = true }) => {
  return (
    <div className={className}>
      <Bar
        data={data}
        plugins={[ChartDataLabels]}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: showLegend,
              labels: {
                usePointStyle: true,
                pointStyle: 'circle',
                boxWidth: 5,
                boxHeight: 5,
                font: {
                  weight: 'bold',
                },
              },
            },
            datalabels: {
              anchor: 'end',
              align: 'top',
              offset: -3,
              formatter: (v) => (v ? Math.round(v) : null),
            },
          },
          scales: {
            x: {
              grid: {
                drawBorder: false,
                display: false,
              },
            },
            y: {
              display: false,
              grace: '1%',
              grid: {
                display: false,
              },
            },
          },
          ...options,
        }}
      />
    </div>
  );
};

const BarChartWidgetStyled = styled(BarChartWidget)`
  height: calc(100% - 35px);
`;

export default BarChartWidgetStyled;
