import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

type ClockingStatusProps = {
  status: string;
  clockingType: 'start' | 'end';
  className?: string;
};

const ClockingStatus: FC<ClockingStatusProps> = ({ status, clockingType, className }) => {
  const { t } = useTranslation();

  const translationKey = useMemo(() => {
    switch (`${clockingType}_${status}`) {
      case 'start_late':
        return 'DASHBOARD.WIDGETS.CLOCKING_START_LATE';
      case 'start_early':
        return 'DASHBOARD.WIDGETS.CLOCKING_START_EARLY';
      case 'start_on-time':
        return 'DASHBOARD.WIDGETS.CLOCKING_START_ON_TIME';
      case 'end_late':
        return 'DASHBOARD.WIDGETS.CLOCKING_END_LATE';
      case 'end_early':
        return 'DASHBOARD.WIDGETS.CLOCKING_END_EARLY';
      case 'end_on-time':
        return 'DASHBOARD.WIDGETS.CLOCKING_END_ON_TIME';
      default:
        return '';
    }
  }, []);

  if (status === 'free') {
    return null;
  }

  return <div className={`${className} ${clockingType}_${status}`}>{t(translationKey)}</div>;
};

const ClockingStatusStyled = styled(ClockingStatus)`
  border-radius: 3px;
  width: fit-content;
  padding: 2px 7px;

  &.start_late,
  &.end_early {
    background-color: rgba(255, 198, 203, 0.65);
    color: rgb(177, 37, 43);
  }

  &.start_on-time,
  &.end_on-time {
    background-color: rgba(204, 255, 198, 0.65);
    color: rgba(31, 119, 3, 0.65);
  }

  &.start_free,
  &.end_free {
    background-color: rgba(202, 204, 218, 0.65);
    color: rgb(95, 100, 104);
  }

  &.start_early,
  &.end_late {
    background-color: rgba(189, 212, 255, 0.65);
    color: rgb(35, 102, 165);
  }
`;

export default ClockingStatusStyled;
