import AppContext from '@/pages/app/context';
import { Button, Form, Input, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  className?: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalGossip: React.FC<Props> = ({ className, visible, setVisible }) => {
  const { t } = useTranslation();
  const {
    state: { activeDepartmentId },
    dispatch,
  } = useContext(AppContext);
  const [form] = useForm();

  const onCancel = () => {
    setVisible(false);
  };

  const onCreateGossip = (values: any) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/v3/super-admin/gossip`, values, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then((data) => {
        setVisible(false);
        form.resetFields();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal
      title="Create gossip"
      className={className}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t('GLOBAL.CANCEL')}
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          {t('GLOBAL.SEND')}
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onCreateGossip}>
        <Form.Item name="text">
          <Input.TextArea rows={5} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default styled(ModalGossip)``;
