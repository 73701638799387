import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import Axios from 'axios';
import moment from 'moment/moment';
import AppContext from '@/pages/app/context';
import ModalYesterdayTurnover from '@/pages/app/components/ModalYesterdayTurnover';
import { isFeatureEnabled } from '@/utils';
import { FEATURES } from '@/types/features.model';

const DailyTurnover: FC<{ widgetsLoading: boolean }> = ({ widgetsLoading }) => {
  const {
    state: { activeDepartment, activeSection, features },
  } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [lastTurnoverTimestamp, setLastTurnoverTimestamp] = useState<number | null>(null);

  const show = useMemo(() => {
    return (
      !widgetsLoading &&
      activeDepartment?.role === 'ADMIN' &&
      activeDepartment.params?.enableTurnover &&
      activeDepartment.accountType !== 'TRIAL' &&
      activeDepartment.accountType !== 'TRIAL_UNCONFIRMED' &&
      isFeatureEnabled(features, FEATURES.PRODUCTIVITY) &&
      localStorage.getItem(`${activeDepartment?.id}_hideTurnover`) !== moment().format('YYYY-MM-DD')
    );
  }, [activeDepartment, widgetsLoading]);

  useEffect(() => {
    if (!show) {
      return;
    }

    const cancelTokenSource = Axios.CancelToken.source();
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/insights/daily-turnover/last`, {
      params: {
        departmentId: activeDepartment?.id,
        sectionId: activeSection,
      },
      cancelToken: cancelTokenSource.token,
    })
      .then(({ data }) => {
        setLoading(false);
        setLastTurnoverTimestamp(data.timestamp);
      })
      .catch(() => {
        setLoading(false);
        setLastTurnoverTimestamp(null);
      });
  }, [show, activeDepartment, activeSection]);

  if (!show || loading) {
    return null;
  }

  return (
    <ModalYesterdayTurnover
      departmentId={activeDepartment?.id}
      lastTurnover={lastTurnoverTimestamp}
      onClose={() => setLastTurnoverTimestamp(null)}
    />
  );
};

export default DailyTurnover;
