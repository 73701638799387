import api from '@/api';
import { IV4Dimona } from '@/types/dimona.model';

export const getDimonas = async (startDate: string, endDate: string) => {
  const data = await api<IV4Dimona[]>(`dimonas?startDate=${startDate}&endDate=${endDate}`);
  return data.data;
};

export const getDimonasPending = async (startDate: string, endDate: string) => {
  const data = await api<IV4Dimona[]>(`dimonas/pending?startDate=${startDate}&endDate=${endDate}`);
  return data.data;
};

export const getDimonasError = async (startDate: string, endDate: string) => {
  const data = await api<IV4Dimona[]>(`dimonas/errors?startDate=${startDate}&endDate=${endDate}`);
  return data.data;
};
