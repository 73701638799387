import { FC, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { LatLngBoundsLiteral } from 'leaflet';

type LocationWidgetTriggerResizeProps = {
  editing: boolean;
  bounds: LatLngBoundsLiteral;
};

const LocationWidgetTriggerResize: FC<LocationWidgetTriggerResizeProps> = ({ editing, bounds }) => {
  const map = useMap();
  useEffect(() => {
    if (!map) return;
    const interval = setInterval(() => map.invalidateSize(), 3000);

    if (!editing) {
      clearInterval(interval);
      map.dragging.enable();
    } else {
      map.dragging.disable();
    }

    return () => clearInterval(interval);
  }, [map, editing]);

  useEffect(() => {
    if (!map) return;

    map.fitBounds(bounds)
  }, [map, bounds]);

  return null;
};

export default LocationWidgetTriggerResize;
