import AuthContext from '@/context';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IAttribut } from '@/types/attribut.model';
import { IClocking, IClockingTask } from '@/types/clocking.model';
import { FEATURES } from '@/types/features.model';
import { ISection } from '@/types/section.model';
import { ISkill } from '@/types/skill.model';
import { getWindowSize, isFeatureEnabled, valueForSearch } from '@/utils';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, DatePicker, Divider, Drawer, Form, Image, Input, Modal, Select } from 'antd';
import { default as Axios, default as axios } from 'axios';
import { LatLngBoundsLiteral, Map, divIcon } from 'leaflet';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapContainer, Marker, Popup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import VectorTileLayer from 'react-leaflet-vector-tile-layer';
import styled from 'styled-components';
import DrawerTaskDetail from './DrawerTaskDetail';
import ModalClockingToAttach from './ModalClockingToAttach';
import ModalSaveClocking from './ModalSaveClocking';
moment.tz.setDefault('Atlantic/Reykjavik');

const { OptGroup, Option } = Select;
const { TextArea } = Input;
const { Meta } = Card;

const iconContainerStyle = `
  width: 30px;
  height: 30px;
  left: -15px;
  top: -15px;
  position: relative;
  border-radius: 30px 30px 0;
  transform: rotate(45deg);
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const iconStyle = `
  color: white;
  display: block;
  transform: rotate(-45deg);
  font-weight: bold;
  font-size: 8px;
`;

const makeIcon = (color: string, text: string) =>
  divIcon({
    className: 'my-custom-pin',
    iconSize: [30, 30],
    iconAnchor: [0, 20],
    popupAnchor: [0, -30],
    html: `<div style="${iconContainerStyle} background-color:${color}"><span style="${iconStyle}">${text}</span></div>`,
  });

interface Props {
  className?: string;
  clockingId: string | null;
  visible: boolean;
  dataSourceExplode?: any[];
  updateDataSource?: () => void;
  setActiveClockingId?: React.Dispatch<React.SetStateAction<string | null>>;
  setDataSourceExplode?: React.Dispatch<React.SetStateAction<any[]>>;
  onClose: () => void;
  onSave: (clockingId: any) => void;
}

const DrawerClocking: React.FC<Props> = ({
  className,
  visible,
  clockingId,
  dataSourceExplode,
  onClose,
  onSave,
  setActiveClockingId,
  updateDataSource,
  setDataSourceExplode,
}) => {
  const {
    state: {
      activeDepartment: department,
      loadingResources,
      skills,
      loadingSkills,
      resources,
      loadingSections,
      sections,
      users,
      features,
    },
  } = useContext(AppContext);
  const {
    state: { userDetails },
  } = useContext(AuthContext);
  const { id: departmentId } = department || {};
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [form] = Form.useForm();
  const [clocking, setClocking] = useState<IClocking | null>(null);
  const [newData, setNewData] = useState<any>(null);
  const [taskDetail, setTaskDetail] = useState<IClockingTask | null>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [clockingToAttach, setClockingToAttach] = useState<string | null>(null);
  const [hrPartners, setHrPartners] = useState<any[]>([]);
  const [loadingHrPartners, setLoadingHrPartners] = useState<boolean>(false);
  const [filteredSkills, setFilteredSkills] = useState<ISkill[]>(skills);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const CANNOT_EDIT_OWN_CLOCKINGS = clocking
    ? clocking.userRecordId == department?.userRecordId && userDetails
      ? userDetails.params
        ? !userDetails.params.params?.allow_edit_own_clockings
        : false
      : false
    : false;

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (visible) {
      getHrPartners();
    }
  }, [visible]);

  const getHrPartners = () => {
    const cancelTokenSource = axios.CancelToken.source();
    setLoadingHrPartners(true);
    setHrPartners([]);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/operations/hr-partners`, {
        params: {
          departmentId: departmentId,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        const hr = response.data;
        setHrPartners(hr);
        setLoadingHrPartners(false);
      })
      .catch((error) => {
        setLoadingHrPartners(false);
        console.log(error);
      });

    return () => {
      cancelTokenSource.cancel();
    };
  };

  useEffect(() => {
    setClocking(null);
    setClockingToAttach(null);
    form.resetFields();
    let fieldsValue: any = {};
    const bounds: LatLngBoundsLiteral = [];
    if (clockingId) {
      const cancelTokenSource = Axios.CancelToken.source();
      Axios.get(`${process.env.REACT_APP_API_URL}/v3/clockings/${clockingId}`, {
        cancelToken: cancelTokenSource.token,
      })
        .then((response) => {
          const clocking = response.data as IClocking;
          setClocking(clocking);
          const {
            pauses,
            checkIn,
            checkOut,
            comment,
            tools,
            section = null,
            attributes = [],
            hr_code,
            skills,
            tasks,
          } = clocking;
          const start = checkIn?.start;
          const end = checkOut?.end;

          fieldsValue = {
            ...fieldsValue,
            comment,
            start: start ? moment.unix(start) : null,
            end: end ? moment.unix(end) : null,
            pauses: pauses?.map((pause) => {
              const { start, end } = pause;
              return {
                start: start ? moment.unix(start) : null,
                end: end ? moment.unix(end) : null,
              };
            }),
            tools,
            section: section ? section.id : null,
            attributes: attributes?.map((x) => x.id),
            hr_code,
            skills: skills && skills.length > 0 ? skills[0].id : [],
            tasks: tasks
              ? tasks.reduce((acc: any, task) => {
                  acc[task!.id!] = {
                    start: task.start ? moment.unix(task.start).format('HH:mm') : null,
                    start_time: task.start,
                    end: task.end ? moment.unix(task.end).format('HH:mm') : null,
                    end_time: task.end,
                    taskTypeId: task.taskTypeId,
                  };
                  return acc;
                }, {})
              : {},
          };

          const {
            checkOut: { location: checkOutLocation } = {},
            checkIn: { location: checkInLocation } = {},
          } = clocking;
          if (checkInLocation) {
            const { latitude, longitude } = checkInLocation;
            if (latitude && longitude) {
              bounds.push([latitude, longitude]);
            }
          }
          if (checkOutLocation) {
            const { latitude, longitude } = checkOutLocation;
            if (latitude && longitude) {
              bounds.push([latitude, longitude]);
            }
          }
          BOUNDS = bounds?.length ? bounds : [[0, 0]];
          form.setFieldsValue(fieldsValue);
        })
        .catch((error) => {
          console.log(error);
          BOUNDS = [[0, 0]];
          form.setFieldsValue(fieldsValue);
        });

      return () => {
        cancelTokenSource.cancel();
      };
    }
  }, [clockingId]);

  useEffect(() => {
    getUserParams();
  }, [clocking]);

  const getUserParams = () => {
    if (!clocking) return;

    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/users/${clocking.userRecordId}/params`, {
        params: {
          departmentId,
        },
      })
      .then(({ data }) => {
        const { skills: userSkills } = data;
        const filteredSkills = skills.filter((skill) => userSkills.includes(skill.id));
        setFilteredSkills(filteredSkills);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onWantToDelete = (clockingId: string | undefined | null) => {
    Modal.confirm({
      title: t('GLOBAL.DELETION'),
      icon: null,
      content: t('CLOCKINGS.MODAL_DELETE_CLOCKING.CONTENT'),
      cancelText: t('GLOBAL.CANCEL'),
      okText: t('GLOBAL.REMOVE'),
      okType: 'danger',
      onOk: () => {
        if (clockingId) {
          onDelete(clockingId);
        }
      },
      onCancel: () => {},
    });
  };

  const onDelete = (clockingId: string) => {
    setIsDeleting(true);
    Axios.delete(`${process.env.REACT_APP_API_URL}/v3/clockings/${clockingId}`, {
      params: {
        departmentId,
      },
    })
      .then((response) => {
        setIsDeleting(false);
        onSave(clockingId);
        onClose();
      })
      .catch((error) => {
        console.error(error);
        setIsDeleting(false);
      });
  };

  const onFinish = (values: any) => {
    const {
      comment,
      start,
      end,
      pauses,
      section,
      attributes,
      hr_code,
      skills: formSkills,
      tasks,
    }: {
      comment: string;
      start: Moment;
      end: Moment;
      pauses: { start: Moment; end: Moment }[];
      section?: ISection;
      attributes: IAttribut[];
      hr_code: string;
      skills: string;
      tasks: {
        [key: string]: { start: string; end: string; start_time: number; end_time: number; taskTypeId: string };
      };
    } = values;
    const foundSkill = skills.find((s) => s.id == formSkills);
    const tasksArray = tasks
      ? Object.entries(tasks).map(([id, task]) => {
          let originalStart = moment.unix(task.start_time);
          const [newStartHours, newStartMinutes] = task.start.split(':').map(Number);
          originalStart = originalStart.hour(newStartHours).minute(newStartMinutes);
          const updatedStart = originalStart.unix();

          let originalEnd = moment.unix(task.end_time);
          const [newEndHours, newEndMinutes] = task.end.split(':').map(Number);
          originalEnd = originalEnd.hour(newEndHours).minute(newEndMinutes);
          const updatedEnd = originalEnd.unix();
          return {
            id,
            start: updatedStart,
            end: updatedEnd,
            taskTypeId: task.taskTypeId,
          };
        })
      : undefined;
    setNewData({
      id: clockingId,
      comment,
      start: start.unix(),
      end: end ? end.unix() : null,
      pauses: pauses.map((pause) => ({
        start: pause.start.unix(),
        end: pause.end.unix(),
      })),
      section,
      attributes,
      hr_code: hr_code ? hr_code : null,
      skills: foundSkill ? [foundSkill.id] : [],
      tasks: tasksArray,
    });
  };

  const start = clocking?.checkIn?.start;
  return (
    <Drawer
      forceRender={true}
      maskClosable={false}
      destroyOnClose={true}
      className={className}
      width={windowSize.innerWidth > 900 ? 530 : '75%'}
      visible={visible}
      title={t('CLOCKINGS.DRAWER_UPDATE_CLOCKING.TITLE')}
      onClose={onClose}
    >
      <div style={{ marginBottom: 15 }}>
        <h2 style={{ margin: 0 }}>{users?.find((x) => x.recordId === clocking?.userRecordId)?.displayName}</h2>
        {clocking?.checkIn && clocking?.checkIn?.origin && (
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
            <p style={{ margin: 0, color: colors.grey }}>
              {t(`CLOCKINGS.ORIGINS.CHECK_IN.${clocking.checkIn.origin}`)}
            </p>
          </div>
        )}
        {clocking?.checkOut && clocking?.checkOut?.origin && (
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
            <p style={{ margin: 0, color: colors.grey }}>
              {t(`CLOCKINGS.ORIGINS.CHECK_OUT.${clocking.checkOut.origin}`)}
            </p>
          </div>
        )}
      </div>
      <Form form={form} layout="vertical" size="large" onFinish={onFinish} scrollToFirstError={true}>
        <Form.Item name="start" label={t('GLOBAL.START')} rules={[{ required: true }]}>
          <DatePicker
            style={{ width: '100%' }}
            showTime
            format={'L - HH:mm'}
            onSelect={(e) => {
              form.setFieldsValue({
                start: e,
              });
            }}
            disabled={CANNOT_EDIT_OWN_CLOCKINGS}
          />
        </Form.Item>
        <Form.Item name="end" label={t('GLOBAL.END')}>
          <DatePicker
            style={{ width: '100%' }}
            showTime
            format={'L - HH:mm'}
            onSelect={(e) => {
              form.setFieldsValue({
                end: e,
              });
            }}
            disabled={CANNOT_EDIT_OWN_CLOCKINGS}
          />
        </Form.Item>
        {isFeatureEnabled(features, FEATURES.SECTIONS) && !!sections?.length && (
          <Form.Item name="section" label={t('GLOBAL.SECTION')}>
            <Select
              clearIcon
              getPopupContainer={(trigger) => trigger}
              showSearch
              placeholder={t('FORMS.SECTION_PLACEHOLDER')}
              optionFilterProp="children"
              loading={loadingSections}
              disabled={loadingSections || CANNOT_EDIT_OWN_CLOCKINGS}
              allowClear={true}
              filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              <Option value={''}>{t('GLOBAL.NO_SECTION')}</Option>
              {sections.map((section) => (
                <Option key={`section_${section.id}`} value={`${section.id}`}>
                  {section.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {!!isFeatureEnabled(features, FEATURES.SKILLS) && !!skills.length && (
          <Form.Item name="skills" label={t('GLOBAL.SKILL')}>
            <Select
              getPopupContainer={(trigger) => trigger}
              showSearch
              placeholder={t('GLOBAL.SKILL')}
              optionFilterProp="children"
              loading={loadingSkills}
              disabled={loadingSkills || CANNOT_EDIT_OWN_CLOCKINGS}
              allowClear={true}
              filterOption={(input, option) => {
                if (option) {
                  if (!option.label) {
                    let children = option.children;
                    if (Array.isArray(children)) {
                      children = children.join();
                    }
                    return valueForSearch(children).includes(valueForSearch(input));
                  }
                }
                return false;
              }}
            >
              {filteredSkills.map((skill = {}) => (
                <Option value={skill.id!} key={`skill_${skill.id}`}>
                  {skill.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {!!isFeatureEnabled(features, FEATURES.ATTRIBUTES) && !!resources?.length && (
          <Form.Item name="attributes" label={t('GLOBAL.ATTRIBUTES')}>
            <Select
              mode="multiple"
              clearIcon
              getPopupContainer={(trigger) => trigger}
              showSearch
              placeholder={t('FORMS.ATTRIBUTES_PLACEHOLDER')}
              optionFilterProp="children"
              loading={loadingResources}
              disabled={loadingResources || CANNOT_EDIT_OWN_CLOCKINGS}
              allowClear={true}
              filterOption={(input, option) => {
                if (option) {
                  if (!option.label) {
                    let children = option.children;
                    if (Array.isArray(children)) {
                      children = children.join();
                    }
                    return valueForSearch(children).includes(valueForSearch(input));
                  }
                }
                return false;
              }}
            >
              {resources.map((resource = {}) => (
                <OptGroup label={resource.name} key={`resource_${resource.id}`}>
                  {(resource.attributes || []).map((attribut) => (
                    <Option value={attribut.id!} key={`attribut_${attribut.id}`}>
                      {attribut.name}
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          </Form.Item>
        )}
        {isFeatureEnabled(features, FEATURES.SCHEDULE) && clocking?.shiftId === '0' && (
          <Button
            disabled={CANNOT_EDIT_OWN_CLOCKINGS}
            style={{ width: '100%' }}
            onClick={() => setClockingToAttach(clockingId)}
          >
            {t('CLOCKINGS.MODAL_CLOCKING_TO_ATTACH.TITLE')}
          </Button>
        )}
        <Divider orientation="left" plain>
          {t('GLOBAL.BREAKS')}
        </Divider>
        <Form.List name="pauses">
          {(pauseItems, { add, remove }) => {
            return (
              <div>
                {pauseItems.map((pauseItem) => {
                  return (
                    <div className="form-list" key={pauseItem.key}>
                      <Form.Item
                        name={[pauseItem.name, 'start']}
                        fieldKey={[pauseItem.fieldKey, 'start']}
                        style={{ width: '100%' }}
                        rules={[{ required: true }]}
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          showTime
                          format={'L - HH:mm'}
                          defaultPickerValue={start ? moment.unix(start) : undefined}
                          onSelect={(e) => {
                            const pauses = [...form.getFieldValue('pauses')];
                            if (pauses[pauseItem.fieldKey]) {
                              pauses[pauseItem.fieldKey].start = e;
                            } else if (pauses[pauses.length - 1]) {
                              pauses[pauses.length - 1].start = e;
                            }
                            form.setFieldsValue({
                              pauses,
                            });
                          }}
                          disabled={CANNOT_EDIT_OWN_CLOCKINGS}
                        />
                      </Form.Item>
                      <div style={{ margin: '0 10px' }}>-</div>
                      <Form.Item
                        name={[pauseItem.name, 'end']}
                        fieldKey={[pauseItem.fieldKey, 'end']}
                        style={{ width: '100%', marginRight: 10 }}
                        rules={[{ required: true }]}
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          showTime
                          format={'L - HH:mm'}
                          defaultPickerValue={start ? moment.unix(start) : undefined}
                          onSelect={(e) => {
                            const pauses = [...form.getFieldValue('pauses')];
                            if (pauses[pauseItem.fieldKey]) {
                              pauses[pauseItem.fieldKey].end = e;
                            } else if (pauses[pauses.length - 1]) {
                              pauses[pauses.length - 1].end = e;
                            }
                            form.setFieldsValue({
                              pauses,
                            });
                          }}
                          disabled={CANNOT_EDIT_OWN_CLOCKINGS}
                        />
                      </Form.Item>
                      <MinusCircleOutlined
                        onClick={() => {
                          remove(pauseItem.name);
                        }}
                      />
                    </div>
                  );
                })}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      const start = clocking?.checkIn?.start;
                      const defaultDate = start ? moment.unix(start) : undefined;
                      add({
                        start: defaultDate,
                        end: defaultDate,
                      });
                    }}
                    block
                    disabled={CANNOT_EDIT_OWN_CLOCKINGS}
                  >
                    <PlusOutlined /> {t('CLOCKINGS.ADD_BREAK')}
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
        {isFeatureEnabled(features, FEATURES.TASKS) && !!clocking?.tasks?.length && (
          <React.Fragment>
            <Divider orientation="left" plain>
              {t('GLOBAL.TASKS')}
            </Divider>
            {clocking?.tasks?.map((task) => {
              return (
                <div key={`task_${task.id}`} style={{ display: 'flex', marginBottom: 10 }}>
                  <Input value={task.task?.name} disabled style={{ marginRight: 10, flex: 1 }} />
                  {!!task?.flow && (
                    <button
                      type="button"
                      style={{ marginRight: 10 }}
                      onClick={() => {
                        setTaskDetail(task);
                      }}
                    >
                      <i className="icon-layers" />
                    </button>
                  )}
                  <Form.Item name={['tasks', task.id!, 'start_time']} style={{ margin: 0 }} hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item name={['tasks', task.id!, 'start']} style={{ margin: 0 }}>
                    <Input style={{ width: '70px', marginRight: '10px', flexShrink: 0 }} disabled={!task.end} />
                  </Form.Item>
                  <Form.Item name={['tasks', task.id!, 'end_time']} style={{ margin: 0 }} hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item name={['tasks', task.id!, 'end']} style={{ margin: 0 }}>
                    <Input style={{ width: '70px', marginRight: '10px', flexShrink: 0 }} disabled={!task.end} />
                  </Form.Item>
                  <Form.Item name={['tasks', task.id!, 'taskTypeId']} hidden>
                    <Input />
                  </Form.Item>
                </div>
              );
            })}
          </React.Fragment>
        )}
        {!!clocking?.posPictures?.length && (
          <React.Fragment>
            <Divider orientation="left" plain>
              {t('GLOBAL.PHOTOS')}
            </Divider>
            <div className="pictures">
              <Image.PreviewGroup>
                {clocking?.posPictures?.map((image) => {
                  const { id, uri, action, start } = image;
                  return (
                    <Card
                      key={id}
                      style={{ width: 200, marginRight: 10 }}
                      cover={<Image src={uri} style={{ width: '100%' }} />}
                    >
                      {/* <Meta title={action?.toUpperCase()} description={moment.unix(start!).format('L HH:mm')} /> */}
                      <Meta title={action?.toUpperCase()} style={{ textAlign: 'center' }} />
                    </Card>
                  );
                })}
              </Image.PreviewGroup>
            </div>
          </React.Fragment>
        )}
        {!!clocking?.pictures?.length && (
          <React.Fragment>
            <Divider orientation="left" plain>
              {t('GLOBAL.PHOTOS')}
            </Divider>
            <div className="pictures">
              <Image.PreviewGroup>
                {clocking?.pictures?.map((image, index) => (
                  <Image
                    key={`image_${index}`}
                    src={image}
                    preview={true}
                    width={100}
                    height={100}
                    style={{ objectFit: 'cover' }}
                  />
                ))}
              </Image.PreviewGroup>
            </div>
          </React.Fragment>
        )}
        {!!clocking?.tools?.length && (
          <React.Fragment>
            <Divider orientation="left" plain>
              {t('GLOBAL.TOOLS')}
            </Divider>
            <Form.List name="tools">
              {(toolItems) => {
                return (
                  <div>
                    {toolItems.map((toolItem) => {
                      return (
                        <div className="form-list" key={toolItem.key}>
                          <Form.Item
                            name={[toolItem.name, 'quantity']}
                            fieldKey={[toolItem.fieldKey, 'quantity']}
                            style={{ display: 'inline-block', marginRight: '10px', width: 60 }}
                          >
                            <Input disabled={true} style={{ textAlign: 'right' }} />
                          </Form.Item>
                          <Form.Item
                            name={[toolItem.name, 'name']}
                            fieldKey={[toolItem.fieldKey, 'name']}
                            style={{ display: 'inline-block', flex: '1' }}
                          >
                            <Input disabled={true} />
                          </Form.Item>
                        </div>
                      );
                    })}
                  </div>
                );
              }}
            </Form.List>
          </React.Fragment>
        )}
        {(!!clocking?.checkIn?.location || !!clocking?.checkOut?.location) && (
          <React.Fragment>
            <Divider orientation="left" plain>
              {t('GLOBAL.GEOLOCATION')}
            </Divider>
            <div className="map" style={{ marginBottom: 15 }}>
              <MapContainer
                style={{
                  height: 310,
                  width: '100%',
                  borderRadius: 3,
                }}
                maxZoom={16}
                minZoom={8}
                center={[0, 0]}
                scrollWheelZoom={false}
                whenCreated={(map: Map) => {
                  setTimeout(() => {
                    map.fitBounds(BOUNDS);
                  });
                }}
              >
                <VectorTileLayer
                  attribution="© MapTiler © OpenStreetMap contributors"
                  styleUrl="https://api.maptiler.com/maps/70ddf71e-c9a7-4112-b00f-9124ba482a89/style.json?key=a9UYy1T8E5qkxvDgC0fH"
                />
                <MarkerClusterGroup>
                  {clocking?.checkIn?.location && (
                    <Marker
                      icon={makeIcon(colors.green, 'IN')}
                      position={[clocking.checkIn!.location!.latitude!, clocking.checkIn!.location!.longitude!]}
                    >
                      <Popup>
                        <b>{t('GLOBAL.CHECK_IN')}</b>
                        <br />
                        <span>{moment.unix(clocking.checkIn!.start!).format('HH:mm')}</span>
                      </Popup>
                    </Marker>
                  )}
                  {clocking?.checkOut?.location && (
                    <Marker
                      icon={makeIcon(colors.red, 'OUT')}
                      position={[clocking.checkOut!.location!.latitude!, clocking.checkOut!.location!.longitude!]}
                    >
                      <Popup>
                        <b>{t('GLOBAL.CHECK_OUT')}</b>
                        <br />
                        <span>{moment.unix(clocking.checkOut!.end!).format('HH:mm')}</span>
                      </Popup>
                    </Marker>
                  )}
                </MarkerClusterGroup>
              </MapContainer>
            </div>
          </React.Fragment>
        )}
        {hrPartners.length > 0 && department?.hr_codes && department?.hr_codes.length > 0 && (
          <Form.Item name="hr_code" label={t('GLOBAL.HR_CODE')}>
            <Select
              getPopupContainer={(trigger) => trigger}
              showSearch
              placeholder={t('GLOBAL.HR_CODE')}
              optionFilterProp="children"
              loading={loadingHrPartners}
              filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              allowClear
              disabled={loadingHrPartners || CANNOT_EDIT_OWN_CLOCKINGS}
            >
              {department?.hr_codes.map((hr_code) => (
                <Option key={hr_code.id} value={`${hr_code.id}`}>
                  {hr_code.description}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item label={t('GLOBAL.COMMENT')} name={['comment']}>
          <TextArea rows={4} disabled={true} />
        </Form.Item>
        {!!clocking?.logs?.length && (
          <React.Fragment>
            <Divider orientation="left" plain>
              {t('CLOCKINGS.CHANGE_HISTORY')}
            </Divider>
            {clocking?.logs?.map((log) => {
              const { id, comment, details, name, start } = log;
              return (
                <div key={`clockingLog_${id}`} className="clocking-log">
                  <h4>
                    {moment.unix(start).format('L HH:mm')} {t('GLOBAL.BY').toLocaleLowerCase()} {name}
                  </h4>
                  <ul>
                    {details?.map((detail, index) => (
                      <li key={`clockingLog_${id}_${index}`}>{detail}</li>
                    ))}
                  </ul>
                  <TextArea value={comment} disabled={true} rows={2} className="clocking-log-comment" />
                </div>
              );
            })}
          </React.Fragment>
        )}
        <div className="actions-container">
          <div className="actions">
            <Button
              type="primary"
              danger
              onClick={() => {
                onClose();
              }}
            >
              {t('GLOBAL.CANCEL')}
            </Button>
            <Button
              type="default"
              danger
              loading={isDeleting}
              onClick={() => {
                if (clocking) {
                  onWantToDelete(clocking!.id);
                }
              }}
              disabled={CANNOT_EDIT_OWN_CLOCKINGS}
            >
              {t('GLOBAL.REMOVE')}
            </Button>
            <Button type="primary" htmlType="submit" disabled={CANNOT_EDIT_OWN_CLOCKINGS}>
              {t('GLOBAL.MODIFY')}
            </Button>
          </div>
        </div>
      </Form>
      <ModalClockingToAttach
        departmentId={departmentId}
        clockingId={clockingToAttach}
        onSave={(shiftId) => {
          setClocking({ ...clocking, shiftId } as IClocking);
          if (setActiveClockingId) {
            setActiveClockingId(null);
          }
          if (updateDataSource) {
            updateDataSource();
          }
        }}
        onClose={() => {
          setClockingToAttach(null);
        }}
      />
      <ModalSaveClocking
        departmentId={departmentId}
        data={newData}
        visible={!!newData}
        onClose={() => setNewData(false)}
        onSave={() => onSave(null)}
        dataSourceExplode={dataSourceExplode}
        setDataSourceExplode={setDataSourceExplode}
      />
      <DrawerTaskDetail
        departmentId={departmentId}
        task={taskDetail}
        visible={!!taskDetail}
        onClose={() => setTaskDetail(null)}
      />
    </Drawer>
  );
};

export default styled(DrawerClocking)`
  h2 {
    font-size: 22px;
    line-height: 21px;
    color: ${colors.green};
    margin-bottom: 25px;
  }

  .form-list {
    display: flex;
    align-items: center;
    margin-bottom: 10px !important;

    .ant-row.ant-form-item {
      margin-bottom: 0;
    }
  }

  .pictures {
    display: flex;
    overflow: auto;
    .ant-image:nth-of-type(n + 2) {
      margin-left: 5px;
      flex-shrink: 0;
    }
    .ant-card > .ant-card-body {
      padding: 5px;
    }
  }

  .clocking-log {
    h4 {
      color: ${colors.grey};
      font-size: 12px;
    }
    ul li {
      font-size: 10px;
      line-height: 12px;
    }
    .clocking-log-comment {
      font-size: 10px;
    }
  }
`;

let BOUNDS: LatLngBoundsLiteral = [[0, 0]];
