import { Moment } from 'moment/moment';
import { useQuery } from '@tanstack/react-query';
import { IV4Dimona } from '@/types/dimona.model';
import { getDimonasError } from '../api/index';

export const useErrorDimonas = (
    startDate: Moment,
    endDate: Moment,
): {
    dimonas: IV4Dimona[];
    loading: boolean;
} => {
    const { data: dimonas = [], isLoading: loading } = useQuery({
        queryKey: ['get-dimonas-error', startDate, endDate],
        queryFn: () => getDimonasError(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')),
    });

    return {
        dimonas,
        loading,
    };
};
