import DrawerBatchUserInfos from '@/pages/app/components/drawers/BatchUserInfos';
import DrawerUser from '@/pages/app/components/drawers/User';
import Options from '@/pages/app/components/Options';
import AppContext from '@/pages/app/context';
import { IUserRole } from '@/types/user-role.model';
import { IUser } from '@/types/user.model';
import { handleError, isFree, METRILIO_ALLOWED_DEPTS } from '@/utils';
import { Button, Input, message, notification, Popover, Select, Tooltip } from 'antd';
import { default as Axios, default as axios } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ExportMode } from '..';
import HeaderOptionDetails from './HeaderOptionDetails';

const Option = Select.Option;

interface Props {
  className?: string;
  activeDepartmentId?: string;
  disabled: boolean;
  columnFilters: Map<string, { name: string; value: boolean; resourceType?: string }>;
  filterSearch?: string;
  filterStatus?: string;
  filterRole?: string;
  userIds: string[];
  dataSource: IUser[];
  setColumnFilters: React.Dispatch<React.SetStateAction<Map<string, { name: string; value: boolean }>>>;
  setFilterSearch: React.Dispatch<React.SetStateAction<string>>;
  setFilterStatus: React.Dispatch<React.SetStateAction<string | null>>;
  setFilterRole: React.Dispatch<React.SetStateAction<string | null>>;
  setExportMode: React.Dispatch<React.SetStateAction<ExportMode>>;
}

const Header: React.FC<Props> = ({
  className,
  columnFilters,
  disabled,
  activeDepartmentId,
  filterSearch,
  filterStatus,
  filterRole,
  userIds,
  dataSource,
  setColumnFilters,
  setFilterSearch,
  setFilterStatus,
  setFilterRole,
  setExportMode,
}) => {
  const {
    state: { activeDepartment, users, userCategories },
    dispatch: appContextDispatch,
  } = useContext(AppContext);
  const [drawerUserIsVisible, setDrawerUserIsVisible] = useState<boolean>(false);
  const [drawerBatchUserInfosVisible, setDrawerBatchUserInfosVisible] = useState<boolean>(false);
  const [userRoles, setUserRoles] = useState<IUserRole[]>([]);
  const [loadingMetrilioSync, setLoadingMetrilioSync] = useState<boolean>(false);
  const [loadingSync, setLoadingSync] = useState<boolean>(false);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const history = useHistory();

  useEffect(() => {
    if (activeDepartmentId) {
      const cancelTokenSource = Axios.CancelToken.source();
      Axios.get(`${process.env.REACT_APP_API_URL}/v3/user-roles/`, {
        params: {
          departmentId: activeDepartmentId,
        },
        cancelToken: cancelTokenSource.token,
      })
        .then((response) => {
          setUserRoles(response.data.userRoles);
        })
        .catch((error) => {
          console.error(error);
        });

      return () => {
        cancelTokenSource.cancel();
      };
    }
  }, [activeDepartmentId]);

  const onUserSave = (user: IUser) => {
    const usersCopy = [...users];
    usersCopy.push(user);

    const usersCopySorted = usersCopy.sort((a, b) => {
      if (a.lastname) {
        if (b.lastname) {
          if (a.lastname.toLowerCase() < b.lastname.toLowerCase()) {
            return -1;
          }
          if (a.lastname.toLowerCase() > b.lastname.toLowerCase()) {
            return 1;
          }
          return 0;
        }
        return 1;
      }
      return 0;
    });

    appContextDispatch({
      type: 'SET_USERS',
      payload: usersCopySorted,
    });
    const free = isFree(activeDepartment!);
    history.push(`/app/team/collaborators/${user.recordId}?newUser=true`);
  };

  const options = (small?: boolean) => (
    <Options className={small ? 'options-small' : 'options'}>
      <Popover
        placement="bottomRight"
        content={() => (
          <HeaderOptionDetails
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            activeDepartmentId={activeDepartmentId}
          />
        )}
      >
        <div className="option">
          <i className="icon-toggle-on" />
        </div>
      </Popover>
    </Options>
  );

  const downloadUsers = () => {
    const data = {
      departmentId: activeDepartmentId,
      userIds,
    };
    Axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/v3/operations/export-users`,
      data,
      params: {
        departmentId: activeDepartmentId,
      },
    })
      .then((response) => {
        notification.open({
          message: response?.data?.message,
          type: 'info',
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onSendBatchUserInfos = () => {
    setDrawerBatchUserInfosVisible(true);
  };

  const onSyncMetrilio = () => {
    setLoadingMetrilioSync(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/exports/metrilio`,
        {},
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then(() => {
        message.success(t('USERS.METRILIO_USERS_SYNCED'));
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        setLoadingMetrilioSync(false);
      });
  };

  const onSync = () => {
    setLoadingSync(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v3/ay/sync-full`,
        {},
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
      .then(() => {
        message.success('Shyfter and AbsoluteYOU synced');
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoadingSync(false);
      });
  };

  return (
    <header className={className}>
      <div className="left">
        <div className="left-content">
          {dataSource?.length ? (
            <div className="title">
              <h2>
                {dataSource?.length} {t('GLOBAL.STAFF_MEMBERS')}
              </h2>
              {options(true)}
            </div>
          ) : null}
          <Input
            id="team_search_input"
            disabled={disabled}
            allowClear
            placeholder={t('GLOBAL.SEARCH')}
            suffix={<i className="icon-search"></i>}
            value={filterSearch}
            onChange={(e) => {
              setFilterSearch(e.target.value);
            }}
            className="search"
          />
          <Select
            placeholder={t('GLOBAL.USER_CATEGORY_SHORT')}
            allowClear
            value={filterStatus}
            className="section"
            getPopupContainer={(trigger) => trigger.parentNode}
            disabled={disabled}
            onChange={(value: string) => {
              setFilterStatus(value);
            }}
          >
            {userCategories?.map((userCategory) => {
              const { id, name } = userCategory;
              return (
                <Option key={`status_${id}`} value={id!}>
                  {name}
                </Option>
              );
            })}
          </Select>
          <Select
            placeholder={t('GLOBAL.ACCESS_LEVEL')}
            className="access"
            allowClear
            value={filterRole}
            getPopupContainer={(trigger) => trigger.parentNode}
            disabled={disabled}
            onChange={(value: string) => {
              setFilterRole(value);
            }}
            style={{ width: 200 }}
          >
            {userRoles?.map((userRole) => {
              const { id, name } = userRole;
              return (
                <Option key={`role_${id}`} value={id!}>
                  {t(`USERS.ROLES.${name}`)}
                </Option>
              );
            })}
          </Select>
          {options(false)}
        </div>
      </div>
      <div className="right">
        <div className="actions">
          <Tooltip title={t('USERS.SEND_BATCH_USER_INFO')}>
            <Button htmlType="button" shape="circle" className="action" onClick={onSendBatchUserInfos}>
              <i id="team_send_info_btn" className="icon-paper-plane-empty" />
            </Button>
          </Tooltip>
          <Tooltip title={t('GLOBAL.DOWNLOAD')}>
            <Button shape="circle" className="action" onClick={downloadUsers}>
              <i className="icon-download" />
            </Button>
          </Tooltip>
          {activeDepartment?.ay_core && (
            <Button
              type="primary"
              onClick={onSync}
              loading={loadingSync}
              style={{ backgroundColor: '#804ca7', borderColor: '#804ca7' }}
            >
              SYNC ABSOLUTEYOU{' '}
              <span style={{ transform: 'rotate(90deg)' }}>
                <i className="icon-up-big" />
              </span>{' '}
              SHYFTER
            </Button>
          )}
          {activeDepartmentId && METRILIO_ALLOWED_DEPTS.includes(activeDepartmentId) && (
            <Button
              type="primary"
              style={{ backgroundColor: '#0069CC', borderColor: '#0069CC' }}
              onClick={onSyncMetrilio}
              loading={loadingMetrilioSync}
            >
              HUGGYS App SYNC →
            </Button>
          )}
          <Button
            id="create-user-btn"
            type="primary"
            style={{ width: '160px' }}
            onClick={() => setDrawerUserIsVisible(true)}
          >
            {t('USERS.CREATE_USER')}
          </Button>
        </div>
      </div>
      <DrawerUser
        user={null}
        visible={drawerUserIsVisible}
        onClose={() => setDrawerUserIsVisible(false)}
        onSave={onUserSave}
        openDrawer={() => setDrawerUserIsVisible(true)}
      />
      <DrawerBatchUserInfos visible={drawerBatchUserInfosVisible} setVisible={setDrawerBatchUserInfosVisible} />
    </header>
  );
};

export default styled(Header)`
  h2 {
    font-weight: 600;
    margin-bottom: 0;
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .left {
    flex: 1;
  }

  .left-content {
    display: flex;
    align-items: center;
    gap: 15px;
    flex: 1;

    .search {
      width: 200px;
    }

    .options-small {
      display: none;
    }
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;

    .left-content {
      flex-direction: column;
      gap: 10px;

      .title  {
        display: flex;
      }

      .search,
      .section,
      .access {
        width: 100% !important;
      }

      .options {
        display: none;
      }

      .options-small {
        display: block;
      }
    }

    .right {
      align-self: center;
      margin-top: 15px;
    }
  }
`;
