import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import GridItem from './components/GridItem';
import NewHeader from './components/NewHeader';
import ResizeObserver from 'rc-resize-observer';
import UnderLayer from './components/UnderLayer';
import DailyTurnover from './components/DailyTurnover';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { Layout, V4WidgetResponse } from './types';
import { useWidgets, useWidgetsPositionUpdate } from './hooks';
import { clockingDashboardWidgets, defaultClockingDashboardSettings, defaultDashboardSettings } from './utils';
// @ts-ignore
import { Responsive, WidthProvider } from 'react-grid-layout';

type DefaultProps = {
  departmentId: string;
  clockingOnly?: boolean;
  className?: string;
}

const Default: FC<DefaultProps> = ({ departmentId, className, clockingOnly = false }) => {
  const ResponsiveReactGridLayout = useMemo(() => WidthProvider(Responsive), []);

  const [editing, setEditing] = useState(false);
  const [height, setHeight] = useState<number>(0);
  const [size, setSize] = useState<'lg' | 'md' | 's'>('lg');
  const [timeFrame, setTimeFrame] = useState({
    startDate: moment().startOf('isoWeek'),
    endDate: moment().endOf('isoWeek'),
  });

  const { t } = useTranslation();
  const { widgets, loading } = useWidgets(departmentId);
  const { updateWidgetsPosition } = useWidgetsPositionUpdate();

  const handleDatePickerChange = useCallback((date: Moment) => {
    setTimeFrame({
      startDate: moment(date).startOf('isoWeek'),
      endDate: moment(date).endOf('isoWeek'),
    });
  }, []);

  const handleEditingChange = useCallback((checked: boolean) => {
    setEditing(checked);
  }, []);

  const handleLayoutChange = useCallback(
    (newLayout: ({ i: string } & Layout)[]) => {
      const payload = newLayout.map((l) => {
        const { configuration } = widgets.find((w) => w.id === l.i) as V4WidgetResponse;

        return {
          id: l.i,
          settings: {
            ...configuration.settings,
            [size]: {
              x: l.x,
              y: l.y,
              w: l.w,
              h: l.h,
            },
          },
        };
      });

      updateWidgetsPosition({ widgets: payload });
    },
    [widgets, size],
  );

  useEffect(() => {
    const defaultLayout = clockingOnly ? defaultClockingDashboardSettings : defaultDashboardSettings;
    const isDashboardEmpty = widgets
        .filter((w) => clockingOnly ? Object.keys(defaultLayout).includes(w.slug) : true)
        .every((w) => !w.configuration.isVisible);

    if (!loading && isDashboardEmpty) {
      updateWidgetsPosition({
        widgets: widgets.map((w) => ({
          id: w.id,
          settings: defaultLayout[w.slug] ?? w.configuration.settings,
          isVisible: defaultLayout[w.slug] !== undefined,
        })),
      });
    }
  }, [loading]);


  if (loading) {
    return <div>{t('GLOBAL.LOADING')}</div>;
  }

  return (
    <div className={className}>
      <DailyTurnover widgetsLoading={loading} />
      <NewHeader
        widgets={widgets}
        editing={editing}
        timeFrame={timeFrame}
        clockingOnly={clockingOnly}
        handleEditingChange={handleEditingChange}
        handleDatePickerChange={handleDatePickerChange}
      />
      <UnderLayer height={height} size={size} editing={editing} />
      <ResizeObserver onResize={({ height }) => setHeight(height)}>
        <ResponsiveReactGridLayout
          isDraggable={editing}
          isResizable={editing}
          onBreakpointChange={setSize}
          onDragStop={handleLayoutChange}
          onResizeStop={handleLayoutChange}
          cols={{ lg: 6, md: 3, s: 1 }}
          breakpoints={{ lg: 1200, md: 1000, s: 700 }}
        >
          {widgets
            .filter(
              (w) =>
                w.configuration.isVisible &&
                (clockingOnly ? clockingDashboardWidgets.includes(w.slug) : true),
            )
            .map(({ id, slug, configuration }) => (
              <div key={id} data-grid={{ i: id, ...configuration.settings[size] }}>
                <GridItem slug={slug} editing={editing} {...timeFrame} />
              </div>
            ))}
        </ResponsiveReactGridLayout>
      </ResizeObserver>
    </div>
  );
};

const DefaultStyled = styled(Default)`
  padding-bottom: 190px;
`;

export default DefaultStyled;
