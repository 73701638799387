import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { IDepartment } from '@/types/department.model';
import { FEATURES } from '@/types/features.model';
import { IField } from '@/types/field.model';
import { IUser } from '@/types/user.model';
import { getWindowSize, isClockingAccount, isFeatureEnabled, isFree } from '@/utils';
import { Button, Layout, Tabs, notification } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import TabSelector from '../components/TabSelector';
import UserSelector from '../components/UserSelector';
import UserList from './UserList';
import Activity from './activity';
import Counters from './counters';
import Dashboard from './dashboard';
import Documents from './documents';
import Notes from './notes';
import Profile from './profile';
import Reports from './reports';

const { Content } = Layout;
const { TabPane } = Tabs;

interface Props {
  className?: string;
  department?: IDepartment;
  user?: IUser;
  fields?: IField[];
}

const CollaboratorOverview: React.FC<Props> = ({ className, user, department }) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [activeTab, setActiveTab] = useState<string>('');
  const [activeSubTab, setActiveSubTab] = useState<string>('');
  const locationRef = useLocation<any>();
  const departmentId = department?.id;
  const history = useHistory();
  const newUser = history.location.search === '?newUser=true';
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const {
    state: { features },
  } = useContext(AppContext);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const path_elements = history.location.pathname.split('/');
    let tab = path_elements[path_elements.length - 2];
    let sub_tab = path_elements[path_elements.length - 1];

    if (!Number.isNaN(Number(tab))) {
      tab = sub_tab;
      sub_tab = '';
    }

    setActiveTab((prev) => {
      return !prev ? tab.toUpperCase() : prev.toUpperCase();
    });

    setActiveSubTab((prev) => {
      return !prev ? sub_tab.toUpperCase() : prev.toUpperCase();
    });
  }, [history, user]);

  const onTabChange = (tab: string) => {
    setActiveTab(tab);
    setActiveSubTab('');
    history.replace(`/app/team/collaborators/${user?.recordId}/${tab.toLowerCase()}`);
  };

  const onBackClick = () => {
    history.goBack();
  };

  const onUpdateCounters = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/users/${user?.recordId}/absences/update`, {
        params: {
          departmentId: department?.id,
        },
      })
      .then((res) => {
        notification.open({
          message: t('USERS.USER.COUNTERS_UPDATED_NOTIFICATION'),
          type: 'success',
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Layout className={className}>
      <UserList user={user || undefined} currentTab={activeTab !== '' ? activeTab.toLowerCase() : 'DASHBOARD'} />
      {windowSize.innerWidth <= 900 && <UserSelector user={user} />}
      <TabSelector activeTab={activeTab} setActiveTab={setActiveTab} />
      {windowSize.innerWidth <= 900 && (
        <div className="small-content">
          {activeTab === 'DASHBOARD' && (
            <div className="tab-container">
              <Dashboard user={user} departmentId={departmentId} />
            </div>
          )}
          {activeTab === 'PROFILE' && (
            <div className="tab-container">
              <Profile
                user={user}
                department={department}
                activeSubTab={activeSubTab}
                setActiveSubTab={setActiveSubTab}
              />
            </div>
          )}
          {activeTab === 'ACTIVITY' && (
            <div className="tab-container">
              <Activity
                user={user}
                department={department}
                activeSubTab={activeSubTab}
                setActiveSubTab={setActiveSubTab}
              />
            </div>
          )}
          {((department?.access?.reports && department?.params?.payReport) ||
            !!department?.params?.extrahoursEnabled) &&
            activeTab === 'COUNTERS' && (
              <div className="tab-container">
                <Counters
                  user={user}
                  department={department}
                  activeSubTab={activeSubTab}
                  setActiveSubTab={setActiveSubTab}
                />
              </div>
            )}
          {(department?.access?.reports || department?.access?.timesheets) &&
            activeTab === 'REPORTS' &&
            !isClockingAccount(features) && (
              <div className="tab-container">
                <Reports
                  user={user}
                  department={department}
                  activeSubTab={activeSubTab}
                  setActiveSubTab={setActiveSubTab}
                />
              </div>
            )}
          {isFeatureEnabled(features, FEATURES.CONTRACTS) && activeTab === 'DOCUMENTS' && (
            <div className="tab-container">
              <Documents
                user={user}
                department={department}
                activeSubTab={activeSubTab}
                setActiveSubTab={setActiveSubTab}
              />
            </div>
          )}
          {activeTab === 'NOTES' && (
            <div className="tab-container">
              <Notes user={user} departmentId={departmentId} />
            </div>
          )}
        </div>
      )}
      <Content>
        {user && (
          <Tabs
            activeKey={activeTab}
            size="small"
            style={{ fontSize: 16 }}
            tabBarStyle={{ margin: 10 }}
            onChange={onTabChange}
            tabBarExtraContent={
              <>
                {activeTab === 'DASHBOARD' && (
                  <Button
                    type="primary"
                    style={{
                      flex: 1,
                      backgroundColor: colors.blueLightPastel,
                      borderColor: colors.blueLightPastel,
                      marginRight: 10,
                    }}
                    onClick={onUpdateCounters}
                  >
                    <i className="icon-arrows-cw"></i>
                    {t('USERS.USER.UPDATE_COUNTERS')}
                  </Button>
                )}
                <Button onClick={onBackClick}>
                  <i className="icon-angle-left"></i>
                  {t('GLOBAL.PREVIOUS_PAGE')}
                </Button>
              </>
            }
            className="tabs"
          >
            {isFeatureEnabled(features, FEATURES.SCHEDULE) && (
              <TabPane disabled={user?.pending} tab={t('DASHBOARD.TITLE')} key="DASHBOARD">
                {activeTab == 'DASHBOARD' && <Dashboard user={user} departmentId={departmentId} />}
              </TabPane>
            )}
            <TabPane tab={t('USERS.PROFILE')} key="PROFILE">
              {activeTab == 'PROFILE' && (
                <Profile
                  user={user}
                  department={department}
                  activeSubTab={activeSubTab}
                  setActiveSubTab={setActiveSubTab}
                />
              )}
            </TabPane>
            <TabPane disabled={user.pending} tab={t('GLOBAL.ACTIVITY')} key="ACTIVITY">
              {activeTab == 'ACTIVITY' && (
                <Activity
                  user={user}
                  department={department}
                  activeSubTab={activeSubTab}
                  setActiveSubTab={setActiveSubTab}
                />
              )}
            </TabPane>
            {((department?.access?.reports && department?.params?.payReport) ||
              !!department?.params?.extrahoursEnabled) &&
              !isFree(department) && (
                <TabPane tab={t('GLOBAL.COUNTERS')} key="COUNTERS">
                  {activeTab == 'COUNTERS' && (
                    <Counters
                      user={user}
                      department={department}
                      activeSubTab={activeSubTab}
                      setActiveSubTab={setActiveSubTab}
                    />
                  )}
                </TabPane>
              )}
            {(department?.access?.reports || department?.access?.timesheets) &&
              !isFree(department) &&
              !isClockingAccount(features) && (
                <TabPane disabled={user.pending} tab={t('MENU.REPORTS.TITLE')} key="REPORTS">
                  {activeTab == 'REPORTS' && (
                    <Reports
                      user={user}
                      department={department}
                      activeSubTab={activeSubTab}
                      setActiveSubTab={setActiveSubTab}
                    />
                  )}
                </TabPane>
              )}
            {(isFeatureEnabled(features, FEATURES.CONTRACTS) || isFeatureEnabled(features, FEATURES.DIMONA)) && (
              <TabPane disabled={user.pending} tab={t('GLOBAL.DOCUMENTS')} key="DOCUMENTS">
                {activeTab == 'DOCUMENTS' && (
                  <Documents
                    user={user}
                    department={department}
                    activeSubTab={activeSubTab}
                    setActiveSubTab={setActiveSubTab}
                  />
                )}
              </TabPane>
            )}
            <TabPane disabled={user?.pending} tab={t('GLOBAL.NOTES')} key="NOTES">
              {activeTab == 'NOTES' && <Notes user={user} departmentId={departmentId} />}
            </TabPane>
          </Tabs>
        )}
      </Content>
    </Layout>
  );
};

const CollaboratorOverviewStyled = styled(CollaboratorOverview)`
  background: #f7f8fb;
  height: calc(100vh - 50px);
  position: relative;

  .small-content {
    display: none;
  }

  > * {
    max-height: 100%;
    overflow: auto;
    &.ant-layout-content {
      padding: 10px 20px 20px;
    }
    &.ant-layout-sider {
      background: #fff;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
      &.right {
        padding: 0;
      }

      @media screen and (max-width: 900px) {
        display: none;
      }
    }
  }

  .ant-layout-content {
    .ant-tabs-tab-btn {
      font-size: 16px;
    }
    .ant-tabs-content {
      .ant-tabs-tab-btn {
        font-size: 12px;
      }
      h4 {
        font-weight: bold;
        margin-bottom: 20px;
        font-size: 16px;
        margin-top: 10px;
        color: #00a651;
      }

      .ant-checkbox-wrapper {
        min-height: 25px;
      }

      .ant-tabs-nav-list > .ant-tabs-tab {
        border-radius: 3px 3px 0 0;
        border: none;
        background: transparent;
        padding: 10px 40px;
        &.ant-tabs-tab-active {
          background: white;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
        }
      }

      .ant-tabs-card .ant-tabs-content {
        margin-top: -16px;
        border-radius: 0px 0px 3px 3px;
      }

      .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
        padding: 20px;
        background: #fff;
      }

      .ant-tabs-card > .ant-tabs-nav::before {
        display: none;
      }
    }
  }

  @media screen and (max-width: 900px) {
    flex-direction: column !important;
    height: 100%;

    > * {
      overflow: initial !important;
    }

    .ant-layout-content {
      width: auto !important;
    }

    .tabs {
      display: none;
    }

    .small-content {
      display: block;
      margin: 25px;
      padding: 25px;
      background-color: #fff;
    }

    h4 {
      font-weight: bold;
      margin-bottom: 20px;
      font-size: 16px;
      margin-top: 10px;
      color: #00a651;
    }
  }
`;

export default CollaboratorOverviewStyled;
