import React, { useContext, useEffect, useState } from 'react';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import moment, { Moment } from 'moment';
import { Avatar, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/es/table/interface';
import { convertUtcToTimezoneDateAndTime, getContrast } from '@/utils';
import { DIMONA_WORK_TYPES, IDimonaStatementFilters, IV4Dimona } from '@/types/dimona.model';

export const useDimonaStatement = () => {
  const { t } = useTranslation();
  const {
    state: { activeDepartment, users, userCategories },
  } = useContext(AppContext);

  const [columns, setColumns] = useState<ColumnsType<IV4Dimona>>([]);
  const [dates, setDates] = useState<[Moment, Moment]>([moment().startOf('month'), moment().endOf('month')]);
  const [filters, setFilters] = useState<IDimonaStatementFilters>({
    search: '',
    state: 'active',
    types: [],
    users: [],
    errors: false,
    pending: false,
  });

  useEffect(() => {
    const columns = [
      {
        title: `${t('GLOBAL.FIRSTNAME')}, ${t('GLOBAL.LASTNAME')}`,
        key: 'user',
        render: (text: any, record: IV4Dimona) => {
          if (!record.user) return;
          const { recordId, firstName, lastName } = record.user;
          const userDetails = users.find((user) => user.recordId == record.user.recordId);
          if (!userDetails) {
            return (
              <span>
                {firstName} {lastName}
              </span>
            );
          }
          return (
            <Tooltip overlay={`${firstName} ${lastName}`} placement="topLeft">
              <Link to={`/app/team/collaborators/${recordId}`}>
                <Avatar size={24} src={userDetails?.picture}>
                  {userDetails?.initials}
                </Avatar>
                <span style={{ color: colors.green }}>{userDetails.displayName}</span>
              </Link>
            </Tooltip>
          );
        },
      },
      {
        title: t('GLOBAL.REFERENCE'),
        key: 'dimona_period_id',
        dataIndex: 'dimona_period_id',
        render: (text: any, record: IV4Dimona) => {
          return (
            <span>
              {record.result.scheduled && (
                <Tooltip overlay={t('DIMONA.LINKED_TO_SHIFT_OR_CLOCKING')}>
                  <i
                    className="icon-ok"
                    style={{
                      backgroundColor: colors.green,
                      color: getContrast(colors.green),
                      borderRadius: 100,
                      marginRight: 5,
                      fontSize: 10,
                    }}
                  />
                </Tooltip>
              )}
              {record.dimonaPeriodId}
            </span>
          );
        },
      },
      {
        title: `NISS`,
        key: 'niss',
        dataIndex: 'niss',
      },
      {
        title: t('GLOBAL.TYPE'),
        key: 'type',
        dataIndex: 'worker_type',
        onFilter: (value: any, record: IV4Dimona) => record.workerType === value,
        filters: DIMONA_WORK_TYPES.map((worker_type) => ({
          value: worker_type.id,
          text: t(`DIMONA.WORKTYPES.${worker_type.id}`),
        })),
        render: (text: any, record: IV4Dimona) => {
          const background =
            record.workerType == 'OTH'
              ? '#273140'
              : record.workerType == 'STU'
              ? '#4074C3'
              : record.workerType == 'FLX'
              ? '#B0ECF4'
              : record.workerType == 'EXT'
              ? '#0545EB'
              : '#CCCDFE';
          return (
            <span
              style={{
                backgroundColor: background,
                color: getContrast(background),
                borderRadius: 8,
                padding: '3px 12px',
                fontSize: 11,
                textTransform: 'uppercase',
              }}
            >
              {record.workerType}
            </span>
          );
        },
      },
      {
        title: t('GLOBAL.STATUS'),
        key: 'status',
        onFilter: (value: any, record: IV4Dimona) => {
          if (value !== record.user.userStatusId) return false;
          const status = userCategories.find((status) => status.id == value);
          if (!status) return false;
          return true;
        },
        filters: userCategories.map((status) => ({
          value: status.id,
          text: status.name,
        })),
        render: (text: any, record: IV4Dimona) => {
          if (!record.user.userStatusId || record.user.userStatusId == '0') return;
          const status = userCategories.find((status) => status.id == String(record.user.userStatusId));
          if (!status) return;
          return <span>{status.name}</span>;
        },
      },
      {
        title: t('GLOBAL.START'),
        key: 'start',
        sorter: (a: IV4Dimona, b: IV4Dimona) => moment(a.startDate).isBefore(b.startDate),
        render: (text: any, record: IV4Dimona) => {
          if (!record.startDate) return <span style={{ color: '#d6d5d5' }}>--/--/----</span>;
          if (record.startHour && record.startDate) {
            return (
              <span>
                {moment(record.startDate!).format('L')} {(record.startHour as string)?.substr(0, 5)}
              </span>
            );
          } else {
            return (
              <span>
                {moment(record.startDate).format('L')}{' '}
                {record.plannedHours && record.plannedHours !== 0
                  ? `(${record.plannedHours}${t('GLOBAL.HOURS_SHORT')})`
                  : ''}
              </span>
            );
          }
        },
      },
      {
        title: t('GLOBAL.END'),
        key: 'end',
        sorter: (a: IV4Dimona, b: IV4Dimona) => moment(a.endDate).isBefore(b.endDate),
        render: (text: any, record: IV4Dimona) => {
          if (record.endDate) {
            if (record.endHour) {
              return (
                <span>
                  {moment(record.endDate).format('L')} {(record.endHour as string)?.substr(0, 5)}
                </span>
              );
            } else {
              return <span>{moment(record.endDate).format('L')}</span>;
            }
          } else {
            return <span style={{ color: '#d6d5d5' }}>--/--/----</span>;
          }
        },
      },
      {
        title: t('GLOBAL.STATE'),
        key: 'status',
        render: (text: any, record: IV4Dimona) => {
          const background = filters.errors
            ? colors.red
            : record.status?.locked
            ? '#273140'
            : record.status?.cancelled && record.status.late
            ? '#EDB15D'
            : record.status?.late
            ? '#00A651'
            : record.status?.indefinite
            ? '#00A651'
            : record.status?.cancelled
            ? '#EDB15D'
            : !record.status?.active
            ? '#9799FF'
            : record.status?.active
            ? '#00A651'
            : '';
          const status = record.pending
            ? 'pending'
            : record.status?.locked
            ? 'locked'
            : record.status?.cancelled && record.status.late
            ? 'cancelled'
            : record.status?.late
            ? 'active'
            : record.status?.indefinite
            ? 'active'
            : record.status?.cancelled
            ? 'cancelled'
            : !record.status?.active
            ? 'inactive'
            : 'active';
          return (
            <span
              style={{
                backgroundColor: background,
                color: getContrast(background),
                borderRadius: 8,
                padding: '3px 12px',
                fontSize: 11,
                textTransform: 'uppercase',
              }}
            >
              {record.status?.late && (
                <Tooltip overlay={t('DIMONA.LATE_DIMONA')}>
                  <i className="icon-attention" style={{ color: colors.red, marginRight: 5 }} />
                </Tooltip>
              )}
              {filters.errors ? t('GLOBAL.ERROR') : t(`DIMONA.STATUSES.${status.toUpperCase()}`)}
            </span>
          );
        },
      },
      {
        title: t('GLOBAL.CREATED_AT'),
        key: 'created_at',
        className: 'light-gray',
        sorter: (a: IV4Dimona, b: IV4Dimona) => moment(a.createdAt).isBefore(b.createdAt),
        render: (text: any, record: IV4Dimona) => {
          return convertUtcToTimezoneDateAndTime(record.createdAt, activeDepartment);
        },
      },
      {
        title: t('GLOBAL.UPDATED_AT'),
        key: 'last_updated_at',
        className: 'light-gray',
        sorter: (a: IV4Dimona, b: IV4Dimona) => moment(a.lastUpdateAt).isBefore(b.lastUpdateAt),
        render: (text: any, record: IV4Dimona) => {
          return convertUtcToTimezoneDateAndTime(record.lastUpdateAt, activeDepartment);
        },
      },
      {
        title: t('GLOBAL.NOTIFICATIONS'),
        key: 'notification',
        render: (text: any, record: IV4Dimona) => {
          const lastHistory = record.history && record.history[record.history.length - 1];
          const lastHistoryErrors = lastHistory && lastHistory.errors;
          if (lastHistoryErrors && lastHistoryErrors.length > 0) {
            return (
              <span>
                {lastHistory.type} - {lastHistoryErrors.map((error: any) => error.message).join(', ')}
              </span>
            );
          }
          if (record.result && record.result.errors && record.result.errors.length > 0) {
            return <span>{record.result.errors.map((error) => error.message).join(', ')}</span>;
          }
          if (record.pending && moment(record.startDate) < moment().startOf('day')) {
            return <span>{t('GLOBAL.NOT_PROCESSED')}</span>
          }
          return record.comment;
        },
      },
    ];
    setColumns(columns as any);
  }, [filters, users, userCategories, activeDepartment]);

  return {
    columns,
    dates,
    setDates,
    filters,
    setFilters,
  };
};
