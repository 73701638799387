import ContainerView from '@/layouts/ContainerView';
import TableView from '@/layouts/TableView';
import AppContext from '@/pages/app/context';
import { IUser } from '@/types/user.model';
import { BackTop, Button, List, Modal, notification, Spin, Table } from 'antd';
import Axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import TableRowActions from './TableRowActions';
moment.tz.setDefault('Atlantic/Reykjavik');

export interface IWeeklyReport {
  id: string;
  week: number;
  start: number;
  end: number;
  pdf: string;
  createdAt: number;
  createdBy: IUser;
  sendingDate: number;
  sentBy: IUser;
  children: IWeeklyReport[];
}

interface Props {
  className?: string;
}

const AppHoursMessagesPage: React.FC<Props> = ({ className }) => {
  const {
    state: { activeDepartment, activeSection: sectionId },
  } = useContext(AppContext);
  const { reportId } = useParams();
  const history = useHistory();
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [weeklyReports, setWeeklyReports] = useState<IWeeklyReport[]>([]);
  const [loadingWeeklyReports, setLoadingWeeklyReports] = useState<boolean>(false);
  const [weeklyReportName, setWeeklyReportName] = useState<string>('');
  const [columns, setColumns] = useState<any[]>([]);
  const [weeklyReportOperationCounter, setWeeklyReportOperationCounter] = useState<number>(0);
  const [errors, setErrors] = useState<string[]>([]);
  const [showErrorsModal, setShowErrorsModal] = useState<boolean>(false);

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    setColumns([
      {
        title: t('GLOBAL.WEEK'),
        key: 'week',
        dataIndex: 'week',
      },
      {
        title: t('GLOBAL.PERIOD'),
        key: 'period',
        render: (text: string, report: IWeeklyReport) => {
          return `${moment.unix(report.start!).format('L')} - ${moment.unix(report.end!).format('L')}`;
        },
      },
      {
        title: `PDF`,
        key: 'pdf',
        // eslint-disable-next-line react/display-name
        render: (text: string, report: IWeeklyReport) => {
          if (report.pdf) {
            return (
              <a href={report.pdf} target="_blank" rel="noreferrer">
                <i className="icon-doc-text-inv" />
              </a>
            );
          } else {
            return <Spin size="small" spinning={true} />;
          }
        },
      },
      {
        title: t('GLOBAL.CREATION'),
        key: 'createdAt',
        render: (text: string, report: IWeeklyReport) => {
          return `${moment.unix(report.createdAt!).format('L HH:mm')}`;
        },
      },
      {
        title: t('GLOBAL.CREATED_BY'),
        key: 'createdBy',
        render: (text: string, report: IWeeklyReport) => {
          return report?.createdBy?.displayName;
        },
      },
      {
        title: t('GLOBAL.SENDING_DATE'),
        key: 'sendingDate',
        render: (text: string, report: IWeeklyReport) => {
          if (report.sendingDate) {
            return `${moment.unix(report.sendingDate).format('L HH:mm')}`;
          }
        },
      },
      {
        title: t('GLOBAL.SENT_BY'),
        key: 'sentBy',
        render: (text: string, report: IWeeklyReport) => {
          return report?.sentBy?.displayName;
        },
      },
      {
        key: 'actions',
        // eslint-disable-next-line react/display-name
        render: (text: string, report: IWeeklyReport) => {
          if (report.pdf) {
            return <TableRowActions weeklyReport={report} handleSchedule={goToSchedule} handleSend={sendReport} />;
          }
        },
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    if (activeDepartment && reportId) {
      let mounted = true;
      const weeklyReportName = activeDepartment?.weeklyReports?.find((weeklyReport) => weeklyReport?.id === reportId)
        ?.name;
      setWeeklyReportName(weeklyReportName || '');
      setLoadingWeeklyReports(true);
      setWeeklyReports([]);
      const cancelTokenSource = Axios.CancelToken.source();
      Axios.get(`${process.env.REACT_APP_API_URL}/v3/documents/weekly-reports`, {
        cancelToken: cancelTokenSource.token,
        params: {
          departmentId: activeDepartment?.id,
          sectionId,
          reportId,
        },
      })
        .then(({ data }) => {
          if (mounted) {
            setWeeklyReports(data);
            setLoadingWeeklyReports(false);
          }
        })
        .catch((error) => {
          if (!Axios.isCancel(error)) {
            console.error(error);
          }
          if (mounted) {
            setLoadingWeeklyReports(false);
          }
        });
      return () => {
        mounted = false;
        cancelTokenSource.cancel();
      };
    }
  }, [activeDepartment, sectionId, reportId, weeklyReportOperationCounter]);

  const goToSchedule = (timestamp: number) => {
    history.push(
      `/app/hours/manage/${
        activeDepartment?.scheduleParams?.default_schedule_view == 'ops'
          ? 'operational'
          : activeDepartment?.scheduleParams?.default_schedule_view
      }/${moment.unix(timestamp).format('YYYY-MM-DD')}`,
    );
  };

  const sendReport = (id: string) => {
    return Axios.post(
      `${process.env.REACT_APP_API_URL}/v3/operations/weekly-reports/send`,
      {
        id,
      },
      {
        params: {
          departmentId: activeDepartment?.id,
          sectionId,
          reportId,
        },
      },
    )
      .then((response) => {
        notification.open({
          message: t('PRINTING_SCHEDULES.MESSAGE_SEND_SUCCESS'),
          type: 'info',
        });
        setWeeklyReportOperationCounter(weeklyReportOperationCounter + 1);
      })
      .catch((error) => {
        if (error.response?.status === 406) {
          notification.open({
            message: t('GLOBAL.AN_ERROR_OCCURRED'),
            description: t('PRINTING_SCHEDULES.MESSAGE_NO_RECIPIENT_ERROR'),
            type: 'error',
          });
        } else {
          if (error.response?.data?.errors) {
            setShowErrorsModal(true);
            setErrors(Object.values(error.response.data.errors).flat(1) as string[]);
          }
        }
      });
  };

  const onErrorsOk = () => {
    setShowErrorsModal(false);
  };

  const onErrorsCancel = () => {
    setShowErrorsModal(false);
  };

  return (
    <ContainerView className={className}>
      <Modal
        forceRender={true}
        maskClosable={false}
        destroyOnClose={true}
        visible={showErrorsModal}
        className={'modal-danger'}
        title={t('CONTRACTS.CREATE_CONTRACT_ERROR')}
        onOk={onErrorsOk}
        onCancel={onErrorsCancel}
        footer={[
          <Button key="back" onClick={onErrorsCancel}>
            {t('GLOBAL.OK')}
          </Button>,
        ]}
      >
        <List
          dataSource={errors}
          renderItem={(item) => (
            <List.Item>
              <p>{item}</p>
            </List.Item>
          )}
        />
      </Modal>
      <TableView>
        <BackTop visibilityHeight={20}>
          <i className="icon-up-big"></i>
        </BackTop>
        <header>
          <h2>{weeklyReportName}</h2>
        </header>
        <Table
          dataSource={weeklyReports}
          columns={columns}
          loading={loadingWeeklyReports}
          rowKey="id"
          onChange={(e) => {
            window.scrollTo(0, 0);
          }}
          pagination={{ position: ['bottomCenter'] }}
        />
      </TableView>
    </ContainerView>
  );
};

export default styled(AppHoursMessagesPage)`
  .column-actions {
    > button:nth-of-type(n + 2) {
      margin-left: 5px;
    }
  }
`;
