import ScrollToTop from '@/components/ScrollToTop';
import ContainerView from '@/layouts/ContainerView';
import TableViewStyled from '@/layouts/TableView';
import ModalHowToUseVariables from '@/pages/app/components/ModalHowToUseVariables';
import AppContext from '@/pages/app/context';
import colors from '@/styles/colors';
import { DocumentPreview } from '@/types/document-preview.model';
import { IDocumentPlaceholder, IDocumentTemplate } from '@/types/document.model';
import { FEATURES } from '@/types/features.model';
import { ISignature } from '@/types/signature.model';
import {
  REGIME_TYPES,
  TINYMCE_CONTENT_STYLE,
  TINYMCE_PLUGINS,
  TINYMCE_TOOLBAR,
  getHtmlWithCodes,
  getWindowSize,
  isFeatureEnabled,
} from '@/utils';
import { Editor } from '@tinymce/tinymce-react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  InputNumber,
  List,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Typography,
  Upload,
  message,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RcFile } from 'antd/lib/upload';
import Axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
moment.tz.setDefault('Atlantic/Reykjavik');

const { Option } = Select;

interface Props {
  className?: string;
}

interface PromiseWithCancel<T> extends Promise<T> {
  cancel: () => void;
}

const Contract: React.FC<Props> = ({ className }) => {
  const location = useLocation<any>();
  const {
    state: { users, loadingUsers, activeDepartment, activeDepartmentId, features },
  } = useContext(AppContext);
  const { t } = useTranslation(undefined, { useSuspense: true });
  const { contractId } = useParams<any>();
  const [form] = Form.useForm();
  const history: any = useHistory();
  const previewRef = React.createRef<any>();
  const { state } = history?.location as any;
  const [userData, setUserData] = useState<any>();
  const [templateData, setTemplateData] = useState<any>();
  const [userParams, setUserParams] = useState<any>();
  const [loadingContract, setLoadingContract] = useState<boolean>(false);
  const [templates, setTemplates] = useState<IDocumentTemplate[]>([]);
  const [loadingTemplates, setLoadingTemplates] = useState<boolean>(false);
  const [activeTemplateId, setActiveTemplateId] = useState<string | null | undefined>(null);
  const [signatures, setSignatures] = useState<ISignature[]>([]);
  const [loadingSignatures, setLoadingSignatures] = useState<boolean>(false);
  const [placeholders, setPlaceholders] = useState<IDocumentPlaceholder[]>([]);
  const placeholdersRef = useRef<IDocumentPlaceholder[]>(placeholders);
  placeholdersRef.current = placeholders;
  const [placeholdersReady, setPlaceholdersReady] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [createDimona, setCreateDimona] = useState<boolean>(false);
  const [htmlContent, setHtmlContent] = useState<string>('');
  const editorRef = useRef<any>(null);
  const [workerTypeIsLoading, setWorkerTypeIsLoading] = useState<boolean>(false);
  const [activeUser, setActiveUser] = useState<string | null>();
  const [userLoading, setUserLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [showErrorsModal, setShowErrorsModal] = useState<boolean>(false);
  const [file, setFile] = useState<RcFile | null>(null);
  const [fileNameOnUpdate, setFileNameOnUpdate] = useState<string | null>(null);
  const [preview, setPreview] = useState<DocumentPreview | null>(null);
  const [previewLoading, setPreviewLoading] = useState<boolean>(false);
  const [activeTemplatePageIndex, setActiveTemplatePageIndex] = useState<number>(0);
  const [howToUseVariablesVisible, setHowToUseVariablesVisible] = useState<boolean>(false);
  const [originalEditorContent, setOriginalEditorContent] = useState<string>('');
  const [showFileUpload, setShowFileUpload] = useState<boolean>(true);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [previewDocumentQuery, setPreviewDocumentQuery] = useState<PromiseWithCancel<DocumentPreview | null>>();
  const [placesOfWork, setPlacesOfWork] = useState<string[]>([]);

  const cancelUploadFileController = new AbortController();

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/department/places-of-work`, {
      params: {
        departmentId: activeDepartmentId,
      },
    })
      .then(({ data }) => {
        setPlacesOfWork(data);
      })
      .catch((err) => {
        console.log(err);
        message.error(t('DIMONA.GET_PLACES_OF_WORK_ERROR'));
      });
  }, [contractId]);

  useEffect(() => {
    if (!file) return;

    if (!preview || (preview && preview.images && preview.images.length > 0)) return;

    beforeUpload(file);
  }, [preview, file]);

  const updatingContract = contractId !== 'new';
  const {
    userRecordId: src_rrid,
    weekHours: src_week_hours,
    comingFrom: src_coming_from,
    start: src_start,
    end: src_end,
    salaryPeriod: src_salary_period,
    weekDays: src_week_days,
    maxDailyHours: src_max_daily_hours,
  } = history.location.state || {};
  const src = src_coming_from === 'SHIFT_RIGHT_CLICK';

  useEffect(() => {
    if (state && state.userRecordId) {
      setActiveUser(state.userRecordId);
    }
  }, [state]);

  useEffect(() => {
    if (src) {
      setActiveUser(src_rrid);
    }
    setFile(null);
    setFileNameOnUpdate(null);
    setPreview(null);
    setPreviewLoading(false);
  }, []);

  useEffect(() => {
    let mounted = true;
    if (src && src_rrid && mounted) {
      getUserParams(src_rrid, null, false);
    }

    return () => {
      mounted = false;
      setUserLoading(false);
    };
  }, [src, src_rrid]);

  useEffect(() => {
    let mounted = true;
    if (mounted && activeUser) {
      loadUser(null, false);
    }
    return () => {
      mounted = false;
    };
  }, [activeUser]);

  useEffect(() => {
    const defaultSignature = signatures.find((signature) => signature.default);
    if (defaultSignature) {
      form.setFieldsValue({
        signatory: defaultSignature.id,
      });
    }
  }, [activeTemplateId, signatures]);

  useEffect(() => {
    if (userData) {
      const {
        price: ud_price,
        price_bruto: ud_price_bruto,
        wage_type: ud_wage_type,
        hr_max_hours_week: ud_hr_max_hours_week,
        hr_days_week: ud_hr_days_week,
        legal_leaves: ud_legal_leaves,
        contract_end: ud_contract_end,
        dimona: ud_dimona,
        default_dimona_hours: ud_default_dimona_hours,
        regime: ud_regime,
      } = userData;

      const {
        maxDailyHours: _max_daily_hours,
        salary: _salary,
        salaryBruto: _salaryBruto,
        salaryPeriod: _salary_period,
        weekDays: _week_days,
        weekHours: _week_hours,
        yearDayoffs: _year_dayoffs,
        renew: _renew,
        dimona: _dimona,
        dimonaPlannedHours: _dimona_planned_hours,
        templateId: _template_id,
        regime: _regime,
      } = form.getFieldsValue();

      let hours_per_day = 0;
      if (isset(ud_hr_days_week)) {
        hours_per_day = ud_hr_max_hours_week / ud_hr_days_week;
      }

      form.setFieldsValue({
        userRecordId: activeUser,
        salary: isset(ud_price) ? ud_price : null,
        salaryBruto: isset(ud_price_bruto) ? ud_price_bruto : null,
        salaryPeriod: src ? src_salary_period : _salary_period ? _salary_period : ud_wage_type ? ud_wage_type : null,
        weekHours: src ? src_week_hours : isset(ud_hr_max_hours_week) ? ud_hr_max_hours_week : null,
        weekDays: src ? src_week_days : isset(ud_hr_days_week) ? ud_hr_days_week : null,
        yearDayoffs: isset(ud_legal_leaves) ? ud_legal_leaves : null,
        maxDailyHours: src ? Math.floor(src_max_daily_hours) : isset(hours_per_day) ? Math.floor(hours_per_day) : null,
        regime: isset(ud_regime) ? ud_regime : _regime,
        start: src ? moment(src_start * 1000) : null,
        end: src ? moment(src_end * 1000) : isset(ud_contract_end) && moment(ud_contract_end),
        dimona: isset(ud_dimona) ? ud_dimona : _dimona,
        dimonaPlannedHours: src ? src_week_hours : isset(ud_default_dimona_hours) ? ud_default_dimona_hours : null,
      });
    }
  }, [userData]);

  useEffect(() => {
    if (userParams) {
      const {
        templateId: _template_id,
        renew: _renew,
        dimona: _dimona,
        workerType: _worker_type,
        dimonaPlannedHours: _dimona_planned_hours,
      } = form.getFieldsValue();

      const {
        automatic_contract_template_id: up_automatic_contract_template_id,
        automatic_contracts: up_automatic_contracts,
        dimona: up_dimona,
        workerType: up_worker_type,
        hr_hours_week: up_hr_hours_week,
      } = userParams;

      if (!activeTemplateId) {
        setActiveTemplateId(up_automatic_contract_template_id);
      }

      if (userParams.deleted_template) {
        setShowFileUpload(true);
        setActiveTemplateId(null);
      }

      form.setFieldsValue({
        templateId: userParams.deleted_template
          ? null
          : isset(up_automatic_contract_template_id)
          ? up_automatic_contract_template_id
          : null,
        renew: isset(up_automatic_contracts) ? up_automatic_contracts : null,
        dimona: isset(up_dimona) ? up_dimona : _dimona,
        workerType: isset(up_dimona) ? DIMONA_FEW_WORK_TYPES.find((type) => type == up_worker_type) : null,
        dimonaPlannedHours: isset(up_dimona) && isset(up_hr_hours_week) ? up_hr_hours_week : null,
      });
    }
  }, [userParams]);

  console.log(form.getFieldsValue());

  const loadUser = (value: string | undefined | null, deleted_template: boolean) => {
    setUserLoading(true);
    const cancelTokenSource = Axios.CancelToken.source();
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/users/${activeUser}/hr/active`, {
      params: {
        departmentId: activeDepartmentId,
      },
      cancelToken: cancelTokenSource.token,
    })
      .then(({ data }) => {
        setUserData(data.data);
        getUserParams(activeUser, value, deleted_template);
      })
      .catch((error) => {
        if (Axios.isCancel(error)) {
          console.error(error);
          setUserLoading(false);
        }
      });
  };

  const getUserParams = (id: any, value: string | undefined | null, deleted_template: boolean) => {
    setUserLoading(true);
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/users/${id}/params`, {
      params: {
        departmentId: activeDepartmentId,
      },
    })
      .then(({ data }) => {
        setUserParams({ ...data.params, deleted_template });

        // setCreateDimona(data.params.dimona);
        if (value) {
          setActiveTemplateId(value);

          form.setFieldsValue({
            templateId: value,
          });
        }

        setUserLoading(false);
      })
      .catch((error) => {
        setUserLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (!location?.pathname) {
      return;
    }
    form.resetFields();
    if (location?.state) {
      const values = { ...location?.state };
      form.setFieldsValue({
        ...location?.state,
        start: values.start ? moment.unix(values.start!) : null,
        end: values.end ? moment.unix(values.end!) : null,
      });
    }
    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();
    setTemplates([]);
    setLoadingTemplates(true);
    setSignatures([]);
    setActiveTemplateId(null);
    setLoadingSignatures(true);
    setPlaceholders([]);
    setPlaceholdersReady(false);

    Axios.get(`${process.env.REACT_APP_API_URL}/v3/documents/templates`, {
      cancelToken: cancelTokenSource.token,
      params: {
        departmentId: activeDepartmentId,
        filter: 'CONTRACT',
      },
    })
      .then(({ data }) => {
        if (mounted) {
          setTemplates(data.templates);
          setLoadingTemplates(false);
        }
      })
      .catch((error) => {
        if (Axios.isCancel(error)) {
          console.error(error);
        }
        if (mounted) {
          setLoadingTemplates(false);
        }
      });

    Axios.get(`${process.env.REACT_APP_API_URL}/v3/documents/signatures`, {
      cancelToken: cancelTokenSource.token,
      params: {
        departmentId: activeDepartmentId,
      },
    })
      .then(({ data }) => {
        if (mounted) {
          setSignatures(data);
          setLoadingSignatures(false);
        }
      })
      .catch((error) => {
        if (Axios.isCancel(error)) {
          console.error(error);
        }
        if (mounted) {
          setLoadingSignatures(false);
        }
      });

    Axios.get(`${process.env.REACT_APP_API_URL}/v3/documents/placeholders`, {
      cancelToken: cancelTokenSource.token,
      params: {
        departmentId: activeDepartmentId,
        docType: 'CONTRACT',
      },
    })
      .then(({ data }) => {
        if (mounted) {
          setPlaceholders(
            data.sort((a: IDocumentPlaceholder, b: IDocumentPlaceholder) => a.label.localeCompare(b.label)),
          );
          setPlaceholdersReady(true);
        }
      })
      .catch((error) => {
        if (Axios.isCancel(error)) {
          console.error(error);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [location?.pathname]);

  useEffect(() => {
    if (!placeholdersReady) {
      return;
    }
    let mounted = true;
    const cancelTokenSource = Axios.CancelToken.source();
    const originContractId = location?.state?.contractId || contractId;
    if (originContractId && originContractId !== 'new') {
      setLoadingContract(true);
      Axios.get(`${process.env.REACT_APP_API_URL}/v3/documents/contracts/${originContractId}`, {
        cancelToken: cancelTokenSource.token,
        params: {
          departmentId: activeDepartmentId,
          docType: 'CONTRACT',
        },
      })
        .then(({ data }) => {
          if (mounted) {
            if (data.type !== 'CONTRACT') {
              return history.push(`/app/documents/documents/${data.id}`);
            }
            setFormFieldsAndDraft(data);
          }
        })
        .catch((error) => {
          if (Axios.isCancel(error)) {
            console.error(error);
          }
          if (mounted) {
            setLoadingContract(false);
          }
        });
    }
    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [location?.pathname, placeholdersReady]);

  useEffect(() => {
    if (templateData) {
      const {
        id: td_id,
        maxDailyHours: td_max_daily_hours,
        salary: td_salary,
        salaryBruto: td_salary_bruto,
        salaryPeriod: td_salary_period,
        signatory: td_signatory,
        weekDays: td_week_days,
        weekHours: td_week_hours,
        yearDayoffs: td_year_dayoffs,
      } = templateData;

      // Get all existing values
      const {
        maxDailyHours: _max_daily_hours,
        salary: _salary,
        salaryBruto: _salary_bruto,
        salaryPeriod: _salary_period,
        weekDays: _week_days,
        weekHours: _week_hours,
        yearDayoffs: _year_dayoffs,
        start: _start,
        end: _end,
        signatory: _signatory,
        templateId: _template_id,
        renew: _renew,
        dimona: _dimona,
        workerType: _worker_type,
        dimonaPlannedHours: _dimona_planned_hours,
        regime: _regime,
      } = form.getFieldsValue();
      form.resetFields();

      form.setFieldsValue({
        userRecordId: activeUser,
        salary: isset(_salary) ? _salary : isset(td_salary) ? td_salary : null,
        salaryBruto: isset(_salary_bruto) ? _salary_bruto : isset(td_salary_bruto) ? td_salary_bruto : null,
        salaryPeriod: _salary_period
          ? _salary_period
          : td_salary_period
          ? td_salary_period == 'HOUR'
            ? '0'
            : td_salary_period == 'DAY'
            ? '1'
            : td_salary_period == 'WEEK'
            ? '2'
            : td_salary_period == 'MONTH'
            ? '3'
            : null
          : null,
        weekDays: isset(_week_days) ? _week_days : isset(td_week_days) ? td_week_days : null,
        weekHours: isset(_week_hours) ? _week_hours : isset(td_week_hours) ? td_week_hours : null,
        yearDayoffs: isset(_year_dayoffs) ? _year_dayoffs : isset(td_year_dayoffs) ? td_year_dayoffs : null,
        maxDailyHours: isset(_max_daily_hours)
          ? _max_daily_hours
          : isset(td_max_daily_hours)
          ? td_max_daily_hours
          : null,
        start: _start,
        end: _end,
        signatory: isset(_signatory) ? _signatory : isset(td_signatory) ? td_signatory : null,
        templateId: isset(_template_id) ? _template_id : isset(_template_id) ? _template_id : null,
        renew: isset(_renew) ? _renew : null,
        dimona: isset(_dimona) ? _dimona : null,
        workerType: isset(_worker_type) ? _worker_type : null,
        dimonaPlannedHours: isset(_dimona_planned_hours) ? _dimona_planned_hours : null,
        regime: isset(_regime) ? _regime : null,
      });
    }
  }, [templateData]);

  useEffect(() => {
    if (!activeTemplateId || !placeholdersReady) {
      setHtmlContent('');
      return;
    }
    if (activeTemplateId) {
      let mounted = true;
      const cancelTokenSource = Axios.CancelToken.source();

      Axios.get(`${process.env.REACT_APP_API_URL}/v3/documents/templates/${activeTemplateId}`, {
        cancelToken: cancelTokenSource.token,
        params: {
          departmentId: activeDepartmentId,
        },
      })
        .then((response) => {
          if (mounted) {
            setShowFileUpload(false);
            const data = response.data;
            if (data.render === 'docx') {
              const source_splitted = data.template_download_url.split('/');
              const name = source_splitted[source_splitted.length - 1];
              if (name) {
                setFileNameOnUpdate(name);
              }
              setPreviewLoading(true);
              fetch(data.template_download_url)
                .then(async (res) => {
                  if (data.render == 'docx' && data && data.images && data.images.length == 0) {
                    beforeUpload((await res.blob()) as RcFile);
                  }
                  setFile((await res.blob()) as any);
                  setPreview(data);
                  setPreviewLoading(false);
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            setTemplateData(data);
            if (data.render === 'html') {
              setFile(null);
              setPreview(null);
              setPreviewLoading(false);
              setOriginalEditorContent(data.html);
              setHtmlContent(getHtmlWithCodes(data?.html || '', placeholdersRef?.current));
            }
          }
        })
        .catch((error) => {
          setShowFileUpload(true);
          if (Axios.isCancel(error)) {
            console.error(error);
          }
        });

      return () => {
        mounted = false;
        cancelTokenSource.cancel();
      };
    }
  }, [activeTemplateId, placeholdersReady]);

  const onWantToChangeTemplateId = (value: string) => {
    if (!value) {
      setFile(null);
      setPreview(null);
      setPreviewLoading(false);
      setShowFileUpload(true);
    }
    if (editorRef?.current?.getContent()) {
      Modal.confirm({
        title: t('GLOBAL.WARNING'),
        type: 'warning',
        content: t('DOCUMENTS.MODAL_CHANGE_TEMPLATE.CONTENT'),
        cancelText: t('GLOBAL.CANCEL'),
        okText: t('GLOBAL.VALIDATE'),
        okType: 'danger',
        onOk: () => {
          if (!value) {
            setShowFileUpload(true);
          }
          if (!value && !activeUser) {
            form.resetFields();
          }
          if (!value && activeUser) {
            form.resetFields();
            form.setFieldsValue({
              userRecordId: activeUser,
            });
            loadUser(value, true);
          }
          setActiveTemplateId(value);
        },
        onCancel: () => {
          setShowFileUpload(false);
          form.setFieldsValue({ templateId: activeTemplateId });
        },
      });
    } else {
      setActiveTemplateId(value);
    }
  };

  const getHtmlWithPlaceholders = () => {
    const html = editorRef.current.getContent() || '';
    const regex = /(<code.+?data\-key\="([a-zA-Z0-9_]+)".+?>)(.*?)(<\/code>)?/g;

    const htmlWithIds = html.replace(regex, (match: any, p1: string, p2: string) => {
      if (p2) {
        return `{{${p2}}}`;
      } else {
        return match;
      }
    });

    return htmlWithIds;
  };

  const setFormFieldsAndDraft = (data: any) => {
    const { formValues, render } = data;
    setActiveUser(formValues.userRecordId);
    if (render.as === 'docx') {
      const source_splitted = render.source.split('/');
      const name = source_splitted[source_splitted.length - 1];
      if (name) {
        setFileNameOnUpdate(name);
      }
      setPreviewLoading(true);
      fetch(render.source)
        .then(async (res) => {
          setFile((await res.blob()) as any);
          setPreview(render);
          setPreviewLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    form.setFieldsValue({
      ...formValues,
      start: formValues.start ? moment.unix(formValues.start!) : null,
      end: formValues.end ? moment.unix(formValues.end!) : null,
    });
    if (render.as === 'html') {
      setHtmlContent(getHtmlWithCodes(render.user_html || '', placeholdersRef?.current));
    }
    setLoadingContract(false);
  };

  const onPreview = async () => {
    try {
      await form.validateFields();
      const formData = new FormData();
      const values = form.getFieldsValue();

      const parsedValues = {
        ...values,
        start:
          src && moment(src_start * 1000).isSame(values.start) ? src_start : values.start ? values.start?.unix() : null,
        end: src && moment(src_end * 1000).isSame(values.start) ? src_end : values.end ? values.end?.unix() : null,
      };

      for (var key in parsedValues) {
        if (parsedValues[key]) {
          formData.append(`formValues[${key}]`, parsedValues[key]);
        }
      }
      if (file) {
        formData.append('formValues[file]', file);
        formData.delete('html');
      }
      if (originalEditorContent !== editorRef.current.getContent()) {
        formData.delete('formValues[file]');
        formData.append('html', getHtmlWithPlaceholders());
      }
      // render_signature is boolean but can only send strings in FormData
      formData.append('render_signature', '1');

      setPreviewLoading(true);
      Axios({
        url: `${process.env.REACT_APP_API_URL}/v3/documents/preview`,
        method: 'POST',
        data: formData,
        params: {
          departmentId: activeDepartmentId,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(({ data }) => {
          Modal.info({
            closable: true,
            icon: null,
            content: <div dangerouslySetInnerHTML={{ __html: data?.html }} />,
            onOk() {},
            style: { top: '20px' },
            autoFocusButton: null,
          });
          setPreview(data);
          setPreviewLoading(false);
        })
        .catch((err) => {
          setFile(null);
          setPreview(null);
          setPreviewLoading(false);
          if (err.response?.data?.errors) {
            setShowErrorsModal(true);
            setErrors(Object.values(err.response.data.errors).flat(1) as string[]);
          }
        });
    } catch (error) {
      console.log(error);
      window.scrollTo(0, 0);
    }
  };

  const onFinish = (values: any) => {
    setIsSaving(true);
    const parsedValues = {
      ...values,
      start:
        src && moment(src_start * 1000).isSame(values.start) ? src_start : values.start ? values.start?.unix() : null,
      end: src && moment(src_end * 1000).isSame(values.start) ? src_end : values.end ? values.end?.unix() : null,
    };

    const url = updatingContract
      ? `${process.env.REACT_APP_API_URL}/v3/documents/contracts/${contractId}`
      : `${process.env.REACT_APP_API_URL}/v3/documents/contracts`;

    const formData = new FormData();
    for (var key in parsedValues) {
      if (parsedValues[key]) {
        if (key == 'renew' || key == 'dimona') {
          formData.append(`formValues[${key}]`, parsedValues[key] ? '1' : '0');
        } else {
          formData.append(`formValues[${key}]`, parsedValues[key]);
        }
      }
    }

    if (file) {
      formData.append('formValues[file]', file);
      formData.delete('html');
    } else {
      if (originalEditorContent !== editorRef.current.getContent()) {
        // formData.delete('formValues[file]');
        // formData.delete('formValues[templateId]');
        formData.append('html', getHtmlWithPlaceholders());
      }
    }

    Axios({
      url,
      method: 'POST',
      data: formData,
      params: {
        departmentId: activeDepartmentId,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        form.setFieldsValue({ ...data });
        message.success(t('DOCUMENTS.CONTRACTS.MESSAGE_SAVING_SUCCESS'));
        setIsSaving(false);
        if (!updatingContract) {
          history.push('/app/documents/contracts/all');
        } else {
          history.push(data.id);
        }
      })
      .catch((err) => {
        if (err.response?.data?.errors) {
          setShowErrorsModal(true);
          setErrors(Object.values(err.response.data.errors).flat(1) as string[]);
        }
        setIsSaving(false);
      });
  };

  const onUserRecordIdChange = (userRecordId: string) => {
    if (userRecordId) {
      if (!updatingContract) {
        setActiveUser(userRecordId);
      }
      // setWorkerTypeIsLoading(true);
      // Axios.get(`${process.env.REACT_APP_API_URL}/v3/users/${userRecordId}/worker-type`, {
      //   params: {
      //     departmentId: activeDepartmentId,
      //   },
      // })
      //   .then(({ data }) => {
      //     // form.setFieldsValue({
      //     //   workerType: data.params.dimona && DIMONA_FEW_WORK_TYPES.find((type) => type.id === data.workerType)?.id,
      //     // });
      //     // setWorkerTypeIsLoading(false);
      //   })
      //   .catch((error) => {
      //     form.setFieldsValue({ workerType: null });
      //     setWorkerTypeIsLoading(false);
      //   });
    } else {
      form.setFieldsValue({ workerType: null });
    }
  };

  const changeDimonaStatus = (e: CheckboxChangeEvent) => {
    setCreateDimona(e.target.checked);
    form.setFieldsValue({
      workerType: null,
      dimonaPlannedHours: null,
    });
  };

  const onErrorsOk = () => {
    setShowErrorsModal(false);
  };

  const onErrorsCancel = () => {
    setShowErrorsModal(false);
  };

  const beforeUpload = (_file: RcFile) => {
    setFile(_file);
    setPreview(null);
    setPreviewLoading(true);

    const formData = new FormData();
    const values = form.getFieldsValue();

    const parsedValues = {
      ...values,
      start:
        src && moment(src_start * 1000).isSame(values.start) ? src_start : values.start ? values.start?.unix() : null,
      end: src && moment(src_end * 1000).isSame(values.start) ? src_end : values.end ? values.end?.unix() : null,
    };
    for (var key in parsedValues) {
      if (parsedValues[key]) {
        formData.append(`formValues[${key}]`, parsedValues[key]);
      }
    }
    if (_file) {
      formData.append('formValues[file]', _file);
      formData.delete('html');
      formData.delete('formValues[templateId]');
    } else {
      if (originalEditorContent !== editorRef.current.getContent()) {
        formData.delete('formValues[file]');
        formData.delete('formValues[templateId]');
        formData.append('html', getHtmlWithPlaceholders());
      }
    }
    // render_signature is boolean but can only send strings in FormData
    formData.append('render_signature', '1');

    const previewQuery = previewContent(formData);
    setPreviewDocumentQuery(previewQuery);

    previewQuery
      .then((data) => {
        setPreviewLoading(false);
        setPreview(data);
      })
      .catch((err) => {
        setFile(null);
        setPreview(null);
        setPreviewLoading(false);
        if (err.response?.data?.errors) {
          setShowErrorsModal(true);
          setErrors(Object.values(err.response.data.errors).flat(1) as string[]);
        }
      });

    return false;
  };

  const previewContent = (formData: FormData) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        const response = await Axios({
          url: `${process.env.REACT_APP_API_URL}/v3/documents/preview`,
          method: 'POST',
          data: formData,
          params: {
            departmentId: activeDepartmentId,
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          signal: cancelUploadFileController.signal,
        });
        const data = await response.data;
        resolve(data);
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
    (promise as PromiseWithCancel<DocumentPreview | null>).cancel = () => cancelUploadFileController.abort();
    return promise as PromiseWithCancel<DocumentPreview | null>;
  };

  const onClearFile = () => {
    previewDocumentQuery?.cancel();
    setFile(null);
    setPreview(null);
  };

  const onChangePage = (direction: string, pagination: string) => {
    if (preview && preview.images.length > 0) {
      switch (direction) {
        case 'left': {
          if (activeTemplatePageIndex == 0) {
            setActiveTemplatePageIndex(preview.images.length - 1);
          } else {
            setActiveTemplatePageIndex(activeTemplatePageIndex - 1);
          }
          if (pagination == 'bottom') {
            window.scroll({ behavior: 'smooth', top: previewRef?.current?.offsetTop });
          }
          break;
        }
        case 'right': {
          if (activeTemplatePageIndex == preview.images.length - 1) {
            setActiveTemplatePageIndex(0);
          } else {
            setActiveTemplatePageIndex(activeTemplatePageIndex + 1);
          }
          if (pagination == 'bottom') {
            window.scroll({ behavior: 'smooth', top: previewRef?.current?.offsetTop });
          }
          break;
        }
      }
    }
  };

  const onHowToUseVariables = () => {
    setHowToUseVariablesVisible(true);
  };

  return (
    <ContainerView>
      <ModalHowToUseVariables
        howToUseVariablesVisible={howToUseVariablesVisible}
        setHowToUseVariablesVisible={setHowToUseVariablesVisible}
        placeholders={placeholders}
        documentType="CONTRACT"
      />
      <Modal
        forceRender={true}
        maskClosable={false}
        destroyOnClose={true}
        visible={showErrorsModal}
        className={'modal-danger'}
        title={t('CONTRACTS.CREATE_CONTRACT_ERROR')}
        onOk={onErrorsOk}
        onCancel={onErrorsCancel}
        footer={[
          <Button key="back" onClick={onErrorsCancel}>
            {t('GLOBAL.OK')}
          </Button>,
        ]}
      >
        <List
          dataSource={errors}
          renderItem={(item) => (
            <List.Item>
              <p>{item}</p>
            </List.Item>
          )}
        />
      </Modal>
      <TableViewStyled className={className}>
        <header>
          <div className="left">
            <h2>{contractId !== 'new' ? t('CONTRACTS.CONTRACT_MODIFICATION') : t('CONTRACTS.NEW_CONTRACT')}</h2>
          </div>
          {!previewLoading && (
            <div className="right">
              <Space>
                <Button loading={isSaving} type="primary" onClick={() => form.submit()}>
                  {t('GLOBAL.SAVE')}
                </Button>
                <Button type="primary" danger onClick={() => history.push('/app/documents/contracts/all')}>
                  {t('GLOBAL.BACK')}
                </Button>
              </Space>
            </div>
          )}
        </header>
        <ScrollToTop />
        <Spin spinning={loadingContract || isSaving || userLoading}>
          <Form
            layout="vertical"
            size="large"
            form={form}
            onFinish={onFinish}
            scrollToFirstError={true}
            initialValues={{ ['renew_period']: 'DAY' }}
            className="contract-container"
          >
            <Row gutter={[20, 30]}>
              <Col span={windowSize.innerWidth > 900 ? 12 : 24}>
                <div className="card-shadow">
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                      name="userRecordId"
                      label={t('CONTRACTS.FORM.WHO')}
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? 'calc(50% - 5px)' : '100%',
                        marginRight: '10px',
                      }}
                      rules={[{ required: true }]}
                    >
                      <Select
                        disabled={src}
                        defaultValue={state && state.userRecordId}
                        getPopupContainer={(trigger) => trigger}
                        showSearch
                        placeholder={t('FORMS.USER_PLACEHOLDER')}
                        optionFilterProp="children"
                        loading={loadingUsers}
                        filterOption={(input, option) =>
                          option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={onUserRecordIdChange}
                      >
                        {users.map((user) => {
                          const { recordId, firstname, lastname } = user;
                          return (
                            <Option key={`user_${recordId}`} value={`${recordId}`}>
                              {`${firstname} ${lastname}`}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label={t('CONTRACTS.FORM.TEMPLATE')}
                      name="templateId"
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? 'calc(50% - 5px)' : '100%',
                      }}
                    >
                      <Select
                        allowClear
                        getPopupContainer={(trigger) => trigger}
                        loading={loadingTemplates}
                        showSearch
                        placeholder={t('CONTRACTS.FORM.TEMPLATE_PLACEHOLDER')}
                        optionFilterProp="children"
                        onChange={onWantToChangeTemplateId}
                        filterOption={(input, option) =>
                          option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {templates.map((template) => {
                          const { name, id } = template;
                          return (
                            <Option key={`template_${id}`} value={`${id}`}>
                              {name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                      label={t('GLOBAL.START_PERIOD')}
                      name="start"
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? 'calc(50% - 5px)' : '100%',
                        marginRight: '10px',
                      }}
                      rules={[{ required: true }]}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        format={'L'}
                        onSelect={(e) => {
                          form.setFieldsValue({
                            start: e,
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={t('CONTRACTS.FORM.END')}
                      name="end"
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? 'calc(50% - 5px)' : '100%',
                      }}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        format={'L'}
                        onSelect={(e) => {
                          form.setFieldsValue({
                            end: e,
                          });
                        }}
                      />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item name="signatory" label={t('CONTRACTS.FORM.SIGNATORY')} rules={[{ required: true }]}>
                    <Select
                      allowClear
                      getPopupContainer={(trigger) => trigger}
                      showSearch
                      loading={loadingSignatures}
                      placeholder={t('CONTRACTS.FORM.SIGNATORY_PLACEHOLDER')}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option!.children.join().toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {signatures.map((signature) => {
                        const { name, id, title } = signature;
                        return (
                          <Option key={`signatory_${id}`} value={`${id}`}>
                            {name} - {title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  {showFileUpload && (
                    <Form.Item label={t('DOCUMENTS.CONTRACTS.UPLOAD_CONTRACT')}>
                      <div className="upload-file-container">
                        <div className="left">
                          <Upload
                            beforeUpload={beforeUpload}
                            accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            maxCount={1}
                            showUploadList={false}
                            className="browse-btn-container"
                          >
                            <div className="content">
                              <button type="button" className="browse-btn">
                                {file
                                  ? file.name
                                    ? file.name
                                    : fileNameOnUpdate && fileNameOnUpdate
                                  : t('GLOBAL.BROWSE')}
                              </button>
                            </div>
                          </Upload>
                          {file && <i className="icon-plus" onClick={onClearFile} />}
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                      >
                        <button type="button" style={{ textDecoration: 'underline' }} onClick={onHowToUseVariables}>
                          {t('DOCUMENTS.VARIABLES.TITLE')}
                        </button>
                      </div>
                    </Form.Item>
                  )}
                </div>
              </Col>
              <Col span={windowSize.innerWidth > 900 ? 12 : 24}>
                <div className="card-shadow">
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                      name="weekHours"
                      label={t('CONTRACTS.FORM.WEEK_HOURS')}
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                        marginRight: 10,
                      }}
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        type="number"
                        min={0}
                        style={{ width: '100%' }}
                        step="any"
                        parser={(value) => {
                          return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="salary"
                      label={
                        <Typography.Text
                          ellipsis={{ tooltip: t('GLOBAL.EMPLOYEE_COST') }}
                          style={{ fontSize: 'inherit' }}
                        >
                          {t('GLOBAL.EMPLOYEE_COST')}
                        </Typography.Text>
                      }
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? 'calc(16.25% - 5px)' : '100%',
                        marginRight: 10,
                      }}
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        type="number"
                        min={0}
                        style={{ width: '100%' }}
                        step="0.0001"
                        parser={(value) => {
                          return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="salaryBruto"
                      label={
                        <Typography.Text ellipsis={{ tooltip: t('GLOBAL.EMPLOYER_COST') }}>
                          {t('GLOBAL.EMPLOYER_COST')}
                        </Typography.Text>
                      }
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? 'calc(16.25% - 5px)' : '100%',
                        marginRight: 10,
                      }}
                    >
                      <InputNumber
                        type="number"
                        min={0}
                        style={{ width: '100%' }}
                        step="0.0001"
                        parser={(value) => {
                          return parseFloat(value?.replace(',', '.').replace(/[^\d\n,.]/, '') || '');
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="salaryPeriod"
                      label={t('CONTRACTS.FORM.SALARY_PERIOD')}
                      rules={[{ required: true }]}
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                      }}
                    >
                      <Select
                        allowClear
                        getPopupContainer={(trigger) => trigger}
                        placeholder={t('CONTRACTS.FORM.SALARY_PERIOD')}
                        optionFilterProp="children"
                      >
                        <Option value="0">{t('GLOBAL.HOUR')}</Option>
                        <Option value="1">{t('GLOBAL.DAY')}</Option>
                        <Option value="2">{t('GLOBAL.WEEK')}</Option>
                        <Option value="3">{t('GLOBAL.MONTH')}</Option>
                      </Select>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                      name="weekDays"
                      label={t('CONTRACTS.FORM.WEEK_DAYS')}
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                        marginRight: 10,
                      }}
                    >
                      <InputNumber type="number" min={0} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                      name="yearDayoffs"
                      label={t('CONTRACTS.FORM.DAYOFFS_NUMBER_BY_YEAR')}
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                        marginRight: 10,
                      }}
                    >
                      <InputNumber type="number" min={0} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                      name="maxDailyHours"
                      label={t('CONTRACTS.FORM.MAX_DAILY_HOURS')}
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                      }}
                    >
                      <InputNumber type="number" min={0} style={{ width: '100%' }} />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item>
                    <Form.Item
                      name="regime"
                      label={<span style={{ display: 'flex', alignItems: 'center' }}>{t('GLOBAL.REGIME')}</span>}
                      style={{
                        display: 'inline-block',
                        width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                      }}
                    >
                      <Select
                        allowClear
                        getPopupContainer={(trigger) => trigger}
                        placeholder={t('GLOBAL.REGIME')}
                        optionFilterProp="children"
                      >
                        {REGIME_TYPES.map((type) => {
                          return (
                            <Select.Option key={`regime_${type}`} value={type!}>
                              {t(`CONTRACTS.REGIME_TYPES.${type}`)}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Form.Item>
                  {isFeatureEnabled(features, FEATURES.AUTOMATIC_DIMONA_CONTRACTS) && (
                    <Form.Item style={{ marginBottom: 0 }}>
                      <Form.Item
                        name="renew"
                        valuePropName="checked"
                        style={{
                          display: 'inline-block',
                          width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                          marginRight: 10,
                        }}
                      >
                        <Checkbox>{t('CONTRACTS.FORM.AUTO_RENEW')}</Checkbox>
                      </Form.Item>
                      <Form.Item
                        shouldUpdate={(prevValues, curValues) => prevValues.renew !== curValues.renew}
                        style={{
                          display: 'inline-block',
                          width: windowSize.innerWidth > 900 ? 'calc(33% - 5px)' : '100%',
                          margin: 0,
                        }}
                      >
                        {(form) => {
                          const renew = form.getFieldValue('renew');
                          if (renew) {
                            return (
                              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <span>{t('GLOBAL.FOR').toLowerCase()}</span>
                                <Form.Item
                                  name="renewNumber"
                                  style={{
                                    display: 'inline-block',
                                    width: 'calc(40% - 10px)',
                                    margin: '0 10px',
                                  }}
                                  rules={[{ required: true }]}
                                >
                                  <InputNumber type="number" min={1} step={1} style={{ width: '100%' }} />
                                </Form.Item>
                                <Form.Item
                                  name="renewPeriod"
                                  style={{ display: 'inline-block', width: 'calc(60% - 5px)', marginBottom: 0 }}
                                  rules={[{ required: true }]}
                                >
                                  <Select
                                    allowClear
                                    getPopupContainer={(trigger) => trigger}
                                    placeholder={t('GLOBAL.PERIOD')}
                                    optionFilterProp="children"
                                  >
                                    <Option value="DAY">{t('GLOBAL.DAY(S)')}</Option>
                                    <Option value="MONTH">{t('GLOBAL.MONTH')}</Option>
                                  </Select>
                                </Form.Item>
                              </div>
                            );
                          }
                        }}
                      </Form.Item>
                    </Form.Item>
                  )}
                  {userParams?.dimona && isFeatureEnabled(features, FEATURES.DIMONA) && (
                    <Form.Item style={{ marginBottom: 0 }}>
                      <Form.Item
                        name="dimona"
                        valuePropName="checked"
                        style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginRight: 10 }}
                      >
                        <Checkbox onChange={changeDimonaStatus}>{t('DIMONA.CREATE_DIMONA')}</Checkbox>
                      </Form.Item>
                      <Form.Item
                        shouldUpdate={(prevValues, curValues) => prevValues.dimona !== curValues.dimona}
                        style={{ display: 'inline-block', width: 'calc(50% - 5px)', margin: 0 }}
                      >
                        {(form) => {
                          const dimona = form.getFieldValue('dimona');
                          if (createDimona || dimona) {
                            return (
                              <Form.Item
                                name="workerType"
                                rules={[{ required: true }]}
                                style={{ display: 'inline-block', width: '100%' }}
                              >
                                <Select
                                  loading={workerTypeIsLoading}
                                  allowClear
                                  getPopupContainer={(trigger) => trigger}
                                  placeholder={t('FORMS.WORKER_TYPE_PLACEHOLDER')}
                                >
                                  {DIMONA_FEW_WORK_TYPES.map((workerType) => {
                                    return (
                                      <Option key={`userType_${workerType}`} value={`${workerType}`}>
                                        {t(`DIMONA.WORKTYPES.${workerType}`)}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            );
                          }
                        }}
                      </Form.Item>
                      <Form.Item
                        shouldUpdate={(prevValues, curValues) => prevValues.workerType !== curValues.workerType}
                        style={{ margin: 0 }}
                      >
                        {(form) => {
                          const workerType = form.getFieldValue('workerType');
                          const dimona = form.getFieldValue('dimona');
                          if ((createDimona || dimona) && workerType === 'STU') {
                            return (
                              <>
                                <Form.Item
                                  name="dimonaPlannedHours"
                                  label={t('GLOBAL.HOURS_NUMBER')}
                                  rules={[{ required: true }]}
                                >
                                  <InputNumber type="number" min={1} style={{ width: '100%' }} />
                                </Form.Item>
                                {placesOfWork.length > 0 && (
                                  <Form.Item
                                    label={
                                      <span style={{ display: 'flex', alignItems: 'center' }}>
                                        {t('DIMONA.PLACE_OF_WORK')}
                                      </span>
                                    }
                                    name="placeOfWork"
                                  >
                                    <Select
                                      placeholder={t('DIMONA.PLACE_OF_WORK')}
                                      allowClear
                                      showSearch
                                      filterOption={(input, option) =>
                                        option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      <Select.Option key={`dimona-no-place-of-work`} value={'null'}>
                                        {t('DIMONA.NO_PLACE_OF_WORK')}
                                      </Select.Option>
                                      {placesOfWork.map((place) => (
                                        <Select.Option key={place} value={place}>
                                          {place}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                )}
                              </>
                            );
                          }
                        }}
                      </Form.Item>
                    </Form.Item>
                  )}
                </div>
              </Col>
            </Row>
            {!file && (
              <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={htmlContent}
                init={{
                  height: 600,
                  menubar: false,
                  setup: function (editor) {
                    editor.ui.registry.addMenuButton('placeholders', {
                      text: 'Variables',
                      fetch: (callback) => {
                        callback(
                          placeholdersRef.current?.map(({ id, label }: any) => ({
                            type: 'menuitem',
                            text: label,
                            onAction: function () {
                              editor.insertContent(
                                `<code data-key="${id}" class="mceNonEditable" contenteditable="false">${label}</code>`,
                              );
                            },
                          })),
                        );
                      },
                    });
                  },
                  plugins: TINYMCE_PLUGINS,
                  toolbar: TINYMCE_TOOLBAR,
                  content_style: TINYMCE_CONTENT_STYLE,
                }}
              />
            )}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 15 }} ref={previewRef}>
              <>
                {file && (
                  <>
                    {previewLoading ? (
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Spin spinning={previewLoading} />
                        <span>{t('DOCUMENTS.PREVIEWS.LOADING')}</span>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          gap: 15,
                          width: '100%',
                          paddingBottom: 50,
                        }}
                      >
                        {preview && (
                          <div>
                            {preview.images.length !== 1 && (
                              <button
                                className="slider-arrow"
                                type="button"
                                onClick={() => onChangePage('left', 'top')}
                              >
                                &lt;
                              </button>
                            )}
                            {t('DOCUMENTS.PREVIEWS.PAGE_X_OF_X', {
                              current: activeTemplatePageIndex + 1,
                              length: preview.images.length,
                            })}
                            {preview.images.length !== 1 && (
                              <button
                                className="slider-arrow"
                                type="button"
                                onClick={() => onChangePage('right', 'top')}
                              >
                                &gt;
                              </button>
                            )}
                          </div>
                        )}
                        <div style={{ width: '100%' }}>
                          {preview && (
                            <img
                              className="template-image"
                              src={preview.images[activeTemplatePageIndex]}
                              style={{ width: '100%' }}
                            />
                          )}
                        </div>
                        <div>
                          {preview && (
                            <div>
                              {preview.images.length !== 1 && (
                                <button
                                  className="slider-arrow"
                                  type="button"
                                  onClick={() => onChangePage('left', 'bottom')}
                                >
                                  &lt;
                                </button>
                              )}
                              {t('DOCUMENTS.PREVIEWS.PAGE_X_OF_X', {
                                current: activeTemplatePageIndex + 1,
                                length: preview.images.length,
                              })}
                              {preview.images.length !== 1 && (
                                <button
                                  className="slider-arrow"
                                  type="button"
                                  onClick={() => onChangePage('right', 'bottom')}
                                >
                                  &gt;
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            </div>
            <div className={`actions active`}>
              {/* <Button
                type="primary"
                onClick={onPreview}
                style={{ background: colors.orange, borderColor: colors.orange, marginRight: 10 }}
                loading={previewLoading}
              >
                {t('GLOBAL.PREVIEW')}
              </Button> */}
              <Button loading={isSaving} type="primary" htmlType="submit" disabled={previewLoading}>
                {t('GLOBAL.SAVE')}
              </Button>
            </div>
          </Form>
        </Spin>
      </TableViewStyled>
    </ContainerView>
  );
};

const ContractStyled = styled(Contract)`
  background: ${colors.blueExtraLight};
  border-radius: 3px;

  label {
    width: 100%;
  }

  .ant-form-item-label > label {
    font-size: unset;
  }

  .upload-file-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      border-radius: 5px;
      background-color: #f6f8fc;
      border: 1px solid #e2e6ef;
      .icon-plus {
        transform: rotate(45deg);
        padding: 0 15px;
        cursor: pointer;
      }
    }
    .browse-btn-container {
      flex: 1;
      .ant-upload-select {
        width: 100%;
        .browse-btn {
          display: flex;
          justify-content: space-between;
          padding: 10px;
          width: 100%;
        }
      }
    }
  }

  .ant-row {
    margin-bottom: 20px;
  }
  .contract-container {
    padding: 0 25px 25px;
    .ant-col .card-shadow {
      padding: 0 20px;
    }
  }
  .tox-tinymce {
    margin-bottom: 40px;
  }
  .card-shadow {
    padding: 20px !important;
    justify-content: flex-start;
  }
  .actions {
    position: fixed;
    width: calc(100vw - 160px);
    background: white;
    bottom: -80px;
    margin: 0;
    margin-left: 40px;
    display: flex;
    box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.15);
    padding: 10px;
    border-radius: 3px 3px 0 0;
    transition: bottom 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    &.active {
      transition-timing-function: ease-in;
      bottom: 0px;
    }
  }

  @media screen and (max-width: 900px) {
  }
`;

export default ContractStyled;

const DIMONA_FEW_WORK_TYPES = ['OTH', 'STU', 'FLX', 'S17', 'O17'];

const isset = (value: string | number | null | undefined) => {
  if (!value) {
    return false;
  }
  if (typeof value == 'string') {
    return (
      value !== '' && value !== '0' && value !== '0.0' && value !== '0.00' && value !== '0.000' && value !== '0.0000'
    );
  }
  if (typeof value == 'number') {
    return value !== 0 && value !== 0.0;
  }
};
