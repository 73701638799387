import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { minutesToHoursAndOrMinutes } from '@/utils';

type ShiftVerificationHoursColumnProps = {
  hours: number;
  status: string;
  className?: string;
};

const ShiftVerificationHoursColumn: FC<ShiftVerificationHoursColumnProps> = ({ hours, status, className }) => {
  const { t } = useTranslation();
  return (
    <span className={`${className} ${status}`}>
      <i className="icon-up-big" />
      {minutesToHoursAndOrMinutes(Math.abs(hours) * 60)}
      <span>{t(status === 'under' ? 'DASHBOARD.WIDGETS.UNDERBOOKING' : 'DASHBOARD.WIDGETS.OVERBOOKING')}</span>
    </span>
  );
};

const ShiftVerificationHoursColumnStyled = styled(ShiftVerificationHoursColumn)`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-end;
  border-radius: 3px;
  width: fit-content;
  padding: 2px 7px;
  margin: 0 10px 0 auto;

  &.under {
    background-color: rgba(189, 212, 255, 0.65);
    color: rgb(35, 102, 165);

    .icon-up-big {
      transform: rotate(180deg);
    }
  }

  &.over {
    background-color: rgba(255, 198, 203, 0.65);
    color: rgb(177, 37, 43);
  }

  .icon-up-big {
    font-size: 10px;
  }
`;

export default ShiftVerificationHoursColumnStyled;
