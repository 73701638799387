import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table/interface';

type TableWidgetProps<T> = {
  data: T[];
  columns: ColumnsType<T>;
  pagination?: TablePaginationConfig | false;
  className?: string;
};

const TableWidget = <T extends object>({
  data,
  columns,
  className,
  pagination = false,
}: TableWidgetProps<T>): ReactElement | null => {
  return (
    <Table
      rowKey={'id'}
      size={'small'}
      className={className}
      scroll={{ x: 'max-content' }}
      showHeader={false}
      pagination={pagination}
      columns={columns}
      dataSource={data}
    />
  );
};

const TableWidgetStyled = styled(TableWidget)`
  height: calc(100% - 35px);
  overflow-y: auto;
  
  .ant-table-cell {
    padding: 4px 0 !important;
  }
` as typeof TableWidget;

export default TableWidgetStyled;
