import { Moment } from 'moment';
import { getDimonas } from '../api/index';
import { useQuery } from '@tanstack/react-query';
import { IV4Dimona } from '@/types/dimona.model';

export const useDimonas = (
  startDate: Moment,
  endDate: Moment,
) => {
  const { data: dimonas = [], isLoading: loading, refetch } = useQuery({
    queryKey: ['get-dimonas', startDate, endDate],
    queryFn: () => getDimonas(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')),
  });

  return {
    refetch,
    loading,
    dimonas: dimonas as IV4Dimona[],
  };
};
