import React, { FC } from 'react';
import styled from 'styled-components';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

type LineChartWidgetProps = {
  data: ChartData<'line'>;
  options?: ChartOptions<'line'>;
  className?: string;
  showLegend?: boolean;
};

const LineChartWidget: FC<LineChartWidgetProps> = ({ data, options, className, showLegend = true }) => {
  return (
    <div className={className}>
      <Line
        data={data}
        plugins={[ChartDataLabels]}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: showLegend,
              labels: {
                usePointStyle: true,
                pointStyle: 'circle',
                boxWidth: 5,
                boxHeight: 5,
                padding: 20,
                font: {
                  weight: 'bold',
                },
              },
            },
            datalabels: {
              anchor: 'end',
              align: 'top',
              offset: -3,
              formatter: (v) => (v ? Math.round(v) : null),
            },
          },
          scales: {
            x: {
              grid: {
                drawBorder: false,
                display: false,
              },
            },
            y: {
              display: false,
              grid: {
                display: false,
              },
            },
          },
          ...options,
        }}
      />
    </div>
  );
};

const LineChartWidgetStyled = styled(LineChartWidget)`
  height: calc(100% - 35px);
`;

export default LineChartWidgetStyled;
