import { client } from '@/ReactQueryProvider';
import { useMutation } from '@tanstack/react-query';
import { updateWidgetsPosition } from '../api/index';
import { V4WidgetSPositionUpdatePayload } from '../types/index';

export const useWidgetsPositionUpdate = () => {
  const { mutate, isLoading } = useMutation({
    mutationKey: ['update-widgets-position'],
    mutationFn: (payload: V4WidgetSPositionUpdatePayload) => updateWidgetsPosition(payload),
    onSuccess: () => client.invalidateQueries({ queryKey: ['get-widgets'] }),
  });

  return {
    updateWidgetsPosition: mutate,
    isLoading,
  };
};
