import React, { FC } from 'react';
import { Switch } from 'antd';
import { useWidgetUpdate } from '../hooks';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { widgetTranslationsBySlug } from '../utils';

type WidgetToggleProps = {
  slug: string;
  isVisible: boolean;
  className?: string;
};

const WidgetToggle: FC<WidgetToggleProps> = ({ slug, isVisible, className }) => {
  const { t } = useTranslation();
  const { updateWidget, isLoading } = useWidgetUpdate();

  return (
    <span className={className}>
      {t(widgetTranslationsBySlug[slug])}
      <Switch
        size={'small'}
        loading={isLoading}
        checked={isVisible}
        onChange={() => updateWidget({ slug, payload: { isVisible: !isVisible } })}
      />
    </span>
  );
};

const WidgetToggleStyled = styled(WidgetToggle)`
  gap: 20px;
  display: flex;
  justify-content: space-between;
`;

export default WidgetToggleStyled;
