import TableView from '@/layouts/TableView';
import AppContext from '@/pages/app/context';
import { IMessage } from '@/types/message.model';
import { BackTop, Button, Table } from 'antd';
import Axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerMessage from '../../components/drawers/Message';
import Header from './components/Header';
moment.tz.setDefault('Atlantic/Reykjavik');

const AppHoursMessagesPage: React.FC = () => {
  const {
    state: { activeDepartmentId, activeDepartment },
  } = useContext(AppContext);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [activeMessage, setActiveMessage] = useState<IMessage | null>(null);
  const [loadingMessages, setLoadingMessages] = useState<boolean>(false);
  const [newMessageCounter, setNewMessageCounter] = useState<number>(0);
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    moment.tz.setDefault('Atlantic/Reykjavik');
    moment.updateLocale(i18n.language, {
      week: {
        dow: 1,
      },
    });
    setColumns([
      {
        title: t('GLOBAL.SENDING_DATE'),
        key: 'sendingDate',
        render: (text: string, message: IMessage) => {
          return moment.unix(message.sendingDate!).format('L');
        },
      },
      {
        title: t('GLOBAL.HOUR'),
        key: 'sendingHour',
        render: (text: string, message: IMessage) => {
          return moment.unix(message.sendingDate!).tz(activeDepartment?.timezone!).format('HH:mm');
        },
      },
      {
        title: t('GLOBAL.MESSAGE'),
        key: 'subject',
        dataIndex: 'subject',
      },
      {
        title: t('GLOBAL.RECIPIENTS'),
        key: 'recipients',
        render: (text: string, message: IMessage) => {
          return message?.recipients?.userCategories?.map((category) => category.name).join(', ');
        },
      },
      // {
      //   title: t('GLOBAL.STATUS'),
      //   key: 'status',
      //   dataIndex: 'status',
      // },
      {
        title: t('GLOBAL.CREATED_BY'),
        key: 'createdBy',
        render: (text: string, message: IMessage) => {
          return message?.createdBy?.displayName;
        },
      },
      {
        title: t('GLOBAL.DATE'),
        key: 'createdAt',
        render: (text: string, message: IMessage) => {
          return moment.unix(message.createdAt!).format('L');
        },
      },
      {
        key: 'actions',
        render: (text: string, message: IMessage) => {
          if (message?.status === 'SCHEDULED') {
            return (
              <Button size="small" type="primary" danger onClick={() => setActiveMessage(message)}>
                {t('GLOBAL.CANCEL')}
              </Button>
            );
          } else {
            return (
              <Button size="small" type="primary" onClick={() => setActiveMessage(message)}>
                {t('MESSAGES.SEE_MESSAGE')}
              </Button>
            );
          }
        },
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    const cancelTokenSource = Axios.CancelToken.source();
    setLoadingMessages(true);
    setMessages([]);
    Axios.get(`${process.env.REACT_APP_API_URL}/v3/messages`, {
      cancelToken: cancelTokenSource.token,
      params: {
        departmentId: activeDepartmentId,
        type: 'CUSTOM',
      },
    })
      .then(({ data }) => {
        setMessages(data);
        setLoadingMessages(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingMessages(false);
      });
    return () => {
      cancelTokenSource.cancel();
    };
  }, [activeDepartmentId, newMessageCounter]);

  const onNewMessage = (message: any) => {
    setNewMessageCounter(newMessageCounter + 1);
  };

  return (
    <TableView>
      <BackTop visibilityHeight={20}>
        <i className="icon-up-big"></i>
      </BackTop>
      <Header setActiveMessage={setActiveMessage} />
      <Table
        loading={loadingMessages}
        dataSource={messages}
        columns={columns}
        rowKey="id"
        onChange={(e) => {
          window.scrollTo(0, 0);
        }}
      />
      <DrawerMessage
        message={activeMessage}
        visible={!!activeMessage}
        onClose={() => setActiveMessage(null)}
        onSave={onNewMessage}
      />
    </TableView>
  );
};

export default AppHoursMessagesPage;
