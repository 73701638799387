import React from 'react';
import moment from 'moment/moment';
import i18n from '../../../../i18n';
import ClockingStatus from '../components/ClockingStatus';
import ShiftVerificationHoursColumn from '../components/ShiftVerificationHoursColumn';
import { Avatar, Button } from 'antd';
import {
  GetUserCategoryCostFunction,
  GetContractRenewalFunction,
  GetSectionsCostFunction,
  GetPlannedHoursVsRealHours,
  GetNumberOfAbsencesPerDay,
  GetShiftsVerification,
  GetSicknessPerUserCategory,
  GetOpenAbsencesRequest,
  GetShiftsWithoutDimona,
  GetOpenShiftSwapRequests,
  GetScheduledShifts,
  GetUpcomingBirthdays,
  GetSicknessPercentagePerMonth,
  GetUpcomingFreeShifts,
  GetClockings,
  GetUnsignedDocumentsContracts,
  GetTurnover,
  GetDimonasWithoutShifts,
  GetTurnoverTwin,
} from '../types';

// --------- CHART DATA PARSERS
export const getUserCategoryCost: GetUserCategoryCostFunction = (data) => {
  const { labels, nettoData, bruttoData, costEmployer } = data.reduce(
    (acc, curr) => {
      acc.labels.push(curr.name);
      acc.nettoData.push(curr.costNetto);
      acc.bruttoData.push(curr.costBrutto);
      acc.costEmployer.push(curr.costEmployer);

      return acc;
    },
    {
      labels: [] as string[],
      nettoData: [] as number[],
      bruttoData: [] as number[],
      costEmployer: [] as number[],
    },
  );

  return {
    labels: labels,
    datasets: [
      {
        label: i18n.t('DASHBOARD.WIDGETS.NET'),
        data: nettoData,
        maxBarThickness: 100,
        borderRadius: 5,
        borderSkipped: false,
        backgroundColor: 'rgb(150 215 151)',
      },
      {
        label: i18n.t('DASHBOARD.WIDGETS.GROSS'),
        data: bruttoData,
        maxBarThickness: 100,
        borderRadius: 5,
        borderSkipped: false,
        backgroundColor: 'rgb(92 167 128)',
      },
      {
        label: i18n.t('GLOBAL.EMPLOYER_COST'),
        data: costEmployer,
        maxBarThickness: 100,
        borderRadius: 5,
        borderSkipped: false,
        backgroundColor: 'rgb(216 245 155)',
      },
    ],
  };
};

export const getSectionsCost: GetSectionsCostFunction = (data) => {
  const { labels, nettoData, bruttoData, costEmployer } = data.reduce(
    (acc, curr) => {
      acc.labels.push(curr.name);
      acc.nettoData.push(curr.costNetto);
      acc.bruttoData.push(curr.costBrutto);
      acc.costEmployer.push(curr.costEmployer);

      return acc;
    },
    {
      labels: [] as string[],
      nettoData: [] as number[],
      bruttoData: [] as number[],
      costEmployer: [] as number[],
    },
  );

  return {
    labels: labels,
    datasets: [
      {
        label: i18n.t('DASHBOARD.WIDGETS.NET'),
        data: nettoData,
        maxBarThickness: 100,
        borderRadius: 5,
        borderSkipped: false,
        backgroundColor: 'rgb(150 215 151)',
      },
      {
        label: i18n.t('DASHBOARD.WIDGETS.GROSS'),
        data: bruttoData,
        maxBarThickness: 100,
        borderRadius: 5,
        borderSkipped: false,
        backgroundColor: 'rgb(92 167 128)',
      },
      {
        label: i18n.t('GLOBAL.EMPLOYER_COST'),
        data: costEmployer,
        maxBarThickness: 100,
        borderRadius: 5,
        borderSkipped: false,
        backgroundColor: 'rgb(216 245 155)',
      },
    ],
  };
};

export const getPlannedHoursVsRealHours: GetPlannedHoursVsRealHours = (data) => {
  const { labels, plannedHours, realHours } = data.reduce(
    (acc, curr) => {
      acc.labels.push(moment(curr.date).format('ddd DD'));
      acc.plannedHours.push(curr.plannedHours || 0);
      acc.realHours.push(curr.realHours || 0);

      return acc;
    },
    {
      labels: [] as string[],
      plannedHours: [] as number[],
      realHours: [] as number[],
    },
  );

  return {
    labels: labels,
    datasets: [
      {
        label: i18n.t('DASHBOARD.WIDGETS.PLANNED_HOURS'),
        data: plannedHours,
        maxBarThickness: 100,
        borderRadius: 5,
        borderSkipped: false,
        backgroundColor: 'rgb(150 215 151)',
      },
      {
        label: i18n.t('DASHBOARD.WIDGETS.REAL_HOURS'),
        data: realHours,
        maxBarThickness: 100,
        borderRadius: 5,
        borderSkipped: false,
        backgroundColor: 'rgb(92 167 128)',
      },
    ],
  };
};

export const getNumberOfAbsencesPerDay: GetNumberOfAbsencesPerDay = (data) => {
  const { labels, absencesCount } = data.reduce(
    (acc, curr) => {
      acc.labels.push(moment(curr.date).format('ddd DD'));
      acc.absencesCount.push(curr.absencesCount);

      return acc;
    },
    {
      labels: [] as string[],
      absencesCount: [] as number[],
    },
  );

  return {
    labels: labels,
    datasets: [
      {
        label: i18n.t('DASHBOARD.WIDGETS.ABSENCES'),
        data: absencesCount,
        maxBarThickness: 100,
        borderRadius: 5,
        borderSkipped: false,
        backgroundColor: 'rgb(216 245 155)',
      },
    ],
  };
};

export const getSicknessPerUserCategory: GetSicknessPerUserCategory = (data) => {
  const { labels, hours } = data.reduce(
    (acc, curr) => {
      acc.labels.push(curr.categoryName);
      acc.hours.push(curr.totalNumberOfHours);

      return acc;
    },
    {
      labels: [] as string[],
      hours: [] as number[],
    },
  );

  return {
    labels: labels,
    datasets: [
      {
        label: i18n.t('DASHBOARD.WIDGETS.TOTAL_HOURS'),
        data: hours,
        maxBarThickness: 100,
        borderRadius: 5,
        borderSkipped: false,
        backgroundColor: 'rgb(216 245 155)',
      },
    ],
  };
};

export const getSicknessPercentagePerMonth: GetSicknessPercentagePerMonth = (data) => {
  const { labels, percentagePeopleSick } = data.reduce(
    (acc, curr) => {
      acc.labels.push(`${moment(curr.month, 'MM').format('MMM')} ${curr.year}`);
      acc.percentagePeopleSick.push(curr.percentagePeopleSick);
      acc.totalPlannedUsers.push(curr.totalPlannedUsers);

      return acc;
    },
    {
      labels: [] as string[],
      percentagePeopleSick: [] as number[],
      totalPlannedUsers: [] as number[],
    },
  );

  return {
    labels: labels,
    datasets: [
      {
        label: '%',
        data: percentagePeopleSick,
        maxBarThickness: 100,
        borderRadius: 5,
        borderSkipped: false,
        backgroundColor: 'rgb(216 245 155)',
      },
    ],
  };
};

export const getTurnover: GetTurnover = (data, startDate) => {
  const { currentValues } = data.current.real.reduce(
    (acc, curr) => ({ currentValues: [...acc.currentValues, curr.real] }),
    { currentValues: [] as number[] },
  );
  const { previousValues } = data.previous.real.reduce(
    (acc, curr) => ({ previousValues: [...acc.previousValues, curr.real] }),
    { previousValues: [] as number[] },
  );

  const firstDay = moment(startDate).startOf('week');
  const lastDay = moment(startDate).endOf('week');
  const labels = Array.from({ length: lastDay.diff(firstDay, 'day') + 1 }).map((_, index) =>
      moment(firstDay).add(index, 'day').format('D MMM'),
  );

  return {
    labels: labels,
    datasets: [
      {
        label: i18n.t('DASHBOARD.WIDGETS.CURRENT_TURNOVER'),
        data: currentValues,
        maxBarThickness: 100,
        borderRadius: 5,
        borderSkipped: false,
        backgroundColor: 'rgb(150 215 151)',
      },
      {
        label: i18n.t('DASHBOARD.WIDGETS.PREVIOUS_TURNOVER'),
        data: previousValues,
        maxBarThickness: 100,
        borderRadius: 5,
        borderSkipped: false,
        backgroundColor: 'rgb(92 167 128)',
      },
    ],
  };
};

export const getTurnoverTwin: GetTurnoverTwin = (data, startDate) => {
  const { realValues } = data.current.real.reduce(
    (acc, curr) => ({ realValues: [...acc.realValues, curr.real || NaN] }),
    { realValues: [] as number[] },
  );
  const { provisionalValues } = data.current.provisional.reduce(
    (acc, curr) => ({ provisionalValues: [...acc.provisionalValues, curr.provisional || NaN] }),
    { provisionalValues: [] as number[] },
  );

  const firstDay = moment(startDate).startOf('week');
  const lastDay = moment(startDate).endOf('week');
  const labels = Array.from({ length: lastDay.diff(firstDay, 'day') + 1 }).map((_, index) =>
      moment(firstDay).add(index, 'day').format('D MMM'),
  );

  return {
    labels: labels,
    datasets: [
      {
        label: i18n.t(i18n.t('GLOBAL.REAL')),
        data: realValues,
        backgroundColor: 'rgb(92 167 128)',
        borderColor: 'rgb(92 167 128)',
        fill: false,
        tension: 0.4,
      },
      {
        label: i18n.t(i18n.t('GLOBAL.PROVISIONAL')),
        data: provisionalValues,
        backgroundColor: 'rgb(150 215 151)',
        borderColor: 'rgb(150 215 151)',
        fill: false,
        borderDash: [10,5],
        tension: 0.4,
      },
    ],
  };
};

// --------- TABLE DATA PARSERS
export const getContractRenewal: GetContractRenewalFunction = (data, onContractClick, onEmployeeClick) => {
  return {
    columns: [
      {
        title: 'Contract n°',
        dataIndex: 'id',
        render: (id: string) => (
          <Button type="link" onClick={() => onContractClick(id)}>
            {id}
          </Button>
        ),
      },
      {
        title: 'Name',
        dataIndex: 'user',
        render: ({ id, name }) => (
          <Button type="link" onClick={() => onEmployeeClick(id)}>
            {name}
          </Button>
        ),
      },
      {
        title: 'Start Date',
        dataIndex: 'startDate',
      },
      {
        title: 'End Date',
        dataIndex: 'endDate',
      },
    ],
    data: data.map(({ contract, employee }) => ({
      id: contract.id,
      user: { name: employee.fullName, id: employee.id },
      startDate: moment(contract.startDate).format('ll'),
      endDate: moment(contract.endDate).format('ll'),
    })),
  };
};

export const getShiftsVerification: GetShiftsVerification = (data, onDateClick) => {
  return {
    columns: [
      {
        title: 'Link',
        dataIndex: 'id',
        render: (date) => (
            <Button type="link" onClick={() => onDateClick(moment(date).format('Y-MM-DD'))}>
              <i className={'icon-link'} />
            </Button>
        ),
      },
      {
        title: 'Date',
        dataIndex: 'date',
      },
      {
        title: 'Result',
        dataIndex: 'result',
        render: ({ hours, status }) => (
          <ShiftVerificationHoursColumn hours={hours} status={status} />
        ),
      },
    ],
    data: data.map(({ date, plannedHours, targetHours, type }) => ({
      id: date,
      date: moment(date).format('dddd D MMMM Y'),
      result: {
        hours: targetHours - plannedHours,
        status: type,
      },
    })),
  };
};

export const getOpenAbsencesRequest: GetOpenAbsencesRequest = (data, onRequestClick, onEmployeeClick) => {
  return {
    columns: [
      {
        title: 'Link',
        dataIndex: 'id',
        render: (id: string) => (
          <Button type="link" onClick={() => onRequestClick(id)}>
            <i className={'icon-link'} />
          </Button>
        ),
      },
      {
        title: 'Date',
        dataIndex: 'date',
      },
      {
        title: 'Absence Type',
        dataIndex: 'absenceType',
      },
      {
        title: 'Employee Name',
        dataIndex: 'user',
        render: ({ id, name }) => (
          <Button type="link" onClick={() => onEmployeeClick(id)}>
            {name ?? id}
          </Button>
        ),
      },
    ],
    data: data.map(({ id, startDate, endDate, absenceType, employee, isFullday }) => ({
      id: id,
      date: `${moment(startDate).format(isFullday ? 'DD/MM' : 'DD/MM HH:mm')} - ${moment(endDate).format(
        isFullday ? 'DD/MM' : 'DD/MM HH:mm',
      )}`,
      absenceType: absenceType.name,
      user: { name: employee.fullName, id: employee.id },
    })),
  };
};

export const getShiftsWithoutDimona: GetShiftsWithoutDimona = (data, onDimonaClick, onEmployeeClick) => {
  return {
    columns: [
      {
        title: 'Link',
        dataIndex: 'id',
        render: (id: string) => (
          <Button type="link" onClick={() => onDimonaClick(id)}>
            <i className={'icon-link'} />
          </Button>
        ),
      },
      {
        title: 'Date',
        dataIndex: 'date',
      },
      {
        title: 'Employee Name',
        dataIndex: 'user',
        render: ({ id, name }) => (
          <Button type="link" onClick={() => onEmployeeClick(id)}>
            {name ?? id}
          </Button>
        ),
      },
      {
        title: 'Default dimona type',
        dataIndex: 'defaultDimonaType',
      },
    ],
    data: data.map(({ id, startDate, endDate, employee }) => ({
      id: id,
      date: `${moment(startDate).format('dddd D MMMM Y, HH:mm')} - ${moment(endDate).format('HH:mm')}`,
      user: { name: employee.fullName, id: employee.id },
      defaultDimonaType: employee.defaultDimonaType,
    })),
  };
};

export const getOpenShiftSwapRequests: GetOpenShiftSwapRequests = (data, onShiftSwapClick, onEmployeeClick) => {
  return {
    columns: [
      {
        title: 'Link',
        dataIndex: 'id',
        render: (id: string) => (
          <Button type="link" onClick={() => onShiftSwapClick(id)}>
            <i className={'icon-link'} />
          </Button>
        ),
      },
      {
        title: 'Start Date',
        dataIndex: 'startDate',
      },
      {
        title: 'End Date',
        dataIndex: 'endDate',
      },
      {
        title: 'Employee Name',
        dataIndex: 'user',
        render: ({ id, name }) => (
          <Button type="link" onClick={() => onEmployeeClick(id)}>
            {name ?? id}
          </Button>
        ),
      },
    ],
    data: data.map(({ id, startDate, endDate, employee }) => ({
      id: id,
      startDate: moment(startDate).format('MMM D, HH:mm'),
      endDate: moment(endDate).format('MMM D, HH:mm'),
      user: { name: employee.fullName, id: employee.id },
    })),
  };
};

export const getScheduledShifts: GetScheduledShifts = (data, onShiftClick, onEmployeeClick) => {
  return {
    columns: [
      {
        title: 'Link',
        dataIndex: 'id',
        render: (id: string) => (
          <Button type="link" onClick={() => onShiftClick(id)}>
            <i className={'icon-link'} />
          </Button>
        ),
      },
      {
        title: 'Date',
        dataIndex: 'date',
      },
      {
        title: 'Employee Name',
        dataIndex: 'user',
        render: ({ id, name }) => (
          <Button type="link" onClick={() => onEmployeeClick(id)}>
            {name ?? id}
          </Button>
        ),
      },
    ],
    data: data.map(({ id, startDate, endDate, employee }) => ({
      id: id,
      date: `${moment(startDate).format('HH:mm')} - ${moment(endDate).format('HH:mm')}`,
      user: { name: employee.fullName, id: employee.id },
    })),
  };
};

export const getUpcomingBirthdays: GetUpcomingBirthdays = (data, onEmployeeClick) => {
  return {
    columns: [
      {
        title: 'Date',
        dataIndex: 'date',
      },
      {
        title: 'Employee Name',
        dataIndex: 'user',
        render: ({ id, name, avatarUrl, initials }) => (
          <Button type="link" onClick={() => onEmployeeClick(id)}>
            <Avatar src={avatarUrl} size={27}>{initials}</Avatar>
            {name ?? id}
          </Button>
        ),
      },
    ],
    data: data.map(({ employee }) => ({
      id: employee.id,
      date: moment(employee.birthday).format('dddd D MMMM'),
      user: {
        id: employee.id,
        name: employee.fullName,
        avatarUrl: employee.avatarUrl,
        initials: employee.initials,
      },
    })),
  };
};

export const getUpcomingFreeShifts: GetUpcomingFreeShifts = (data, onShiftClick) => {
  return {
    columns: [
      {
        title: 'Link',
        dataIndex: 'link',
        render: (date) => (
          <Button type="link" onClick={() => onShiftClick(date)}>
            <i className={'icon-link'} />
          </Button>
        ),
      },
      {
        title: 'Start date',
        dataIndex: 'startDate',
      },
      {
        title: 'End date',
        dataIndex: 'endDate',
      },
      {
        title: 'Count',
        dataIndex: 'count',
        render: (count) => <span>{count} {i18n.t('GLOBAL.AVAILABLE_SHORT')}</span>,
      },
    ],
    data: data.map(({ startDate, endDate, count }) => ({
      id: `${startDate}_${endDate}_${count}`,
      link: moment(startDate).format('Y-MM-DD'),
      startDate: moment(startDate).format('MMM D, HH:mm'),
      endDate: moment(endDate).format('MMM D, HH:mm'),
      count: count,
    })),
  };
};

export const getClockings: GetClockings = (data, onEmployeeClick) => {
  return {
    columns: [
      {
        title: 'Date',
        dataIndex: 'date',
      },
      {
        title: 'Start status',
        dataIndex: 'startStatus',
        render: (status) => <ClockingStatus status={status} clockingType={'start'} />,
      },
      {
        title: 'End Status',
        dataIndex: 'endStatus',
        render: (status) => <ClockingStatus status={status} clockingType={'end'} />,
      },
      {
        title: 'Employee Name',
        dataIndex: 'user',
        render: ({ id, name }) => (
          <Button type="link" onClick={() => onEmployeeClick(id)}>
            {name ?? id}
          </Button>
        ),
      },
    ],
    data: data.map(({ id, startDate, endDate, startStatus, endStatus, employee }) => ({
      id: id,
      startStatus,
      endStatus,
      date: `${moment(startDate).format('HH:mm')} - ${moment(endDate).format('HH:mm')}`,
      user: {
        id: employee.id,
        name: employee.fullName,
      },
    })),
  };
};

export const getDimonasWithoutShifts: GetDimonasWithoutShifts = (data, onShiftClick) => {
  return {
    columns: [
      {
        title: 'Link',
        dataIndex: 'id',
        render: (date) => (
            <Button type="link" onClick={() => onShiftClick(date)}>
              <i className={'icon-link'} />
            </Button>
        ),
      },
      {
        title: 'Title',
        dataIndex: 'title',
      },
      {
        title: 'Start date',
        dataIndex: 'startDate',
      },
      {
        title: 'End date',
        dataIndex: 'endDate',
      },
      {
        title: 'Employee Name',
        dataIndex: 'user',
        render: ({ id, name }) => (
            <Button type="link" onClick={() => {}}>
              {name ?? id}
            </Button>
        ),
      },
      {
        title: 'Worker type',
        dataIndex: 'workerType',
      },
    ],
    data: data.map(({ id, startDate, endDate, employee, workerType }) => ({
      id: id,
      workerType,
      startDate: moment(startDate).format('ll'),
      endDate: moment(endDate).format('ll'),
      user: {
        id: employee.id,
        name: employee.fullName,
      },
    })),
  };
};

export const getUnsignedDocumentsContracts: GetUnsignedDocumentsContracts = (
  data,
  onDocumentClick,
  onEmployeeClick,
) => {
  return {
    columns: [
      {
        title: 'Link',
        dataIndex: 'link',
        render: ({ id, type }) => (
          <Button type="link" onClick={() => onDocumentClick(id, type)}>
            <i className={'icon-link'} />
          </Button>
        ),
      },
      {
        title: 'Title',
        dataIndex: 'title',
      },
      {
        title: 'Type',
        dataIndex: 'type',
      },
      {
        title: 'Employee Name',
        dataIndex: 'user',
        render: ({ id, name }) => (
          <Button type="link" onClick={() => onEmployeeClick(id)}>
            {name ?? id}
          </Button>
        ),
      },
      {
        title: 'Sent at',
        dataIndex: 'sentAt',
      },
    ],
    data: data.map(({ id, title, type, employee, sentAt }) => ({
      id: id,
      link: {
        id,
        type,
      },
      title,
      type: i18n.t(type === 'contract' ? 'GLOBAL.CONTRACT' : 'GLOBAL.DOCUMENT'),
      sentAt: moment(sentAt).format('LL'),
      user: {
        id: employee.id,
        name: employee.fullName,
      },
    })),
  };
};
