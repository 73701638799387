import { Moment } from 'moment/moment';
import { useQuery } from '@tanstack/react-query';
import { IV4Dimona } from '@/types/dimona.model';
import { getDimonasPending } from '../api/index';

export const usePendingDimonas = (
  startDate: Moment,
  endDate: Moment,
): {
  dimonas: IV4Dimona[];
  loading: boolean;
} => {
  const { data: dimonas = [], isLoading: loading } = useQuery({
    queryKey: ['get-dimonas-pending', startDate, endDate],
    queryFn: () => getDimonasPending(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')),
  });

  return {
    dimonas,
    loading,
  };
};
