import TableView from '@/layouts/TableView';
import Color from '@/pages/app/components/Color';
import DrawerUserStatus from '@/pages/app/components/drawers/UserStatus';
import AppContext from '@/pages/app/context';
import { AlignType } from '@/types/alignType.model';
import { ISection } from '@/types/section.model';
import { IUserStatus } from '@/types/user-status.model';
import { MenuOutlined } from '@ant-design/icons';
import { message, Modal, Table } from 'antd';
import arrayMove from 'array-move';
import { default as axios, default as Axios } from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import DrawerAssignUsers from '../../../components/drawers/AssignUsers';
import Header from './components/Header';
import TableRowActions from './components/TableRowActions';
interface Props {
  className?: string;
}

interface IUserForAttribution {
  recordId: string;
  displayName: string;
  active: boolean;
}

const SortableElementItem = SortableElement((props: any) => <tr {...props} />);
const SortableContainerItem = SortableContainer((props: any) => <tbody {...props} />);
const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />);

const ManageUserStatuses: React.FC<Props> = ({ className }) => {
  const {
    state: { activeDepartmentId, activeDepartment },
    dispatch: appContextDispatch,
  } = useContext(AppContext);
  const [userStatuses, setUserStatuses] = useState<IUserStatus[]>([]);
  const userStatusesRef = useRef(userStatuses);
  userStatusesRef.current = userStatuses;
  const [filteredUserStatuses, setFilteredUserStatuses] = useState<IUserStatus[]>([]);
  const [term, setTerm] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeStatus, setActiveStatus] = useState<IUserStatus | null>(null);
  const [columns, setColumns] = useState<any[]>([]);
  const { i18n, t } = useTranslation(undefined, { useSuspense: false });
  const [showAssignDrawer, setShowAssignDrawer] = useState<ISection | null>(null);

  useEffect(() => {
    setColumns([
      {
        title: '',
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        // eslint-disable-next-line react/display-name
        render: () => <DragHandle />,
      },
      {
        title: t('GLOBAL.ID'),
        dataIndex: 'id',
        key: 'id',
        sorter: (a: any, b: any) => a.id.localeCompare(b.id),
      },
      {
        title: t('GLOBAL.NAME'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a: any, b: any) => a.name?.localeCompare(b.name),
      },
      {
        title: t('GLOBAL.COLOR'),
        dataIndex: 'background',
        key: 'background',
        // eslint-disable-next-line react/display-name
        render: (text: any, record: any) => {
          return <Color style={{ background: record.background }} />;
        },
      },
      {
        title: t('GLOBAL.NET_COST'),
        dataIndex: 'hourlyRate',
        key: 'hourlyRate',
        sorter: (a: any, b: any) => a.hourlyRate - b.hourlyRate,
        // eslint-disable-next-line react/display-name
        render: (hourlyRate: number) => {
          return (
            <span>
              {new Intl.NumberFormat(i18n.language, {
                style: 'currency',
                currency: activeDepartment?.currency || 'EUR',
              }).format(hourlyRate)}{' '}
              / h
            </span>
          );
        },
      },
      {
        title: t('GLOBAL.GROSS_COST'),
        dataIndex: 'hourlyRateBruto',
        key: 'hourlyRateBruto',
        sorter: (a: any, b: any) => a.hourlyRateBruto - b.hourlyRateBruto,
        // eslint-disable-next-line react/display-name
        render: (hourlyRateBruto: number) => {
          return (
            <span>
              {new Intl.NumberFormat(i18n.language, {
                style: 'currency',
                currency: activeDepartment?.currency || 'EUR',
              }).format(hourlyRateBruto)}{' '}
              / h
            </span>
          );
        },
      },
      {
        title: t('GLOBAL.EMPLOYER_COST_COEFFICIENT'),
        dataIndex: 'employerCostCoefficient',
        key: 'employerCostCoefficient',
        sorter: (a: any, b: any) => a.employerCostCoefficient - b.employerCostCoefficient,
      },
      {
        title: t('GLOBAL.ACTIONS'),
        key: 'action',
        displayName: 'actions',
        align: 'right' as AlignType,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: IUserStatus) => (
          <TableRowActions
            onEdit={() => setActiveStatus(record)}
            onDelete={() => onWantToDelete(record.id!)}
            onAssign={() => setShowAssignDrawer(record)}
          />
        ),
      },
    ]);
  }, [i18n.language]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/user-categories`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then((response) => {
        const userStatuses = response.data.userCategories as IUserStatus[];
        setUserStatuses(userStatuses);
        setFilteredUserStatuses(userStatuses);
        setLoading(false);
        appContextDispatch({
          type: 'SET_USER_CATEGORIES',
          payload: userStatuses,
        });
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (term) {
      const filteredUserStatus = userStatuses.filter((userStatus: any) => {
        return Object.keys(userStatus).some((key) => {
          if (key === 'background') {
            return false;
          }
          if (typeof userStatus[key] === 'string') {
            return userStatus[key].toLowerCase().includes(term.toLowerCase());
          } else {
            return false;
          }
        });
      });
      setFilteredUserStatuses(filteredUserStatus);
    } else {
      setFilteredUserStatuses(userStatuses);
    }
  }, [userStatuses, term]);

  const onWantToDelete = (userStatusId: string) => {
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/status/${userStatusId}/users`, {
        cancelToken: cancelTokenSource.token,
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        if (data.length > 0 && data.find((user: IUserForAttribution) => user.active == true)) {
          return Modal.error({
            title: t('GLOBAL.CANNOT_DELETE'),
            content: t('SETTINGS.TOOLBOX.USER_CATEGORIES.CANNOT_DELETE'),
          });
        } else {
          Modal.confirm({
            title: t('GLOBAL.DELETION'),
            icon: null,
            content: t('USER_CATEGORIES.MODAL_DELETE.CONTENT'),
            cancelText: t('GLOBAL.CANCEL'),
            okText: t('GLOBAL.REMOVE'),
            okType: 'danger',
            onOk: () => {
              onDelete(userStatusId);
            },
            onCancel: () => {},
          });
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      });
  };

  const onDelete = (userStatusId: string) => {
    const userStatusesCopy = [...userStatusesRef.current];
    const index = userStatusesCopy.findIndex((userCategory) => userCategory.id === userStatusId);
    const userStatusesResult = userStatusesCopy.filter((el: any) => el.id != userStatusId);

    if (~index) {
      setUserStatuses(userStatusesResult);
      appContextDispatch({
        type: 'SET_USER_CATEGORIES',
        payload: userStatusesResult,
      });
    }

    axios
      .delete(`${process.env.REACT_APP_API_URL}/v3/user-categories/${userStatusId}`)
      .then((response) => {
        appContextDispatch({
          type: 'SET_USER_CATEGORIES',
          payload: userStatusesResult,
        });
      })
      .catch((error) => {
        if (~index) {
          console.error(error);
          message.error(t('USER_CATEGORIES.MODAL_DELETE.MESSAGE_ERROR'));
          if (userStatusesCopy) {
            setUserStatuses(userStatusesCopy);
            appContextDispatch({
              type: 'SET_USER_CATEGORIES',
              payload: userStatusesCopy,
            });
          }
        }
      });
  };

  const onSave = (result: IUserStatus) => {
    const userStatusesCopy = [...userStatusesRef.current];
    const index = userStatusesCopy.findIndex((userStatus) => userStatus.id === result.id);

    if (~index) {
      userStatusesCopy[index] = result;
      setUserStatuses(userStatusesCopy);
      appContextDispatch({
        type: 'SET_USER_CATEGORIES',
        payload: userStatusesCopy,
      });
    } else {
      userStatusesCopy.push(result);
      setUserStatuses(userStatusesCopy);
      appContextDispatch({
        type: 'SET_USER_CATEGORIES',
        payload: userStatusesCopy,
      });
    }
  };

  const DraggableContainer = (props: any) => (
    <SortableContainerItem useDragHandle helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const oldUserStatuses = [...userStatuses];
    if (userStatuses && oldIndex !== newIndex) {
      setLoading(true);
      const newUserStatuses: IUserStatus[] = arrayMove(userStatuses, oldIndex, newIndex);
      setUserStatuses(newUserStatuses);
      Axios.patch(
        `${process.env.REACT_APP_API_URL}/v3/user-categories`,
        {
          fields: newUserStatuses.map((x) => x.id),
        },
        {
          params: {
            departmentId: activeDepartmentId,
          },
        },
      )
        .then(({ data }) => {
          setUserStatuses(data.userCategories);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setUserStatuses(oldUserStatuses);
          setLoading(false);
        });
    }
  };

  const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
    const index = userStatuses.findIndex((x) => x.id === restProps['data-row-key']);
    return <SortableElementItem index={index} {...restProps} />;
  };

  return (
    <React.Fragment>
      <h2>{t('SETTINGS.GENERAL.USER_STATUS.TITLE')}</h2>
      <div style={{ backgroundColor: 'white', padding: 25, borderRadius: 10, marginTop: 25 }}>
        <TableView>
          <Header disabled={false} setTerm={setTerm} onCreate={() => setActiveStatus({})} />
          {!term && (
            <Table
              className={className}
              loading={loading}
              dataSource={filteredUserStatuses.filter((x) => x.active)}
              columns={columns}
              rowKey="id"
              pagination={false}
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              }}
            />
          )}
          {term && (
            <Table
              className={className}
              loading={loading}
              dataSource={filteredUserStatuses.filter((x) => x.active)}
              columns={columns.slice(1)}
              rowKey="id"
              pagination={false}
            />
          )}
        </TableView>
        <DrawerUserStatus
          userStatus={activeStatus}
          visible={!!activeStatus}
          onClose={() => setActiveStatus(null)}
          onSave={onSave}
        />
        <DrawerAssignUsers
          departmentId={activeDepartmentId}
          entity={showAssignDrawer}
          entityName="status"
          onClose={() => setShowAssignDrawer(null)}
        />
      </div>
    </React.Fragment>
  );
};

export default ManageUserStatuses;
