import DrawerUserStatus from '@/pages/app/components/drawers/UserStatus';
import AppContext from '@/pages/app/context';
import { FEATURES } from '@/types/features.model';
import { IUserStatus } from '@/types/user-status.model';
import { isClockingAccount, isFeatureEnabled } from '@/utils';
import { Col, DatePicker, Form, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IResponse } from '.';
import SocialPartners from './SocialPartners';
const Option = Select.Option;

interface Props {
  className?: string;
  form: FormInstance;
  meta?: IResponse['meta'];
  setNewStart: (start: moment.Moment) => void;
}

const Status: React.FC<Props> = ({ className, form, meta, setNewStart }) => {
  const {
    state: { activeDepartment, loadingUserCategories, userCategories, features },
    dispatch: appContextDispatch,
  } = useContext(AppContext);
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [drawerStatusIsVisible, setDrawerStatusIsVisible] = useState<boolean>(false);

  const freemium = activeDepartment?.accountType == 'FREE-CLOCKING';

  const onCreateStatus = (result: IUserStatus) => {
    const userStatusesCopy = [...userCategories];
    userStatusesCopy.push(result);
    appContextDispatch({
      type: 'SET_USER_CATEGORIES',
      payload: userStatusesCopy,
    });
    setDrawerStatusIsVisible(false);
    form.setFieldsValue({ user_type: result.id });
  };

  return (
    <div className={className}>
      {!!activeDepartment?.params?.hr_historisation && (
        <Row gutter={[20, 10]}>
          <Col lg={8} md={12} style={{ width: '100%' }}>
            <Form.Item label={t('GLOBAL.START')} name={'contract_start'} rules={[{ required: true, message: '' }]}>
              <DatePicker
                style={{ width: '100%' }}
                format={'L'}
                onSelect={(e) => {
                  setNewStart(e);
                  form.setFieldsValue({
                    start: e,
                  });
                }}
                disabled={freemium}
              />
            </Form.Item>
          </Col>
          <Col lg={8} md={12} style={{ width: '100%' }}>
            <Form.Item label={t('GLOBAL.END')} name={'contract_end'}>
              <DatePicker
                style={{ width: '100%' }}
                format={'L'}
                onSelect={(e) => {
                  form.setFieldsValue({
                    end: e,
                  });
                }}
                disabled={freemium}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={[20, 10]}>
        <Col lg={8} md={12} style={{ width: '100%' }}>
          <Form.Item label={t('GLOBAL.USER_CATEGORY')} name={'user_type'}>
            <Select
              style={{ width: '100%' }}
              getPopupContainer={(trigger) => trigger}
              allowClear={true}
              loading={loadingUserCategories}
              disabled={loadingUserCategories || freemium}
              placeholder={t('FORMS.USER_CATEGORY_PLACEHOLDER')}
            >
              {userCategories
                ?.filter((x) => x.active)
                ?.map((userCategory) => (
                  <Option key={`status_${userCategory.id}`} value={userCategory.id!}>
                    {userCategory.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <button
            className="green"
            style={{ marginTop: 5, padding: 0 }}
            onClick={() => setDrawerStatusIsVisible(!drawerStatusIsVisible)}
            disabled={freemium}
          >
            + {t('FORMS.ADD_NEW_USER_CATEGORY')}
          </button>
        </Col>
        {(isFeatureEnabled(features, FEATURES.CLOCKING) || isFeatureEnabled(features, FEATURES.SCHEDULE)) &&
          !isClockingAccount(features) && (
            <Col lg={8} md={12} style={{ width: '100%' }}>
              <Form.Item label={t('GLOBAL.GENERAL_WORKTIME_CALCULATION_METHOD')} name={'worktime_calculation_method'}>
                <Select
                  placeholder={t('GLOBAL.SELECT')}
                  getPopupContainer={(trigger) => trigger}
                  allowClear={true}
                  disabled={freemium}
                >
                  {isFeatureEnabled(features, FEATURES.SCHEDULE) && (
                    <Option value={'PLANNING'}>{t('GLOBAL.PLANNED_HOURS')}</Option>
                  )}
                  {isFeatureEnabled(features, FEATURES.CLOCKING) && (
                    <Option value={'CLOCKING'}>{t('GLOBAL.CLOCKING_HOURS')}</Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
          )}
      </Row>
      <br />
      {isFeatureEnabled(features, FEATURES.HR_PARTNER) && !!(meta?.['socialPartnerId'] || []).length && (
        <SocialPartners socialPartners={meta?.['socialPartnerId'] || []} />
      )}
      <DrawerUserStatus
        visible={drawerStatusIsVisible}
        onClose={() => setDrawerStatusIsVisible(false)}
        onSave={onCreateStatus}
      />
    </div>
  );
};

export default styled(Status)`
  .ant-row.ant-form-item.one-line {
    .ant-col.ant-form-item-label {
      label {
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
`;
